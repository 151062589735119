import { SetStateAction, useState } from 'react';
import { Box, Checkbox, FormControlLabel, Menu, MenuItem, Switch, TableCell, TableHead, TableRow, TableSortLabel, Tooltip } from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import { useLanguage } from '../../../context/LanguageContext';
import getTitle from '../../../common/translations';
import { HeadCell, Order, getStyles } from '../tableCommon';

import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';

interface EnhancedTableProps {
    numSelected: number;
    onRequestSort: (event: React.MouseEvent<unknown>, property: SetStateAction<string>) => void;
    onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
    order: Order;
    orderBy: string;
    rowCount: number;
    visibleColumns: HeadCell[];
    categoriesAvailable: boolean;

    categories: any;
    setCatList: any;
    catList: any;
}

export function EnhancedTableHead({
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    visibleColumns,
    categories,
    categoriesAvailable,
    setCatList,
    catList,
}: EnhancedTableProps) {
    const [anchorEl, setAnchorEl] = useState(null);

    const { language } = useLanguage();
    const { tableRowStyle, tableCellStyle } = getStyles();

    const createSortHandler = (property: SetStateAction<string>) => (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property);
    };

    // columns menu
    const handleMenuOpen = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleColumnToggle = (clickedColumn: { id: string }) => {
        setCatList((prevCatList: any) => {
            if (prevCatList.includes(clickedColumn.id)) {
                return prevCatList.filter((id: any) => id !== clickedColumn.id);
            } else {
                return [...prevCatList, clickedColumn.id];
            }
        });
    };

    const handleColumnItemClick = (cat: any) => {
        handleColumnToggle(cat);
    };

    return (
        <TableHead sx={tableRowStyle}>
            <TableRow>
                <TableCell padding="checkbox">
                    <Checkbox
                        color="primary"
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{
                            'aria-label': 'select all desserts',
                        }}
                    />
                </TableCell>
                {visibleColumns.map((headCell) => {
                    if (headCell.visible) {
                        return (
                            <TableCell
                                key={headCell.id}
                                align={headCell.numeric ? 'center' : 'left'}
                                padding={headCell.disablePadding ? 'none' : 'normal'}
                                sortDirection={orderBy === headCell.id ? order : false}
                                sx={tableCellStyle}
                            >
                                {headCell.id === 'categories' ? (
                                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        {getTitle(language, headCell.label)}

                                        {categoriesAvailable ? (
                                            <Box>
                                                <Tooltip title="Filter Category">
                                                    <IconButton onClick={handleMenuOpen}>
                                                        <MenuIcon />
                                                    </IconButton>
                                                </Tooltip>
                                                <Menu
                                                    anchorEl={anchorEl}
                                                    open={Boolean(anchorEl)}
                                                    onClose={handleMenuClose}
                                                    MenuListProps={{
                                                        'aria-labelledby': 'column-selector',
                                                    }}
                                                >
                                                    {categories.map((cat: any) => {
                                                        return (
                                                            <MenuItem
                                                                key={cat.id}
                                                                onClick={(event) => {
                                                                    event.stopPropagation();
                                                                    handleColumnItemClick(cat);
                                                                }}
                                                            >
                                                                <FormControlLabel control={<Switch checked={catList.includes(cat.id)} />} label={cat.name} />
                                                            </MenuItem>
                                                        );
                                                    })}
                                                </Menu>
                                            </Box>
                                        ) : (
                                            <></>
                                        )}
                                    </Box>
                                ) : (
                                    <TableSortLabel active={orderBy === headCell.id} direction={orderBy === headCell.id ? order : 'asc'} onClick={createSortHandler(headCell.id)}>
                                        {getTitle(language, headCell.label)}
                                        {orderBy === headCell.id ? (
                                            <Box component="span" sx={visuallyHidden}>
                                                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                            </Box>
                                        ) : null}
                                    </TableSortLabel>
                                )}
                            </TableCell>
                        );
                    }
                })}
            </TableRow>
        </TableHead>
    );
}
