import { FC, useState } from 'react';
import { List, ListItemButton, ListItemIcon, ListItemText, useMediaQuery } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import Collapse from '@mui/material/Collapse';
import ChecklistIcon from '@mui/icons-material/Checklist';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { ICategory } from '../../../models/ICategory';
import { useLanguage } from '../../../context/LanguageContext';
import getTitle from '../../../common/translations';

interface CheckboxListProps {
    categories: ICategory[] | undefined;
    checked: number[];
    setChecked: (data: number[]) => void;
}

const CheckboxList: FC<CheckboxListProps> = ({ categories, checked, setChecked }) => {
    const [open, setOpen] = useState(false);
    const { language } = useLanguage();

    const handleToggle = (value: number | undefined) => () => {
        if (value !== undefined) {
            const currentIndex = checked.indexOf(value);
            const newChecked = [...checked];

            if (currentIndex === -1) {
                newChecked.push(value);
            } else {
                newChecked.splice(currentIndex, 1);
            }

            setChecked(newChecked);
        }
    };

    const handleClick = () => {
        setOpen(!open);
    };

    const isMobile = useMediaQuery('(max-width:800px)');
    const padding = isMobile ? { padding: 0 } : {};

    return (
        <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper', ...padding }} component="nav" aria-labelledby="nested-list-subheader">
            <ListItemButton onClick={handleClick}>
                <ListItemIcon>
                    <ChecklistIcon />
                </ListItemIcon>
                <ListItemText primary={getTitle(language, 'categories')} />
                {open ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    {categories?.map((category) => {
                        const labelId = `checkbox-list-label-${category.id}`;
                        return (
                            <ListItemButton key={category.id} sx={{ ...padding, pl: 4 }} onClick={handleToggle(category.id)}>
                                <ListItemIcon>
                                    <Checkbox
                                        edge="start"
                                        checked={checked.indexOf(category.id !== undefined ? category.id : -1) !== -1}
                                        tabIndex={-1}
                                        disableRipple
                                        inputProps={{ 'aria-labelledby': labelId }}
                                    />
                                </ListItemIcon>
                                <ListItemText id={labelId} primary={category.name} />
                            </ListItemButton>
                        );
                    })}
                </List>
            </Collapse>
        </List>
    );
};

export default CheckboxList;
