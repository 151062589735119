import { FC } from 'react';
import { Alert, AlertColor, Snackbar } from '@mui/material';

interface SnackBarProps {
    openSnackBar: boolean;
    handleClose: (event?: React.SyntheticEvent | Event, reason?: string) => void;
    alertText: string;
    severity: AlertColor;
    duration: number;
}

const MySnackBar: FC<SnackBarProps> = ({ openSnackBar, handleClose, alertText, severity, duration }) => {
    return (
        <Snackbar open={openSnackBar} anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }} autoHideDuration={duration} onClose={handleClose}>
            <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
                {alertText}
            </Alert>
        </Snackbar>
    );
};

export default MySnackBar;
