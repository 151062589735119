import { useState } from 'react';
import { Box, TextField } from '@mui/material';
import { FuncButtonWIcon } from '../../../common/buttons/FunctionalButton';
import { getStyles } from '../../tableCommon';

export const DeleteForm = ({ item, remove }: { item: any; remove: (id: number | undefined) => Promise<void> }) => {
    const { titleDeleteForm, labelDeleteForm, formWrapper, inputStylePadding, buttonWrapper } = getStyles();

    const [deleteItemName, setDeleteItemName] = useState({
        checkDeleteItemName: '',
    });

    // methods
    const handleDeleteItemName = (e: { target: { value: any } }) => {
        setDeleteItemName((prevState) => {
            return {
                ...prevState,
                checkDeleteItemName: e.target.value,
            };
        });
    };

    const handleRemove = () => {
        if (deleteItemName.checkDeleteItemName === item.name) remove(item.id);
    };

    return (
        <Box sx={formWrapper}>
            <TextField
                label={`${labelDeleteForm} ${item.name}`}
                margin="dense"
                fullWidth
                variant="standard"
                value={deleteItemName.checkDeleteItemName}
                onChange={handleDeleteItemName}
                sx={inputStylePadding}
            />
            <Box sx={buttonWrapper}>
                <FuncButtonWIcon field={titleDeleteForm} handleClick={handleRemove} iconName={'deleteIcon'} />
            </Box>
        </Box>
    );
};
