import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { BASE_API_URL } from '../common/endpoints';

export const baseAPI = createApi({
    reducerPath: 'baseAPI',
    baseQuery: fetchBaseQuery({
        baseUrl: BASE_API_URL,
        credentials: 'include',
    }),
    tagTypes: ['Order', 'Item', 'Auth', 'Category', 'Customer', 'Recipe', 'Recipes', 'User'],

    endpoints: (build) => ({}),
});
