import { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { IRecipePartItem } from '../../../models/Recipe/IRecipePartItem';
import { getStyles } from '../recipeCommon';

interface RecipePartItemProps {
    item: IRecipePartItem;
    size: number;
    isLinear?: boolean;
}

const RecipePartItem = ({ item, size, isLinear }: RecipePartItemProps) => {
    const { recipePartItemStyle } = getStyles();
    const [data, setData] = useState<any>([{}]);

    const recipePartItemData = (
        <>
            {/* {isLinear ? item.proportions[0].quantity * size : data[0].quantity} */}
            {Math.round(item.proportions[0].quantity * size)}
            &nbsp;
            {item.unitOfWeight}
        </>
    );

    useEffect(() => {
        const filteredData = item.proportions.filter((el: any) => el.proportion === size);
        setData(filteredData);
    }, []);

    return (
        <Box sx={recipePartItemStyle}>
            <Box component={'span'} sx={{ pr: '10px' }}>
                {item.itemName}
            </Box>
            {recipePartItemData}
        </Box>
    );
};

export default RecipePartItem;
