import { SetStateAction, useEffect, useState } from 'react';
import { Box, Button, CircularProgress, InputLabel, TextField, Typography } from '@mui/material';
import { useLanguage } from '../../context/LanguageContext';
import { authAPI } from '../../services/AuthServices';
import getTitle from '../../common/translations';
import { IUser } from '../../models/IUser';
import { useStyles } from './loginStyles';
import './Login.css';

const Login = ({ setUser, isLoginStart, setIsLoginStart }: { setUser: (u: IUser) => void; isLoginStart: boolean; setIsLoginStart: (data: boolean) => void }) => {
    const { language } = useLanguage();
    const { title, header, errorWrapper, errorStyle, loginWrapper } = useStyles();

    const [errorMessage, setErrorMessage] = useState('');
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [isEmailFocused, setIsEmailFocused] = useState(false);
    const [emailSizeTransition, setEmailSizeTransition] = useState(false);
    const [isPasswordFocused, setIsPasswordFocused] = useState(false);
    const [passwordSizeTransition, setPasswordSizeTransition] = useState(false);

    // api
    const [logIn, { error: logInError, isSuccess: logInIsSuccess }] = authAPI.useLogInMutation();

    // handle an error
    const [isError, setIsError] = useState<any>(logInError);

    useEffect(() => {
        setIsError(logInError);
    }, [logInError]);

    // method to authenticate user
    const auth = (e: any) => {
        logIn([email, password]).then((result: any) => {
            if ('data' in result) {
                setUser(result.data);
            }
        });
    };

    // handlers inputs
    const handleEmailChange = (e: { target: { value: SetStateAction<string> } }) => {
        setEmail(e.target.value);
    };
    const handlePasswordChange = (e: { target: { value: SetStateAction<string> } }) => {
        setPassword(e.target.value);
    };

    // handlers focus
    const handleEmailFocus = () => {
        setEmailSizeTransition(true);
        setIsEmailFocused(true);
    };

    const handlePasswordFocus = () => {
        setPasswordSizeTransition(true);
        setIsPasswordFocused(true);
    };

    // handlers blur
    const handleEmailBlur = () => {
        setIsEmailFocused(false);
        setTimeout(() => {
            setEmailSizeTransition(false);
        }, 150);
    };

    const handlePasswordBlur = () => {
        setIsPasswordFocused(false);
        setTimeout(() => {
            setPasswordSizeTransition(false);
        }, 150);
    };

    // label font size
    const fontSizeEmailLabel = emailSizeTransition ? 12 : email.length === 0 ? 12 : 0;
    const fontSizePasswordLabel = passwordSizeTransition ? 12 : password.length === 0 ? 12 : 0;

    useEffect(() => {
        if (isError) {
            if (isError.data) {
                if (isError.status === 400) {
                    setIsLoginStart(false), setErrorMessage(getTitle(language, 'loginError'));
                }
                if (isError.status === 500) {
                    setIsLoginStart(false), setErrorMessage(getTitle(language, 'serverError'));
                }
            } else if (isError.status) {
                setIsLoginStart(false), setErrorMessage(getTitle(language, 'serverError'));
            }
        }

        isError === undefined && setErrorMessage('');
    }, [isError, language]);

    return (
        <Box sx={loginWrapper}>
            <Box className="ring">
                <Box>
                    <i className="grn"></i>
                    <i className="rd"></i>
                    <i className="yllw"></i>
                </Box>

                <Box>
                    <Box className="login">
                        <Box sx={title}>
                            <Typography sx={header}>{getTitle(language, 'loginTitle')}</Typography>
                            <Box sx={errorWrapper}>
                                <Box sx={errorStyle}>{errorMessage}</Box>
                            </Box>
                        </Box>
                        <Box className="inputBx">
                            <Box sx={{ position: 'relative', ...(isLoginStart && { opacity: '0.5', pointerEvents: 'none' }) }}>
                                <InputLabel
                                    htmlFor="emailInput"
                                    style={{
                                        position: 'absolute',
                                        top: isEmailFocused ? -16 : 22.5,
                                        left: 20,
                                        transition: 'top 0.5s ease, font-size 0.5s ease',
                                        pointerEvents: 'none',
                                        fontSize: fontSizeEmailLabel,
                                        color: isEmailFocused ? '#618B4A99' : 'rgba(0, 0, 0, 0.54)',
                                        fontWeight: 600,
                                        transformOrigin: 'top left',
                                        cursor: 'text',
                                    }}
                                >
                                    {getTitle(language, 'emailHolder')}
                                </InputLabel>

                                <TextField
                                    id="emailInput"
                                    onFocus={handleEmailFocus}
                                    onBlur={handleEmailBlur}
                                    style={{ marginTop: 8 }}
                                    type="text"
                                    value={email}
                                    onChange={handleEmailChange}
                                    InputLabelProps={{
                                        shrink: false,
                                    }}
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            '&.Mui-focused fieldset': {
                                                borderColor: '#618B4A90',
                                            },
                                        },
                                    }}
                                />
                            </Box>
                        </Box>
                        <Box className="inputBx">
                            <Box sx={{ position: 'relative', ...(isLoginStart && { opacity: '0.5', pointerEvents: 'none' }) }}>
                                <InputLabel
                                    sx={{
                                        position: 'absolute',
                                        top: isPasswordFocused ? -16 : 22,
                                        left: 20,
                                        transition: 'top 0.5s ease, font-size 0.5s ease',
                                        pointerEvents: 'none',
                                        fontSize: fontSizePasswordLabel,
                                        color: isPasswordFocused ? '#618B4A99' : 'rgba(0, 0, 0, 0.54)',
                                        fontWeight: 600,
                                        transformOrigin: 'top left',
                                        cursor: 'text',
                                    }}
                                >
                                    {getTitle(language, 'pswHolder')}
                                </InputLabel>
                                <TextField
                                    onFocus={handlePasswordFocus}
                                    onBlur={handlePasswordBlur}
                                    style={{ marginTop: 8 }}
                                    type="password"
                                    value={password}
                                    onChange={handlePasswordChange}
                                    InputLabelProps={{
                                        shrink: false,
                                    }}
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            '&.Mui-focused fieldset': {
                                                borderColor: '#618B4A90',
                                            },
                                        },
                                    }}
                                />
                            </Box>
                        </Box>
                    </Box>
                    <Box>
                        <Box className="inputBx" onClick={() => setIsLoginStart(true)}>
                            <Button
                                variant="contained"
                                onClick={auth}
                                disabled={isLoginStart}
                                sx={{
                                    width: '232px',
                                    background: 'linear-gradient(45deg, #4bc96a, #a9ef83)',
                                    color: '#41693e',
                                    top: '110px',
                                    left: '10px',
                                }}
                            >
                                <Box sx={{ position: 'relative' }}>
                                    {getTitle(language, 'loginBtn')}
                                    <Box sx={{ position: 'absolute', top: '5px', right: '-80px' }}>{isLoginStart && <CircularProgress size={20} />}</Box>
                                </Box>
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default Login;
