import { useMediaQuery } from '@mui/material';

export function getMedia() {
    // mobile
    const isMobile = useMediaQuery('(max-width:400px)');

    // tablet
    const isTablet = useMediaQuery('(max-width: 800px)');

    // PC
    const isFullHD = useMediaQuery('(min-width: 1100px) and (max-width: 1920px) and (min-height: 1000px) and (max-height: 1080px)');
    const is2k = useMediaQuery('(min-width: 1921px) and (max-width: 2560px) and (min-height: 1000px) and (max-height: 1440px)');
    const is4k = useMediaQuery('(min-width: 2561px) and (min-height: 1000px)');
    const isFullHDVertical = useMediaQuery('(min-width: 1000px) and (max-width: 1080px) and (min-height: 1100px) and (max-height: 1920px)');
    const is2kVertical = useMediaQuery('(min-width: 1000px) and (max-width: 1440px) and (min-height: 1921px) and (max-height: 2560px)');
    const is4kVertical = useMediaQuery('(min-width: 1441px) and (min-height: 2561px)');

    // default rows per page for PC
    const ROWS_FullHD = 11;
    const ROWS_2k = 14;
    const ROWS_4k = 26;
    const ROWS_FullHD_Vertical = 22;
    const ROWS_2k_Vertical = 33;
    const ROWS_4k_Vertical = 53;

    // options for PC
    const rowsOptionsFullHD = [6, ROWS_FullHD, 48, 96];
    const rowsOptions2k = [8, ROWS_2k, 48, 96];
    const rowsOptions4k = [14, ROWS_4k, 48, 96];
    const rowsOptionsFullHDVertical = [5, ROWS_FullHD_Vertical, 50, 100];
    const rowsOptions2kVertical = [10, ROWS_2k_Vertical, 50, 100];
    const rowsOptions4kVertical = [15, ROWS_4k_Vertical, 88, 150];

    // mobile + tablet
    const isMobileHeight600 = useMediaQuery('(max-height: 600px)');
    const isMobileHeight650 = useMediaQuery('(min-height: 601px) and (max-height: 650px)');
    const isMobileHeight700 = useMediaQuery('(min-height: 651px) and (max-height: 700px)');
    const isMobileHeight770 = useMediaQuery('(min-height: 701px) and (max-height: 770px)');
    const isMobileHeight830 = useMediaQuery('(min-height: 771px) and (max-height: 830px)');
    const isMobileHeight900 = useMediaQuery('(min-height: 831px) and (max-height: 900px)');
    const isMobileHeight950 = useMediaQuery('(min-height: 901px) and (max-height: 950px)');
    const isMobileHeight1020 = useMediaQuery('(min-height: 951px) and (max-height: 1022px)');
    const isMobileHeight1070 = useMediaQuery('(min-height: 1023px) and (max-height: 1070px)');
    const isMobileHeight1135 = useMediaQuery('(min-height: 1071px) and (max-width: 1919px) and (max-height: 1134px)');
    const isMobileHeight1190 = useMediaQuery('(min-height: 1135px) and (max-width: 1919px) and (max-height: 1188px)');
    const isMobileHeight1245 = useMediaQuery('(min-height: 1189px) and (max-width: 1919px) and (max-height: 1244px)');
    const isMobileHeight1300 = useMediaQuery('(min-height: 1245px) and (max-width: 1919px) and (max-height: 1300px)');
    const isMobileHeight1380 = useMediaQuery('(min-height: 1300px) and (max-width: 1919px) and (max-height: 1364px)');
    const isMobileHeight1400 = useMediaQuery('(min-height: 1365px) and (max-width: 1919px) and (max-height: 1400px)');

    // default rows per page for mobile + tablet
    const ROWS_600_AND_650 = 3;
    const ROWS_700 = 4;
    const ROWS_770 = 5;
    const ROWS_830 = 6;
    const ROWS_900 = 7;
    const ROWS_950 = 8;
    const ROWS_1020 = 9;
    const ROWS_1070 = 10;
    const ROWS_1135 = 11;
    const ROWS_1190 = 12;
    const ROWS_1245 = 13;
    const ROWS_1300 = 14;
    const ROWS_1380 = 15;
    const ROWS_1400 = 16;

    // options for mobile + tablet
    const rowsOptionsMobile600 = [1, ROWS_600_AND_650, 9, 27, 54];
    const rowsOptionsMobile650 = [1, ROWS_600_AND_650, 9, 27, 54];
    const rowsOptionsMobile700 = [1, ROWS_700, 9, 27, 54];
    const rowsOptionsMobile770 = [2, ROWS_770, 10, 30, 60];
    const rowsOptionsMobile830 = [2, ROWS_830, 10, 30, 60];
    const rowsOptionsMobile900 = [3, ROWS_900, 11, 33, 66];
    const rowsOptionsMobile950 = [3, ROWS_950, 12, 36, 72];
    const rowsOptionsMobile1020 = [4, ROWS_1020, 48, 96];
    const rowsOptionsMobile1070 = [4, ROWS_1070, 48, 96];
    const rowsOptionsMobile1135 = [4, ROWS_1135, 48, 96];
    const rowsOptionsMobile1190 = [5, ROWS_1190, 48, 96];
    const rowsOptionsMobile1245 = [5, ROWS_1245, 48, 96];
    const rowsOptionsMobile1300 = [5, ROWS_1300, 48, 96];
    const rowsOptionsMobile1380 = [5, ROWS_1380, 48, 96];
    const rowsOptionsMobile1400 = [5, ROWS_1400, 48, 96];

    // options calculation
    const rowsPerPageOptions =
        /* the smallest ternary ever */
        isMobileHeight600
            ? rowsOptionsMobile600
            : isMobileHeight650
            ? rowsOptionsMobile650
            : isMobileHeight700
            ? rowsOptionsMobile700
            : isMobileHeight770
            ? rowsOptionsMobile770
            : isMobileHeight830
            ? rowsOptionsMobile830
            : isMobileHeight900
            ? rowsOptionsMobile900
            : isMobileHeight950
            ? rowsOptionsMobile950
            : isMobileHeight1020
            ? rowsOptionsMobile1020
            : isMobileHeight1070
            ? rowsOptionsMobile1070
            : isMobileHeight1135
            ? rowsOptionsMobile1135
            : isMobileHeight1190
            ? rowsOptionsMobile1190
            : isMobileHeight1245
            ? rowsOptionsMobile1245
            : isMobileHeight1300
            ? rowsOptionsMobile1300
            : isMobileHeight1380
            ? rowsOptionsMobile1380
            : isMobileHeight1400
            ? rowsOptionsMobile1400
            : /* PC */ isFullHD
            ? rowsOptionsFullHD
            : is2k
            ? rowsOptions2k
            : is4k
            ? rowsOptions4k
            : isFullHDVertical
            ? rowsOptionsFullHDVertical
            : is2kVertical
            ? rowsOptions2kVertical
            : is4kVertical
            ? rowsOptions4kVertical
            : rowsOptionsFullHD;

    // default option calculation
    const rowsPP =
        isMobileHeight600 || isMobileHeight650
            ? ROWS_600_AND_650
            : isMobileHeight700
            ? ROWS_700
            : isMobileHeight770
            ? ROWS_770
            : isMobileHeight830
            ? ROWS_830
            : isMobileHeight900
            ? ROWS_900
            : isMobileHeight950
            ? ROWS_950
            : isMobileHeight1020
            ? ROWS_1020
            : isMobileHeight1070
            ? ROWS_1070
            : isMobileHeight1135
            ? ROWS_1135
            : isMobileHeight1190
            ? ROWS_1190
            : isMobileHeight1245
            ? ROWS_1245
            : isMobileHeight1300
            ? ROWS_1300
            : isMobileHeight1380
            ? ROWS_1380
            : isMobileHeight1400
            ? ROWS_1400
            : /* PC */ isFullHD
            ? ROWS_FullHD
            : is2k
            ? ROWS_2k
            : isFullHDVertical
            ? ROWS_FullHD_Vertical
            : is2kVertical
            ? ROWS_2k_Vertical
            : is4kVertical
            ? ROWS_4k_Vertical
            : ROWS_4k;

    const debounceTimeValue = 600;

    return {
        isMobile,
        isTablet,
        isFullHD,
        rowsPerPageOptions,
        rowsPP,
        debounceTimeValue,
        isMobileHeight700,
    };
}
