import { IRecipe } from '../../models/Recipe/IRecipe';
import { itemAPI } from '../../services/ItemServices';
import { recipeAPI } from '../../services/RecipeServices';

export function fetchRecipes({
    currentPageNumber,
    itemsPerPage,
    searchPhrase,
    categories,
    descending,
    orderByName,
    orderByCost,
}: {
    currentPageNumber: number;
    itemsPerPage: number;
    searchPhrase: string;
    categories: string;
    descending: boolean;
    orderByName: boolean;
    orderByCost: boolean;
}) {
    const {
        data: recipes,
        isLoading: getAllItemsIsLoading,
        error: getAllItemsError,
        isSuccess: getAllItemsIsSuccess,
    } = recipeAPI.useGetRecipesQuery({
        page: currentPageNumber,
        limit: itemsPerPage,
        searchPhrase,
        categories,
        descending,
        orderByName,
        orderByCost,
    });

    return {
        recipes,
        getAllItemsIsLoading,
        getAllItemsError,
        getAllItemsIsSuccess,
    };
}

export function getAllItems() {
    const { data: items, error: getAllItemsError, isLoading: getAllItemsIsLoading, isSuccess: getAllItemsIsSuccess } = itemAPI.useGetAllItemsQuery();

    return {
        items,
        getAllItemsIsLoading,
        getAllItemsError,
        getAllItemsIsSuccess,
    };
}

export function getRecipe(recipeId: string | number, isLinear: boolean, size: string) {
    const { data: recipeData, isLoading: isLoadingRecipe, error: getRecipeError, isSuccess: isSuccessRecipe } = recipeAPI.useGetRecipeQuery(recipeId);

    return {
        recipeData,
        isLoadingRecipe,
        getRecipeError,
        isSuccessRecipe,
    };
}

export function saveRecipePost() {
    const [saveRecipe, { error: saveRecipeError, isSuccess: isSaveRecipeSuccess }] = recipeAPI.useSaveRecipeMutation();

    return { saveRecipe, saveRecipeError, isSaveRecipeSuccess };
}
