import { useState } from 'react';
import { Collapse, IconButton, TableCell, TableRow } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/ExpandMore';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import { getDateTime } from '../../../common/helpers';
import { getStyles } from '../../recipes/recipeCommon';
import { IGroupedOrdersByDay } from '../../../models/Order/IGroupedOrdersByDay';
import { IOrder } from '../../../models/Order/IOrder';
import { useLanguage } from '../../../context/LanguageContext';
import getTitle from '../../../common/translations';
import Order from './Order';

interface Props {
    ordersByDay: IGroupedOrdersByDay;
    handleEditOrder: (order: IOrder) => void;
    id: number;
    rowOpen: Record<number, boolean>;
    setRowOpen: React.Dispatch<React.SetStateAction<Record<number, boolean>>>;
    ordersArray: any;
}

export const OrdersByDay = ({ ordersByDay, handleEditOrder, id, rowOpen, setRowOpen, ordersArray }: Props) => {
    const { language } = useLanguage();
    const { tableRowWrapper } = getStyles();
    const [editOrderMode, setEditOrderMode] = useState<boolean>(false);

    const handleEditMode = () => {
        setEditOrderMode(!editOrderMode);
    };

    const handleClickCollapse = (id: string | number) => {
        const isOpen = !!rowOpen[Number(id)];
        setRowOpen(() => ({
            [id]: !isOpen,
        }));
    };

    const handleEditOrderIDs = (orderId: number) => {
        const order = ordersByDay.orders.findIndex((x) => x.id === orderId);
        handleEditOrder(ordersByDay.orders[order]);
    };

    const dayNames: any = {
        sunday: getTitle(language, 'sunday'),
        monday: getTitle(language, 'monday'),
        tuesday: getTitle(language, 'tuesday'),
        wednesday: getTitle(language, 'wednesday'),
        thursday: getTitle(language, 'thursday'),
        friday: getTitle(language, 'friday'),
        saturday: getTitle(language, 'saturday'),
    };

    const weekDay: string = ordersByDay?.week?.day?.toLowerCase() || '';
    const ordersWeekDay = dayNames[weekDay];

    let bgc1 = '';
    let bgc2 = '';

    if (ordersByDay?.week?.number % 2 == 0) {
        bgc1 = 'rgba(0, 136, 255, 0.15)';
        bgc2 = 'rgba(0, 136, 255, 0.05)';
    } else {
        bgc1 = 'rgba(255, 236, 39, 0.20)';
        bgc2 = 'rgba(255, 236, 39, 0.10)';
    }

    return (
        <>
            <TableRow
                onClick={() => handleClickCollapse(id)}
                sx={{
                    ...tableRowWrapper,
                    ...(id % 2 === 0 ? { backgroundColor: bgc1 } : { backgroundColor: bgc2 }),
                    ...(ordersArray[id + 1]?.week?.number > ordersByDay?.week?.number ? id !== ordersArray.length && { borderBottom: '2px solid #b5651d70' } : {}),
                }}
            >
                <TableCell>
                    <IconButton aria-label="expand row" size="small">
                        {rowOpen[Number(id)] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                    {getDateTime(ordersByDay.date)?.date || ordersByDay.date} - {ordersWeekDay}
                </TableCell>
            </TableRow>
            <TableRow>
                <Collapse in={!!rowOpen[Number(id)]} timeout="auto" unmountOnExit>
                    <TableCell sx={{ display: 'grid' }}>
                        {ordersByDay.orders.map((order) => (
                            <Order key={order.id} order={order} handleOrderId={handleEditOrderIDs} handleEditMode={handleEditMode} />
                        ))}
                    </TableCell>
                </Collapse>
            </TableRow>
        </>
    );
};
