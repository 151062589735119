import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './reducers/rootReducer';
import { itemAPI } from '../services/ItemServices';
import { stockAPI } from '../services/StockServices';
import { categoryAPI } from '../services/CategoryServices';
import { recipeAPI } from '../services/RecipeServices';
import { userAPI } from '../services/UserServices';
import { orderAPI } from '../services/OrderServices';
import { customerAPI } from '../services/CustomerServices';
import { baseAPI } from '../services/BaseAPI';

export const setupStore = () => {
    return configureStore({
        reducer: rootReducer,
        middleware: (configureStore) =>
            configureStore().concat(
                baseAPI.middleware,
                itemAPI.middleware,
                stockAPI.middleware,
                categoryAPI.middleware,
                recipeAPI.middleware,
                userAPI.middleware,
                orderAPI.middleware,
                customerAPI.middleware
            ),
    });
};

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];
