import { Box, Button } from '@mui/material';
import { useEffect, useState } from 'react';
import { BarChart, Bar, Brush, ReferenceLine, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import { useLanguage } from '../../context/LanguageContext';
import getTitle from '../../common/translations';
import { getMedia } from '../../common/mediaQuery';

const dataWeek = [
    { name: 'Week 1', Income: 14079, Outcome: 2498 },
    { name: 'Week 2', Income: 13846, Outcome: 4019 },
    { name: 'Week 3', Income: 12098, Outcome: 2343 },
    { name: 'Week 4', Income: 12522, Outcome: 4509 },
    { name: 'Week 5', Income: 13601, Outcome: 1399 },
    { name: 'Week 6', Income: 14723, Outcome: 4280 },
    { name: 'Week 7', Income: 12358, Outcome: 2444 },
    { name: 'Week 8', Income: 13233, Outcome: 4598 },
    { name: 'Week 9', Income: 13930, Outcome: 1777 },
    { name: 'Week 10', Income: 14461, Outcome: 3682 },
    { name: 'Week 11', Income: 11793, Outcome: 2371 },
    { name: 'Week 12', Income: 12952, Outcome: 1589 },
    { name: 'Week 13', Income: 1716, Outcome: 2924 },
    { name: 'Week 14', Income: 11817, Outcome: 4367 },
    { name: 'Week 15', Income: 14039, Outcome: 4310 },
    { name: 'Week 16', Income: 14378, Outcome: 4495 },
    { name: 'Week 17', Income: 12026, Outcome: 1309 },
    { name: 'Week 18', Income: 13826, Outcome: 4553 },
    { name: 'Week 19', Income: 14303, Outcome: 2607 },
    { name: 'Week 20', Income: 13220, Outcome: 4341 },
    { name: 'Week 21', Income: 12941, Outcome: 3820 },
    { name: 'Week 22', Income: 13415, Outcome: 4753 },
    { name: 'Week 23', Income: 13413, Outcome: 2164 },
    { name: 'Week 24', Income: 13759, Outcome: 4822 },
    { name: 'Week 25', Income: 12867, Outcome: 2933 },
    { name: 'Week 26', Income: 13169, Outcome: 1241 },
    { name: 'Week 27', Income: 14872, Outcome: 3085 },
    { name: 'Week 28', Income: 14709, Outcome: 4142 },
    { name: 'Week 29', Income: 14865, Outcome: 2989 },
    { name: 'Week 30', Income: 13208, Outcome: 1884 },
    { name: 'Week 31', Income: 12145, Outcome: 3423 },
    { name: 'Week 32', Income: 4002, Outcome: 4212 },
    { name: 'Week 33', Income: 14664, Outcome: 2847 },
    { name: 'Week 34', Income: 13189, Outcome: 1529 },
    { name: 'Week 35', Income: 12432, Outcome: 3930 },
    { name: 'Week 36', Income: 13723, Outcome: 3411 },
    { name: 'Week 37', Income: 12029, Outcome: 3758 },
    { name: 'Week 38', Income: 14811, Outcome: 4493 },
    { name: 'Week 39', Income: 12435, Outcome: 2209 },
    { name: 'Week 40', Income: 13837, Outcome: 4877 },
    { name: 'Week 41', Income: 14523, Outcome: 2497 },
    { name: 'Week 42', Income: 13259, Outcome: 3433 },
    { name: 'Week 43', Income: 11874, Outcome: 1373 },
    { name: 'Week 44', Income: 12743, Outcome: 4053 },
    { name: 'Week 45', Income: 4403, Outcome: 7164 },
    { name: 'Week 46', Income: 13544, Outcome: 2822 },
    { name: 'Week 47', Income: 14562, Outcome: 4864 },
    { name: 'Week 48', Income: 14012, Outcome: 3863 },
    { name: 'Week 49', Income: 12434, Outcome: 3273 },
    { name: 'Week 50', Income: 12235, Outcome: 1255 },
    { name: 'Week 51', Income: 13183, Outcome: 4013 },
    { name: 'Week 52', Income: 13114, Outcome: 3572 },
];

const dataMonth = [
    { name: 'Jan 2024', Income: 14079, Outcome: 2498 },
    { name: 'Feb 2024', Income: 13846, Outcome: 4019 },
    { name: 'Mar 2024', Income: 12098, Outcome: 2343 },
    { name: 'Apr 2024', Income: 12522, Outcome: 4509 },
    { name: 'May 2024', Income: 13601, Outcome: 16991 },
    { name: 'Jun 2024', Income: 14723, Outcome: 4280 },
    { name: 'Jul 2024', Income: 12358, Outcome: 2444 },
    { name: 'Aug 2024', Income: 13233, Outcome: 4598 },
    { name: 'Sep 2024', Income: 13930, Outcome: 1777 },
    { name: 'Oct 2024', Income: 14461, Outcome: 3682 },
    { name: 'Nov 2024', Income: 11793, Outcome: 2371 },
    { name: 'Dec 2024', Income: 12952, Outcome: 1589 },
];

const dataWeekWithDelta = dataWeek.map(({ name, Income, Outcome }) => ({
    name,
    Income,
    Outcome,
    Delta: Income - Outcome,
}));

const dataMonthWithDelta = dataMonth.map(({ name, Income, Outcome }) => ({
    name,
    Income,
    Outcome,
    Delta: Income - Outcome,
}));

const Finances = () => {
    const [parrentWidth, setParrentWidth] = useState(0);
    const [parrentHeight, setParrentHeight] = useState(0);

    const [activePeriod, setActivePeriod] = useState('week');
    const [currentWeekIndex, setCurrentWeekIndex] = useState(0);
    const [currentMonthIndex, setCurrentMonthIndex] = useState(0);

    const { language } = useLanguage();
    const { isFullHD } = getMedia();

    const switchPeriod = () => {
        setActivePeriod((prevPeriod) => (prevPeriod === 'week' ? 'month' : 'week'));
    };

    const handleNext = () => {
        switch (activePeriod) {
            case 'week':
                setCurrentWeekIndex((prevIndex) => Math.min(prevIndex + 7, dataWeekWithDelta.length - 1));
                break;
            case 'month':
                setCurrentMonthIndex((prevIndex) => Math.min(prevIndex + 3, dataMonthWithDelta.length - 1));
                break;
            default:
                break;
        }
    };

    const handlePrev = () => {
        switch (activePeriod) {
            case 'week':
                setCurrentWeekIndex((prevIndex) => Math.max(prevIndex - 7, 0));
                break;
            case 'month':
                setCurrentMonthIndex((prevIndex) => Math.max(prevIndex - 3, 0));
                break;
            default:
                break;
        }
    };

    const getData = () => {
        switch (activePeriod) {
            case 'week':
                return dataWeekWithDelta.slice(currentWeekIndex, currentWeekIndex + 7);
            case 'month':
                return dataMonthWithDelta.slice(currentMonthIndex, currentMonthIndex + 3);
            default:
                return [];
        }
    };

    useEffect(() => {
        const parentElement = document.getElementById('contentPageContainer');
        const width = parentElement && parentElement.offsetWidth;
        const height = parentElement && parentElement.offsetHeight;
        setParrentWidth(width || 0);
        setParrentHeight(height || 0);
    }, []);

    return (
        <Box justifyContent={'center'} sx={{ ...(isFullHD ? { height: '920px' } : {}) }}>
            {!isFullHD ? (
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        textAlign: 'center',
                        height: '450px',
                        fontSize: '25px',
                        pl: '10px',
                        pr: '10px',
                    }}
                >
                    {getTitle(language, 'screenResolution')}
                </Box>
            ) : (
                <>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', pl: '50px', pr: '50px', pt: '20px' }}>
                        <Box>
                            <Button variant="outlined" color={activePeriod === 'week' ? 'success' : 'error'} sx={{ boxShadow: '1px 2px 3px #999' }} onClick={switchPeriod}>
                                {activePeriod === 'week' ? getTitle(language, 'weekStats') : getTitle(language, 'monthStats')}
                            </Button>
                        </Box>
                        <Box>
                            <Button
                                variant="outlined"
                                color="secondary"
                                sx={{ boxShadow: '1px 2px 3px #999', mr: '20px' }}
                                disabled={(activePeriod === 'week' && currentWeekIndex === 0) || (activePeriod === 'month' && currentMonthIndex === 0)}
                                onClick={handlePrev}
                            >
                                {activePeriod === 'month' ? getTitle(language, 'prevMonth') : getTitle(language, 'prevWeeks')}
                            </Button>
                            <Button
                                variant="outlined"
                                sx={{ boxShadow: '1px 2px 3px #999' }}
                                disabled={
                                    (activePeriod === 'week' && dataWeekWithDelta.length === currentWeekIndex + 3) ||
                                    (activePeriod === 'month' && dataMonthWithDelta.length === currentMonthIndex + 3)
                                }
                                onClick={handleNext}
                            >
                                {activePeriod === 'month' ? getTitle(language, 'nextMonth') : getTitle(language, 'nextWeeks')}
                            </Button>
                        </Box>
                    </Box>
                    <Box sx={{ mt: '15px' }}>
                        <BarChart width={parrentWidth * 0.9} height={parrentHeight * 0.9} data={getData()} style={{ margin: '0 auto', pb: '40px' }}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="name" />
                            <YAxis />
                            <Tooltip />
                            <Legend verticalAlign="top" wrapperStyle={{ lineHeight: '40px' }} />
                            <ReferenceLine y={0} stroke="#000" />

                            <Brush dataKey="name" height={30} stroke="#8884d8" />

                            <Bar dataKey="Income" fill="#1976D2" name={getTitle(language, 'income')} />
                            <Bar dataKey="Outcome" fill="#B87333" name={getTitle(language, 'outcome')} />
                            <Bar dataKey="Delta" fill="#82ca9d" name={getTitle(language, 'delta')} />
                        </BarChart>
                    </Box>
                </>
            )}
        </Box>
    );
};

export default Finances;
