import { GET_ALL_CUSTOMERS, SAVE_CUSTOMER, SEARCH_CUSTOMERS, GET_CUSTOMERS, UPDATE_CUSTOMER, DELETE_CUSTOMER } from '../common/endpoints';
import { ICustomer } from '../models/ICustomer';
import { ISearchString } from '../models/ISearchString';
import { baseAPI } from './BaseAPI';

type SearchPropsType = {
    page: number;
    limit: number;
    searchPhrase: string;
    descending: boolean;
    orderByName: boolean;
    orderByOrdersQty: boolean;
};

type SearchResponseType = {
    data: ICustomer[];
    page: number;
    pageCount: number;
    pageSize: number;
    totalCount: number;
};

export const customerAPI = baseAPI.injectEndpoints({
    endpoints: (build) => ({
        getAllCustomers: build.query<ICustomer[], void>({
            query: () => ({
                url: GET_ALL_CUSTOMERS,
                method: 'GET',
            }),

            providesTags: (result) => ['Customer'],
        }),

        getCustomers: build.query<SearchResponseType, SearchPropsType>({
            query: ({ page, limit, searchPhrase, descending, orderByName, orderByOrdersQty }: SearchPropsType) => ({
                url: `${GET_CUSTOMERS}?page=${page}&limit=${limit}&searchPhrase=${searchPhrase}&descending=${descending}&orderByName=${orderByName}&orderByOrdersQty=${orderByOrdersQty}`,
                method: 'GET',
            }),

            providesTags: (result) => ['Customer'],
        }),

        searchCustomers: build.mutation<ICustomer[], ISearchString>({
            query: (searchPhrase: ISearchString) => ({
                url: SEARCH_CUSTOMERS,
                method: 'POST',
                body: searchPhrase,
            }),

            invalidatesTags: (result) => ['Customer'],
        }),

        updateCustomer: build.mutation<ICustomer[], ICustomer[]>({
            // TODO: add type is it ICustomer?
            query: (data: any) => ({
                url: UPDATE_CUSTOMER,
                method: 'POST',
                body: {
                    id: data.id,
                    name: `${data.firstName} ${data.lastName}`,
                    firstName: data.firstName,
                    lastName: data.lastName,
                    email: data.email,
                    phoneNumber: data.phoneNumber,
                    instagram: data.instagram,
                    faceBook: data.faceBook,
                    isDeleted: data.isDeleted,
                    createdAt: data.createdAt,
                    state: data.state,
                },
            }),

            invalidatesTags: (result) => ['Customer'],
        }),

        deleteCustomer: build.mutation<ICustomer, number | undefined>({
            query: (id: number) => ({
                url: `${DELETE_CUSTOMER}/${id}`,
                method: 'DELETE',
            }),

            invalidatesTags: ['Customer'],
        }),

        saveCustomer: build.mutation<ICustomer, Partial<ICustomer>>({
            query: (customer: Partial<ICustomer>) => ({
                url: SAVE_CUSTOMER,
                method: 'POST',
                body: customer,
            }),

            invalidatesTags: (result) => ['Customer'],
        }),
    }),
});
