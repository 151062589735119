import { Box, Button, DialogContentText, DialogTitle, Divider, Stack, TextField } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import getTitle from '../../../common/translations';
import { useLanguage } from '../../../context/LanguageContext';
import { getStyles } from '../tableCommon';
import { getCommonStyles } from '../../../common/commonStyles';

type Props = {
    handelCreateCategoryDialog: () => void;
    categoryName: string;
    setCategory: React.Dispatch<React.SetStateAction<string>>;
    handleCreateCategory: () => Promise<void>;
};

export function CreateCategoryDialog({ handelCreateCategoryDialog, categoryName, setCategory, handleCreateCategory }: Props) {
    const { language } = useLanguage();
    const { funcButtonWrapper, buttonItemsStyle } = getStyles();
    const { simpleBtnStyle } = getCommonStyles();

    return (
        <Box>
            <Box sx={funcButtonWrapper}>
                <Stack direction="row" divider={<Divider flexItem />} alignItems="center" justifyContent={'space-evenly'} gap={'5px'}>
                    <Button variant="outlined" onClick={handelCreateCategoryDialog} sx={{ ...buttonItemsStyle, ...simpleBtnStyle }}>
                        {getTitle(language, 'back_to_Items')}
                    </Button>
                </Stack>
            </Box>

            <Box
                sx={{
                    padding: '1rem 1rem',
                    pt: '0rem',
                }}
            >
                <DialogTitle
                    sx={{
                        padding: '1rem 0.3rem',
                    }}
                >
                    {getTitle(language, 'create_new_category')}
                </DialogTitle>

                <DialogContentText>{getTitle(language, 'create_new_category')}</DialogContentText>

                <TextField
                    autoFocus
                    margin="dense"
                    id="categoryName"
                    label={getTitle(language, 'category_name')}
                    type="text"
                    fullWidth
                    variant="standard"
                    value={categoryName}
                    onChange={(e) => setCategory(e.target.value)}
                />

                <Box mt="10px">
                    <Button variant="outlined" onClick={handleCreateCategory} endIcon={<SendIcon />} sx={{ ...buttonItemsStyle, ...simpleBtnStyle }}>
                        {getTitle(language, 'create')}
                    </Button>
                </Box>
            </Box>
        </Box>
    );
}
