import { useRef, useState } from 'react';
import { Autocomplete, Box, Button, InputAdornment, MenuItem, Select, Stack, TextField } from '@mui/material';
import { RECIPE_SIZE } from '../../../common/dictionary';
import { getCommonStyles } from '../../../common/commonStyles';
import { IRecipe } from '../../../models/Recipe/IRecipe';
import { ISubOrder } from '../../../models/Order/ISubOrder';
import { useLanguage } from '../../../context/LanguageContext';
import getTitle from '../../../common/translations';

interface IEditSubOrderProps {
    subOrder: ISubOrder;
    recipes?: IRecipe[] | undefined;
    handleOrderPrice?: () => void;
    setNewValueInSubOrder?: (fieldName: string, subOrderId: number | string, value: number) => void;
    deleteSubOrder: () => void;
}

const EditSubOrder = ({ subOrder, recipes, handleOrderPrice, setNewValueInSubOrder, deleteSubOrder }: IEditSubOrderProps) => {
    const { language } = useLanguage();
    const [newPrice, setNewPrice] = useState<number>(subOrder.price);
    const [orderCount, setOrderCount] = useState<number>(subOrder.count);
    const [recipeName, setRecipeName] = useState(subOrder.recipeName);
    const [recipeSize, setRecipeSize] = useState(
        subOrder.recipeCounter.toString().length === 1 && subOrder.recipeCounter !== 0
            ? `${subOrder.recipeCounter}.00`
            : subOrder.recipeCounter.toString().length === 3
            ? `${subOrder.recipeCounter}0`
            : subOrder.recipeCounter === 0
            ? ''
            : subOrder.recipeCounter.toString() || '1.00'
    );
    const inputCountRef = useRef<HTMLInputElement>(null);
    const inputPriceRef = useRef<HTMLInputElement>(null);

    let recipesNameList: string[] = [];
    recipes?.map((r) => recipesNameList.push(r.recipeName || ''));

    const handleRecipeName = (e: any, name: string | null) => {
        if (name && setNewValueInSubOrder) {
            const recipe = recipes?.find((x) => x.recipeName === name);
            setNewValueInSubOrder('recipeId', subOrder.id, recipe?.id as number);
            setRecipeName(name);
        }
    };

    const handleRecipeCounter = (e: any, counter: any) => {
        if (counter && setNewValueInSubOrder) {
            setNewValueInSubOrder('counter', subOrder.id, Number(counter));
            setRecipeSize(counter);
        }
    };

    const handlePrice = (e: any) => {
        setNewPrice(Number(e.target.value));
        setNewValueInSubOrder && setNewValueInSubOrder('price', subOrder.id, Number(e.target.value));
    };

    const handleOrderCount = (e: any) => {
        setOrderCount(Number(e.target.value));
        setNewValueInSubOrder && setNewValueInSubOrder('count', subOrder.id, Number(e.target.value));
    };

    const buttonStyle = { lineHeight: '1.25rem' /* fontSize: '0.75rem' */ };
    const autoCompleteLabelStyle = { '& .MuiInputLabel-root': { pointerEvents: 'none' } };
    const { simpleBtnStyle } = getCommonStyles();

    return (
        <Box style={{ padding: 10 }}>
            <Stack spacing={2} sx={{ minWidth: 100, maxWidth: 390 }}>
                <Autocomplete
                    disablePortal
                    id="recipes"
                    placeholder={getTitle(language, 'select_recipe')}
                    options={recipesNameList}
                    value={recipeName}
                    renderInput={(params) => <TextField {...params} required label={getTitle(language, 'select_recipe')} />}
                    onChange={handleRecipeName}
                    sx={autoCompleteLabelStyle}
                />
                <Select
                    size="medium"
                    disableUnderline
                    id="recipes"
                    placeholder={getTitle(language, 'recipe_size_kg')}
                    value={recipeSize}
                    onChange={handleRecipeCounter}
                    sx={autoCompleteLabelStyle}
                >
                    {RECIPE_SIZE.map((option: any) => (
                        <MenuItem key={option} value={option}>
                            {option}
                        </MenuItem>
                    ))}
                </Select>
                <TextField
                    inputRef={inputCountRef}
                    required
                    id={'count'}
                    label={getTitle(language, 'count_orders')}
                    placeholder={getTitle(language, 'count_orders')}
                    value={orderCount}
                    focused
                    type="number"
                    onChange={handleOrderCount}
                    onFocus={() => inputCountRef.current && inputCountRef.current.select()}
                    style={{ marginBottom: 10 }}
                />
                <TextField
                    inputRef={inputPriceRef}
                    required
                    id={'price'}
                    label={getTitle(language, 'price_orders')}
                    placeholder={getTitle(language, 'price_orders')}
                    value={newPrice}
                    focused
                    type="number"
                    onChange={handlePrice}
                    onFocus={() => inputPriceRef.current && inputPriceRef.current.select()}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">zł</InputAdornment>,
                    }}
                    style={{ marginBottom: 10 }}
                />
                <Button variant="outlined" onClick={deleteSubOrder} sx={{ ...buttonStyle, ...simpleBtnStyle }}>
                    {getTitle(language, 'delete')}
                </Button>
            </Stack>
        </Box>
    );
};

export default EditSubOrder;
