export class DTO_STATE {
    public static DTO_NEW = 'DTO_NEW';
    public static DTO_MODIFIED = 'DTO_MODIFIED';
    public static DTO_DELETED = 'DTO_DELETED';
    public static DTO_UNCHANGED = 'DTO_UNCHANGED';
}

export enum IS_DELETED {
    ACTIVE,
    IN_ACTIVE,
}

export enum UNIT_OF_WEIGHT {
    PCS = 'PCS',
    G = 'G',
}

export enum FIELD_NAMES {
    NAME = 'NAME',
    DESCRIPTION = 'DESCRIPTION',
}

export enum ORDER_STAGES {
    NEW,
    PREPAID,
    CANCELED,
    DONE,
    PICKED_UP,
}

export const ORDER_SOURCES = ['INSTAGRAM', 'FACEBOOK', 'WHATSAPP', 'PHONE'];

export const RECIPE_SIZE = [
    '0.25',
    '0.50',
    '0.75',
    '1.00',
    '1.25',
    '1.50',
    '1.75',
    '2.00',
    '2.25',
    '2.50',
    '2.75',
    '3.00',
    '3.25',
    '3.50',
    '3.75',
    '4.00',
    '4.25',
    '4.50',
    '4.75',
    '5.00',
    // '5.25',
    // '5.50',
    // '5.75',
    // '6.00',
    // '6.25',
    // '6.50',
    // '6.75',
    // '7.00',
    // '7.25',
    // '7.50',
    // '7.75',
    // '8.00',
    // '8.25',
    // '8.50',
    // '8.75',
    // '9.00',
    // '9.25',
    // '9.50',
    // '9.75',
    // '10.00',
];

export const RECIPE_SIZE_SPECIAL = [
    '0.25',
    '0.33',
    '0.50',
    '0.66',
    '0.75',
    '1.00',
    '1.25',
    '1.33',
    '1.50',
    '1.66',
    '1.75',
    '2.00',
    '2.25',
    '2.50',
    '2.75',
    '3.00',
    '3.25',
    '3.50',
    '3.75',
    '4.00',
    '4.25',
    '4.50',
    '4.75',
    '5.00',
    // '5.25',
    // '5.50',
    // '5.75',
    // '6.00',
    // '6.25',
    // '6.50',
    // '6.75',
    // '7.00',
    // '7.25',
    // '7.50',
    // '7.75',
    // '8.00',
    // '8.25',
    // '8.50',
    // '8.75',
    // '9.00',
    // '9.25',
    // '9.50',
    // '9.75',
    // '10.00',
];

export const CLEAR_BUTTON = 'InputAdornment-IconButton-SearchOffIcon-ff#11@!';
