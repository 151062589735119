import { Box, CircularProgress, Table, TableBody, TableContainer } from '@mui/material';
import { useEffect, useState } from 'react';
import { orderAPI } from '../../services/OrderServices';
import Orders from '../orders/common/Orders';
import RequiredItems from './RequiredItems/RequiredItems';
import MySnackBar from '../common/snackBars/MySnackBar';
import collapseTracker from '../../common/collapseTracker';
import { getMedia } from '../../common/mediaQuery';
import { useLanguage } from '../../context/LanguageContext';
import getTitle from '../../common/translations';

const HomePage = () => {
    const { isFullHD } = getMedia();
    const { language } = useLanguage();

    const [editOrderMode, setEditOrderMode] = useState<boolean>(false);
    const [fetchedOrderSnackBar, setFetchedOrderSnackBar] = useState<boolean>(false);
    const [fetchedRequiredItemsSnackBar, setFetchedRequiredItemsSnackBar] = useState<boolean>(false);
    const handleEditMode = () => {
        setEditOrderMode(!editOrderMode);
    };
    const { data: orders, isLoading, isError, isSuccess } = orderAPI.useGetOrderSeveralDayAheadQuery();
    const wrapperItems = { padding: '10px' };
    const opacityHalf = { opacity: '0.5', pointerEvents: 'none' };

    collapseTracker(orders);

    useEffect(() => {
        if (!isLoading && isSuccess && !isError) setFetchedOrderSnackBar(true);
    }, [isSuccess]);

    return (
        <Box sx={{ width: '100%', ...(isFullHD ? { minHeight: '920px' } : {}), ...(isLoading && !isSuccess && { backgroundColor: 'rgba(0,0,0,0.025)' }) }}>
            {isLoading && !isSuccess && (
                <Box sx={{ position: 'absolute', top: '40%', left: '48.5%' }}>
                    <CircularProgress color="success" />
                </Box>
            )}

            {!isLoading && isSuccess && orders && orders?.length > 0 ? (
                <Box sx={wrapperItems}>
                    <Box>
                        <TableContainer sx={{ borderRadius: '5px' }}>
                            <Table aria-labelledby="Required Items">
                                <TableBody>
                                    <RequiredItems orders={orders} setFetchedRequiredItemsSnackBar={setFetchedRequiredItemsSnackBar} />
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                    <Box>
                        <Orders orders={orders} handleEditModeInHOC={handleEditMode} />
                    </Box>
                </Box>
            ) : (
                <Box
                    sx={{
                        ...wrapperItems,
                        textAlign: 'center',
                        pt: '40px',
                        ...(isLoading && !isSuccess && opacityHalf),
                    }}
                >
                    <Box
                        sx={{
                            mb: '10px',
                        }}
                    >
                        {getTitle(language, 'home_page_info_one')}
                    </Box>
                    <Box>{getTitle(language, 'home_page_info_two')}</Box>
                </Box>
            )}
            <MySnackBar duration={5000} openSnackBar={fetchedOrderSnackBar} handleClose={() => setFetchedOrderSnackBar(false)} severity={'success'} alertText="Orders fetched!" />
            <MySnackBar
                duration={5000}
                openSnackBar={fetchedRequiredItemsSnackBar}
                handleClose={() => setFetchedRequiredItemsSnackBar(false)}
                severity={'success'}
                alertText="Required items fetched!"
            />
        </Box>
    );
};

export default HomePage;
