import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton, Stack, TextField } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import ContentCopy from '@mui/icons-material/ContentCopy';
import SendIcon from '@mui/icons-material/Send';

import { customerAPI } from '../../../services/CustomerServices';
import { DTO_STATE, IS_DELETED, ORDER_SOURCES, ORDER_STAGES } from '../../../common/dictionary';
import { getCommonStyles } from '../../../common/commonStyles';
import { getDateTime, getMessageWithOrderNumber } from '../../../common/helpers';
import { ICustomer } from '../../../models/ICustomer';
import { IOrder } from '../../../models/Order/IOrder';
import { IPrepayment } from '../../../models/Order/IPrepayment';
import { ISearchString } from '../../../models/ISearchString';
import { ISubOrder } from '../../../models/Order/ISubOrder';
import { orderAPI } from '../../../services/OrderServices';
import { recipeAPI } from '../../../services/RecipeServices';
import { useLanguage } from '../../../context/LanguageContext';
import CreateCustomer from '../../customers/CreateCustomer';
import CreateSubOrder from './CreateSubOrder';
import getTitle from '../../../common/translations';
import MySnackBar from '../../common/snackBars/MySnackBar';

interface CreateOrderProps {
    handleExistOrderId: (ids: string[]) => void;
    handleCreateOrderMode: () => void;
}

interface Validation {
    id: string | number;
    result: boolean;
}

interface IError {
    status: number;
    data: string[];
}

const subOrdersValidations: Validation[] = [];

const CreateOrder = ({ handleExistOrderId, handleCreateOrderMode }: CreateOrderProps) => {
    const { language } = useLanguage();
    const { simpleBtnStyle } = getCommonStyles();

    const { data: recipes, error: getRecipesError, isLoading: getRecipesIsLoading, isSuccess: getRecipesIsSuccess } = recipeAPI.useGetAllRecipesQuery();
    const [searchCustomers, { error: searchError, isSuccess: searchIsSuccess }] = customerAPI.useSearchCustomersMutation();
    const [saveOrder, { error: saveOrderError, isSuccess: saveOrderIsSuccess }] = orderAPI.useSaveOrderMutation();
    const [expandCreateCustomer, setExpandCreateCustomer] = useState<boolean>(false);
    const [selectedCustomer, setSelectedCustomer] = useState<ICustomer>();
    const [infoExistOrderSnackBar, setInfoExistOrderSnackBar] = useState<boolean>(false);
    const [saveOrderSuccessSnackBar, setSaveOrderSuccessSnackBar] = useState<boolean>(false);
    const [saveOrderErrorSnackBar, setSaveOrderErrorSnackBar] = useState<boolean>(false);
    const [copyOrderNumberSnackBar, setCopyOrderNumberSnackBar] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);
    const [subOrdersCheck, setSubOrdersCheck] = useState(false);

    const [prepaymentInitialState] = useState<IPrepayment>({
        id: -1,
        prepayment: 0,
        state: DTO_STATE.DTO_NEW,
    });
    useParams;
    const [newOrderInitialState] = useState<IOrder>({
        id: crypto.randomUUID(),
        orderNumber: '',
        description: '',
        isDeleted: IS_DELETED.ACTIVE,
        state: DTO_STATE.DTO_NEW,
        price: 0,
        cost: 0,
        customerId: 0,
        subOrders: [],
        stage: ORDER_STAGES.NEW,
    });
    const [subOrderInitialState] = useState<ISubOrder[]>([
        {
            id: crypto.randomUUID(),
            recipeName: '',
            recipeId: 0,
            description: '',
            price: 0,
            productCost: 0,
            count: 1,
            recipeCounter: 0,
            orderId: newOrderInitialState.id,
            isDeleted: IS_DELETED.ACTIVE,
            state: DTO_STATE.DTO_NEW,
        },
    ]);
    const [searchResponse, setSearchResponse] = useState<ICustomer[]>();
    let customersField: string[] = [];
    const [subOrders, setSubOrders] = useState<ISubOrder[]>([...subOrderInitialState]);
    const [newOrder, setNewOrder] = useState<IOrder>({ ...newOrderInitialState });
    newOrder.prepayment = { ...prepaymentInitialState };
    newOrder.subOrders = [...subOrders];
    const [orderDescription, setOrderDescription] = useState<string>('');
    const [searchPhrase, setSearchPhrase] = useState<string>('');
    let searchAfterAddNewCustomer = '';

    const search = () => {
        let timeout: string | number | NodeJS.Timeout | undefined;
        return (e: { target: { value: string } } | null) => {
            const localSearchPhrase: string = e === null ? searchAfterAddNewCustomer : e.target.value;
            searchAfterAddNewCustomer = localSearchPhrase;
            setSearchPhrase(localSearchPhrase);
            const searchString: ISearchString = { search: localSearchPhrase };
            clearTimeout(timeout);
            timeout = setTimeout(() => {
                setSearchResponse(undefined);
                localSearchPhrase.length >= 3 &&
                    searchCustomers(searchString).then((result) => {
                        if ('data' in result) {
                            setSearchResponse(result.data);
                            handelDialogOpen();
                        }
                    });
            }, 1000);
        };
    };

    const debounceSearch = useMemo(() => search(), []);
    const processSearchResponse = () => {
        const exInstagram = new RegExp('.*instagram.*');
        const exFacebook = new RegExp('.*facebook.*');
        const exMail = new RegExp('^[\\w\\d\\.]+@[\\w\\.]+$');
        const exPhoneNumber = new RegExp('^\\+?\\d+$');
        if (exInstagram.test(searchPhrase)) {
            searchResponse?.map((c) => customersField.push(c.instagram || ''));
            return;
        }
        if (exFacebook.test(searchPhrase)) {
            searchResponse?.map((c) => customersField.push(c.faceBook || ''));
            return;
        }
        if (exMail.test(searchPhrase)) {
            searchResponse?.map((c) => customersField.push(c.email || ''));
            return;
        }
        if (exPhoneNumber.test(searchPhrase)) {
            searchResponse?.map((c) => customersField.push(c.phoneNumber || ''));
            return;
        }
        searchResponse?.map((c) => customersField.push(c.name || ''));
    };

    useMemo(() => processSearchResponse(), [searchResponse]);

    const handelCreateCustomer = () => {
        setExpandCreateCustomer(!expandCreateCustomer);
    };

    const [dialogOpen, setDialogOpen] = useState<boolean>(false);

    const handelDialogOpen = () => {
        setDialogOpen(!dialogOpen);
    };

    const handleCustomerId = (e: any, customer: ICustomer) => {
        newOrder.customerId = customer.id;
        setSelectedCustomer(customer);
        handelDialogOpen();
    };
    const [selectCustomerDialog, setSelectCustomerDialog] = useState<boolean>(false);
    const handelSelectCustomerDialog = () => {
        setSelectCustomerDialog(!selectCustomerDialog);
    };

    const handleOrderCosts = () => {
        let cost = 0;
        let price = 0;
        subOrders.forEach((element) => {
            cost += element.productCost;
            price += element.price * element.count;
        });

        setNewOrder((prevState) => {
            return {
                ...prevState,
                cost: cost,
                price: price,
            };
        });
    };

    const [pickUpDate, setPickUpDate] = useState('');
    const handlePickUpDateTime = (e: any) => {
        const exDateTime = new RegExp('(?<year>\\d{4})-(?<month>\\d{1,2})-(?<day>\\d{1,2})T(?<hour>\\d{1,2}):(?<min>\\d{1,2})');
        if (exDateTime.test(e.target.value)) setPickUpDate(e.target.value);
    };

    const handleAddSubOrder = () => {
        setSubOrders((prevState) => {
            return [
                ...prevState,
                {
                    id: crypto.randomUUID(),
                    recipeName: '',
                    recipeId: 0,
                    description: '',
                    price: 0,
                    productCost: 0,
                    count: 1,
                    recipeCounter: 0,
                    orderId: newOrderInitialState.id,
                    isDeleted: IS_DELETED.ACTIVE,
                    state: DTO_STATE.DTO_NEW,
                },
            ];
        });
        newOrder.subOrders = [...subOrders];
    };

    const [newOrderNumber, setNewOrderNumber] = useState('');
    const [existOrderId, setExistOrderId] = useState<string[]>();
    const handleSaveNewOrder = () => {
        newOrder.description = orderDescription;
        newOrder.pickUpAt = pickUpDate;

        saveOrder(newOrder).then((res) => {
            if ('error' in res) {
                // setIsError(true); TODO: double check the purpose and logic behind this
            }
            if ('error' in res) {
                const err = res.error as IError;
                if (err.status === 400) setExistOrderId(err.data);
            }
            if ('data' in res) res.data.orderNumber && setNewOrderNumber(res.data.orderNumber);
        });
    };

    // FIXME: state doesn't use
    const [hasCorrectSubOrders, setHasCorrectSubOrders] = useState<boolean>(false);
    const handleValidationSubOrder = (result: boolean, id: string | number) => {
        const index = subOrdersValidations.findIndex((el) => el.id === id);
        if (index !== -1) {
            let element = subOrdersValidations[index];
            element.result = result;
            subOrdersValidations[index] = element;
        } else {
            subOrdersValidations.push({ id, result });
        }
        const isValid = subOrdersValidations.every((el) => el.result === true);

        // FIXME: what is it for?
        setHasCorrectSubOrders(isValid);
    };

    const handleUpdateSubOrders = (subOrder: ISubOrder) => {
        const newSubOrders: ISubOrder[] = [];
        subOrders?.forEach((el) => {
            if (el.id !== subOrder.id) newSubOrders.push(el);
            else newSubOrders.push(subOrder);
        });
        setSubOrders([...newSubOrders]);

        handleOrderCosts();
    };

    const handleDeleteSubOrder = (id: string | number) => {
        const newSubOrders: ISubOrder[] = [];
        subOrders?.forEach((el) => {
            if (el.id !== id) newSubOrders.push(el);
        });
        setSubOrders([...newSubOrders]);

        handleOrderCosts();
    };

    const buttonStyle = { lineHeight: '1.25rem' /* fontSize: '0.75rem' */ };

    const copyOrderNumber = () => {
        window.navigator.clipboard.writeText(getMessageWithOrderNumber(newOrderNumber));
        setCopyOrderNumberSnackBar(true);
    };

    useEffect(() => {
        if (saveOrderError && existOrderId) setInfoExistOrderSnackBar(true);
        if (saveOrderError && !existOrderId) setSaveOrderErrorSnackBar(true);
        if (saveOrderIsSuccess && !saveOrderError) setSaveOrderSuccessSnackBar(true);
    }, [saveOrderError, existOrderId, saveOrderIsSuccess]);

    useEffect(() => {
        let initialValue = 0;
        subOrders.forEach((currentValue) => {
            if (currentValue.recipeName === '') {
                initialValue += 1;
            }
            if (currentValue.price === 0) {
                initialValue += 1;
            }
            if (currentValue.recipeCounter === 0) {
                initialValue += 1;
            }
            if (currentValue.count === 0) {
                initialValue += 1;
            }
        });

        if (initialValue === 0) {
            setSubOrdersCheck(true);
        }
    }, [subOrders]);

    return (
        <Box style={{ margin: 10 }}>
            <Box sx={{ paddingBottom: '10px' }}>
                <Stack direction="row" divider={<Divider flexItem />} alignItems="center" justifyContent={'space-evenly'} gap={'5px'}>
                    <Button variant="outlined" onClick={handleCreateOrderMode} sx={{ ...buttonStyle, ...simpleBtnStyle }}>
                        {getTitle(language, 'back_to_orders')}
                    </Button>
                </Stack>
            </Box>
            {saveOrderIsSuccess ? (
                <Box>
                    <MySnackBar
                        duration={5000}
                        openSnackBar={saveOrderSuccessSnackBar}
                        handleClose={() => setSaveOrderSuccessSnackBar(false)}
                        severity={'success'}
                        alertText="Save order success!"
                    />
                    {/* Template for Natali. Need to save templates in DB user settings table.
                    me */}
                    {getMessageWithOrderNumber(newOrderNumber)}
                    {/* {getTitle(language, 'order_number')} {newOrderNumber} */}
                    <IconButton onClick={copyOrderNumber}>
                        <ContentCopy />
                    </IconButton>
                </Box>
            ) : saveOrderError && existOrderId ? (
                <Box sx={{ mt: '20px' }}>
                    <MySnackBar
                        duration={5000}
                        openSnackBar={infoExistOrderSnackBar}
                        handleClose={() => setInfoExistOrderSnackBar(false)}
                        severity={'info'}
                        alertText="Order exist!"
                    />
                    {getTitle(language, 'an_order_for_the_customer')}
                    &nbsp;
                    {selectedCustomer?.name}
                    &nbsp;
                    {getTitle(language, 'at')} {getDateTime(pickUpDate)?.date}
                    &nbsp;
                    {getTitle(language, 'already_exists')}.
                    <Box sx={{ mt: '20px' }}>
                        <Button variant="outlined" onClick={() => handleExistOrderId(existOrderId)} sx={{ ...buttonStyle, ...simpleBtnStyle }}>
                            {getTitle(language, 'searchedOrder')}
                        </Button>
                    </Box>
                </Box>
            ) : (
                <Box>
                    <Stack spacing={2} sx={{ minWidth: 100, maxWidth: 400, mb: '10px' }}>
                        <TextField
                            style={{ marginBottom: 10 }}
                            required
                            id={'description'}
                            label={getTitle(language, 'order_description')}
                            placeholder={getTitle(language, 'order_description')}
                            value={orderDescription}
                            focused
                            multiline
                            onChange={(e) => setOrderDescription(e.target.value)}
                            color={orderDescription === '' ? 'error' : 'success'}
                        />
                        <TextField
                            style={{ marginBottom: 10 }}
                            required
                            id={'searchPhrase'}
                            label={getTitle(language, 'search_customer')}
                            placeholder={getTitle(language, 'search_customer')}
                            value={searchPhrase}
                            onChange={debounceSearch}
                            focused
                            error={searchPhrase.length < 3}
                        />
                        <Dialog fullWidth open={dialogOpen} onClose={handelDialogOpen}>
                            <DialogTitle>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Box>{getTitle(language, 'customers')}</Box>
                                    <Box>
                                        <Button variant="outlined" onClick={handelDialogOpen} sx={{ ...buttonStyle, ...simpleBtnStyle }}>
                                            {getTitle(language, 'close')}
                                        </Button>
                                    </Box>
                                </Box>
                            </DialogTitle>
                            <DialogContent>
                                <Box sx={{ display: 'flex', justifyContent: 'center' }}>{!searchIsSuccess && !searchResponse && <CircularProgress />}</Box>

                                {searchResponse &&
                                    searchResponse.map((c) => (
                                        <CustomerInDialog key={c.id} customer={c} handleCustomerId={handleCustomerId} selectedCustomer={newOrder.customerId === c.id} />
                                    ))}
                            </DialogContent>
                            <DialogActions></DialogActions>
                        </Dialog>

                        {searchError && (
                            <>
                                <Box>{getTitle(language, 'customer_not_found')}</Box>

                                <Button variant="outlined" onClick={handelCreateCustomer} sx={{ ...buttonStyle, ...simpleBtnStyle }}>
                                    {getTitle(language, 'create_customer')}
                                </Button>

                                {expandCreateCustomer && <CreateCustomer search={debounceSearch} handleCreateCustomerDialog={handelCreateCustomer} />}
                            </>
                        )}

                        {searchResponse && (
                            <Button variant="outlined" onClick={handelDialogOpen} sx={{ ...buttonStyle, ...simpleBtnStyle }}>
                                {getTitle(language, 'foundCustomers')}
                            </Button>
                        )}

                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Box sx={{ mr: '10px', ml: '10px' }}>{getTitle(language, 'selected_customer')}</Box>

                            {selectedCustomer?.name === undefined ? (
                                <Box sx={{ backgroundColor: '#f49d9d75', padding: '3px', borderRadius: '3px', pr: '6px', pl: '6px' }}>{getTitle(language, 'no_customer')}</Box>
                            ) : (
                                <Box>{selectedCustomer?.name}</Box>
                            )}
                        </Box>
                        <Box>
                            {selectedCustomer?.name !== undefined ? (
                                <Button variant="outlined" onClick={handelSelectCustomerDialog} sx={{ ...buttonStyle, ...simpleBtnStyle }}>
                                    {getTitle(language, 'customer_info')}
                                </Button>
                            ) : (
                                <></>
                            )}
                        </Box>
                    </Stack>
                    <Box>
                        <Button variant="outlined" onClick={handleAddSubOrder} sx={{ ...buttonStyle, ...simpleBtnStyle }}>
                            {getTitle(language, 'add_suborder')}
                        </Button>
                    </Box>
                    {subOrders.map((s) => (
                        <CreateSubOrder
                            key={s.id}
                            handleCorrectSubOrder={handleValidationSubOrder}
                            subOrder={s}
                            recipes={recipes}
                            handleUpdateSubOrders={handleUpdateSubOrders}
                            deleteSubOrder={handleDeleteSubOrder}
                        />
                    ))}
                    <Box>
                        {getTitle(language, 'total_order_price')}
                        {Number(newOrder.price).toFixed(2)} zł
                    </Box>
                    <Box>
                        {getTitle(language, 'total_order_cost')}
                        {Number(newOrder.cost).toFixed(2)} zł
                    </Box>
                    <Box>
                        {getTitle(language, 'total_order_profit')}
                        {Number(newOrder.price - newOrder.cost).toFixed(2)} zł
                    </Box>
                    <Box style={{ paddingTop: 10 }}>
                        <TextField
                            style={{ marginBottom: 10 }}
                            required
                            id={'pickUpDate'}
                            label={getTitle(language, 'pick_up_date')}
                            type="datetime-local"
                            placeholder={getTitle(language, 'pick_up_date')}
                            onChange={handlePickUpDateTime}
                            onClick={handlePickUpDateTime}
                            color={!pickUpDate ? 'error' : 'success'}
                            focused
                        />
                    </Box>
                    <Box>
                        <Button
                            disabled={orderDescription === '' || selectedCustomer === undefined || pickUpDate === '' || !subOrdersCheck || isError || subOrders.length === 0}
                            variant="outlined"
                            onClick={handleSaveNewOrder}
                            sx={{ ...buttonStyle, ...simpleBtnStyle, mt: '20px' }}
                            endIcon={<SendIcon />}
                        >
                            {getTitle(language, 'save_order')}
                        </Button>
                    </Box>
                    {selectedCustomer && (
                        <Dialog fullWidth open={selectCustomerDialog} onClose={handelSelectCustomerDialog}>
                            <DialogTitle>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Box> {getTitle(language, 'selected_customer')}</Box>
                                    <Box>
                                        <Button variant="outlined" onClick={handelSelectCustomerDialog} sx={{ ...buttonStyle, ...simpleBtnStyle }}>
                                            {'X'}
                                        </Button>
                                    </Box>
                                </Box>
                            </DialogTitle>
                            <DialogContent>
                                <CustomerInDialog
                                    key={selectedCustomer.id}
                                    customer={selectedCustomer}
                                    handleCustomerId={handleCustomerId}
                                    selectedCustomer={newOrder.customerId === selectedCustomer.id}
                                />
                            </DialogContent>
                            <DialogActions></DialogActions>
                        </Dialog>
                    )}
                </Box>
            )}
            <MySnackBar
                duration={5000}
                openSnackBar={saveOrderErrorSnackBar}
                handleClose={() => setSaveOrderErrorSnackBar(false)}
                severity={'error'}
                alertText="Save order error!"
            />
            <MySnackBar
                duration={5000}
                openSnackBar={copyOrderNumberSnackBar}
                handleClose={() => setCopyOrderNumberSnackBar(false)}
                severity={'success'}
                alertText="The order number is copied!"
            />
        </Box>
    );
};

export default CreateOrder;

interface CustomerInDialogProps {
    customer: ICustomer;
    handleCustomerId: (e: any, customer: ICustomer) => void;
    selectedCustomer: boolean;
}

const CustomerInDialog = ({ customer, handleCustomerId, selectedCustomer }: CustomerInDialogProps) => {
    const { language } = useLanguage();
    let color = customer.id % 2 === 0 ? 'rgba(245, 245, 220, 0.7)' : 'rgba(211, 211, 211, 0.5)';
    color = selectedCustomer ? 'rgba(50, 205, 50, 0.3)' : color;
    const handleSelectCustomer = (e: any) => {
        handleCustomerId(e, customer);
    };
    const buttonStyle = { lineHeight: '1.25rem' /* fontSize: '0.75rem' */ };
    const { simpleBtnStyle } = getCommonStyles();

    return (
        <Box sx={{ backgroundColor: color, padding: '10px' }}>
            <Box sx={{ lineHeight: '2rem' }}>
                {getTitle(language, 'full_name')}
                {customer.name}
            </Box>
            <Box sx={{ lineHeight: '2rem' }}>
                {getTitle(language, 'email')}
                {customer.email}
            </Box>
            <Box sx={{ lineHeight: '2rem' }}>
                {getTitle(language, 'phone_number')}
                {customer.phoneNumber}
            </Box>
            <Box sx={{ lineHeight: '2rem' }}>
                {getTitle(language, 'instagram')}
                {customer.instagram}
            </Box>
            <Box sx={{ lineHeight: '2rem' }}>
                {getTitle(language, 'facebook')}
                {customer.faceBook}
            </Box>
            {!selectedCustomer && (
                <Box>
                    <Button variant="outlined" onClick={handleSelectCustomer} sx={{ ...buttonStyle, ...simpleBtnStyle }}>
                        {getTitle(language, 'select')}
                    </Button>
                </Box>
            )}
        </Box>
    );
};
