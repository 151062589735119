import { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { useLanguage } from '../../context/LanguageContext';
import getTitle from '../../common/translations';
import { useCustomSpan } from './useCustomSpan';
import './ChaiPage.css';

const ChaiPage = () => {
    const { language } = useLanguage();
    const [translatedText, setTranslatedText] = useState('');
    const {
        vapourStyle1,
        vapourStyle2,
        vapourStyle3,
        vapourStyle4,
        vapourStyle5,
        vapourStyle6,
        vapourStyle7,
        vapourStyle8,
        vapourStyle9,
        vapourStyle10,
        vapourStyle11,
        vapourStyle12,
        vapourStyle13,
        vapourStyle14,
        vapourStyle15,
        vapourStyle16,
        vapourStyle17,
        vapourStyle18,
        vapourStyle19,
        vapourStyle20,
        vapourStyle21,
    } = useCustomSpan();

    useEffect(() => {
        setTranslatedText('');
        setTimeout(() => {
            const text = getTitle(language, 'chaiPage');
            setTranslatedText(text);
        }, 2000);
    }, [language]);

    return (
        <Box className="home">
            <Box className="container">
                <Box className="plate"></Box>
                <Box className="cup">
                    <Box className="top">
                        <Box className="vapour">
                            <Box component="span" style={vapourStyle1}></Box>
                            <Box component="span" style={vapourStyle3}></Box>
                            <Box component="span" style={vapourStyle16}></Box>

                            <Box style={vapourStyle5}></Box>
                            <Box style={vapourStyle13}></Box>
                            <Box style={vapourStyle20}></Box>

                            <Box component="span" style={vapourStyle6}></Box>
                            <Box component="span" style={vapourStyle7}></Box>
                            <Box component="span" style={vapourStyle10}></Box>

                            <Box style={vapourStyle8}></Box>
                            <Box style={vapourStyle17}></Box>
                            <Box style={vapourStyle11}></Box>

                            <Box component="span" style={vapourStyle12}></Box>
                            <Box component="span" style={vapourStyle14}></Box>
                            <Box component="span" style={vapourStyle2}></Box>

                            <Box style={vapourStyle9}></Box>
                            <Box style={vapourStyle15}></Box>
                            <Box style={vapourStyle4}></Box>

                            <Box component="span" style={vapourStyle19}></Box>
                            <Box component="span" style={vapourStyle18}></Box>
                            <Box component="span" style={vapourStyle21}></Box>
                        </Box>
                        <Box className="circle">
                            <Box className="tea"></Box>
                        </Box>
                    </Box>
                    <Box className="handle"></Box>
                </Box>
            </Box>

            <Box className="animate-text pulsate">
                {translatedText.split('').map((letter, index) => (
                    <Box component="span" key={index} style={{ animationDelay: `${index * 0.1}s` }}>
                        {letter === ' ' ? '\u00A0' : letter}
                    </Box>
                ))}
            </Box>
        </Box>
    );
};

export default ChaiPage;
