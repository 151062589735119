import { GET_USER_DATA } from '../common/endpoints';
import { IUser } from '../models/IUser';
import { baseAPI } from './BaseAPI';

export const userAPI = baseAPI.injectEndpoints({
    endpoints: (build) => ({
        getUserData: build.query<IUser, void>({
            query: () => ({
                url: GET_USER_DATA,
                method: 'GET',
            }),

            providesTags: (result) => [{ type: 'User', id: 'UserData' }],
        }),
    }),
});
