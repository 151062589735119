import { IGroupedOrdersByDay } from '../models/Order/IGroupedOrdersByDay';

const collapseTracker = (orders: IGroupedOrdersByDay[] | undefined) => {
    let oldData = sessionStorage.getItem('orders')?.length;
    sessionStorage.setItem('orders', JSON.stringify(orders));
    let newData = sessionStorage.getItem('orders')?.length;

    if (oldData === undefined) {
        oldData = 0;
    }

    if (newData === undefined) {
        newData = 0;
    }

    if (newData < oldData) {
        sessionStorage.setItem('deletedOrClosed', 'true');
    }

    if (newData > oldData) {
        sessionStorage.setItem('deletedOrClosed', 'false');
    }
};

export default collapseTracker;
