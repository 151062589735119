import { useState } from 'react';
import { Box } from '@mui/material';
import { FuncButtonWIcon } from '../../../common/buttons/FunctionalButton';
import CheckboxList from '../../../common/lists/CheckboxList';
import { IItem } from '../../../../models/Items/IItem';
import { ICategory } from '../../../../models/ICategory';
import { addItemCategory, getStyles } from '../../tableCommon';

export const CategoryForm = ({ item, categories, addItemCategory }: { item: any; categories: ICategory[] | undefined; addItemCategory: addItemCategory }) => {
    const data = item?.categories ? item.categories : [];
    const [checkedCategories, setChecked] = useState<any>([...data]);

    const { titleCategoryForm, wrapperStyle, categoryFormStyle } = getStyles();

    const handleAddCategory = () => {
        const newItem: IItem = { ...item };
        newItem.categories = checkedCategories;
        addItemCategory(newItem);
    };

    return (
        <Box sx={{ position: 'relative', ...wrapperStyle }}>
            <CheckboxList categories={categories} checked={checkedCategories} setChecked={setChecked} />
            <Box sx={categoryFormStyle}>
                <FuncButtonWIcon field={titleCategoryForm} handleClick={handleAddCategory} iconName={'sendIcon'} />
            </Box>
        </Box>
    );
};
