import { Key } from 'react';
import { AlertColor } from '@mui/material';
import { alpha } from '@mui/material';
import { MutationTrigger } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import { BaseQueryFn, FetchArgs, FetchBaseQueryError, FetchBaseQueryMeta, MutationDefinition } from '@reduxjs/toolkit/dist/query';
import { IItem } from '../../models/Items/IItem';
import { IStock } from '../../models/IStock';
import getTitle from '../../common/translations';
import { useLanguage } from '../../context/LanguageContext';
import { UNIT_OF_WEIGHT } from '../../common/dictionary';
import { getMedia } from '../../common/mediaQuery';

export const successColor: AlertColor = 'success';
export const errorColor: AlertColor = 'error';
export const unitsOfWeight = [UNIT_OF_WEIGHT.G, UNIT_OF_WEIGHT.PCS];

export type Order = 'asc' | 'desc';

export interface HeadCell {
    id: string;
    disablePadding: boolean;
    label: string;
    numeric: boolean;
    visible: boolean;
}

export type addItemCategory = MutationTrigger<
    MutationDefinition<
        IItem,
        BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, {}, FetchBaseQueryMeta>,
        'Order' | 'Item' | 'Auth' | 'Category' | 'Customer' | 'Recipe' | 'User',
        IItem,
        'baseAPI'
    >
>;

export type addStock = MutationTrigger<
    MutationDefinition<
        IStock | undefined,
        BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, {}, FetchBaseQueryMeta>,
        'Order' | 'Item' | 'Auth' | 'Category' | 'Customer' | 'Recipe' | 'User',
        IStock,
        'baseAPI'
    >
>;

export type renameItem = MutationTrigger<
    MutationDefinition<
        [number, string],
        BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, {}, FetchBaseQueryMeta>,
        'Order' | 'Item' | 'Auth' | 'Category' | 'Customer' | 'Recipe' | 'User',
        IItem,
        'baseAPI'
    >
>;

export function getStyles(numSelected?: number) {
    const { language } = useLanguage();
    const { isMobile, isTablet, rowsPerPageOptions, rowsPP, debounceTimeValue } = getMedia();

    // styles
    const formStyle = isMobile ? { flex: '1 0 50%' } : { flex: '1 0 calc(50% - 16px)' };
    const plr = {
        pl: '5px',
        pr: '5px',
    };
    const toolbarWrapper = {
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected &&
            numSelected > 0 && {
                bgcolor: (theme: { palette: { primary: { main: string }; action: { activatedOpacity: number } } }) =>
                    alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
            }),
    };
    const inputStylePadding = {
        '& label': plr,
        '& input': plr,
    };
    const inputStyle = {
        flex: '1 1 50%',
        mr: isTablet ? '10px' : '50px',
        padding: 0,
        maxHeight: '30px',
        minHeight: '30px',
        height: '30px',
        ...inputStylePadding,

        '& .MuiInputBase-root': { mt: 0 },
        '& .MuiFormLabel-root': { top: '-12px' },
    };
    const progressLoader = { position: 'absolute', top: '50%', left: '47%' };
    const opacityHalf = { opacity: '0.5', pointerEvents: 'none' };
    const opacityFull = { opacity: 1 };
    const labelStyle = { flex: '1 1 0%' };
    const titleStyle = { maxWidth: '215px' };
    const tableRowStyle = { backgroundColor: '#fcfdf5', height: '60px' };
    const tableCellStyle = isTablet ? { fontWeight: '600' } : { fontWeight: '600', fontSize: '1rem' };
    const wrapperStyle = isMobile
        ? {
              maxWidth: '280px',
          }
        : {};
    const btnWrapperStyle = isTablet
        ? {
              mb: '12px',
          }
        : {};
    const updateFormInput = {
        mr: '10px',
        '& label': plr,
        '& input': plr,
    };
    const categoryFormStyle = {
        position: 'absolute',
        top: isMobile || isTablet ? '13px' : '25px',
        right: '0px',
    };
    const formWrapper = {
        display: 'flex',
        justifyContent: 'space-between',
        ...wrapperStyle,
    };
    const buttonWrapper = {
        mb: '4px',
        ml: '20px',
        display: 'flex',
        alignItems: 'flex-end',
        ...btnWrapperStyle,
    };
    const funcButtonWrapper = { mt: '10px', mb: '0px' };
    const wrapperItems = { padding: '10px' };
    const boxItemsLoading = { width: '100%', paddingTop: '1rem' };
    const buttonItemsStyle = isTablet ? { lineHeight: '1.25rem' /* fontSize: '0.75rem' */ } : {};
    const tableWrapper = { width: '100%' };
    const tablePaperWrapper = { width: '100%', mb: '0px', boxShadow: '0px 0px 0px 0px rgba(0,0,0,0), 0px 0px 0px 0px rgba(0,0,0,0), 0px 0px 0px 0px rgba(0,0,0,0)' };
    const tableWidthStyle = isTablet ? {} : { minWidth: 750 };
    const tableRowWrapper = { cursor: 'pointer', height: '60px' };
    const tableModalsWrapper = { paddingBottom: 0, paddingTop: 0 };
    const tableBoxWrapper = { flexGrow: 1, mt: '10px', mb: '10px' };
    const tableBoxForms = {
        display: 'flex',
        maxWidth: isTablet ? '720px' : '1100px',
        flexWrap: 'wrap',
        gap: '16px',
        overflowX: 'auto',
        width: '100%',
    };
    const tablePaginationStyle = isTablet
        ? {
              '.MuiTablePagination-select': {
                  pl: '5px !important',
                  pr: '15px !important',
              },
              '.MuiTablePagination-input': {
                  pr: '5px !important',
                  pl: '5px !important',
                  ml: '5px !important',
                  mr: '5px !important',
              },
              '.MuiTablePagination-actions': {
                  pr: '0px !important',
                  pl: '0px !important',
                  ml: '0px !important',
                  mr: '0px !important',
              },
          }
        : {};

    // titles
    const titleUpdateForm = isMobile ? '' : getTitle(language, 'save');
    const titleDeleteForm = isMobile ? '' : getTitle(language, 'delete');
    const labelDeleteForm = isMobile ? getTitle(language, 'delete_item_info_start_mobile') : getTitle(language, 'delete_item_info_start');
    const titleNameForm = isMobile ? '' : getTitle(language, 'save');
    const titleCategoryForm = isMobile ? '' : getTitle(language, 'save');

    // methods
    function useGetHeaders() {
        const headCellsDefault: HeadCell[] = [
            {
                id: 'name',
                numeric: false,
                disablePadding: true,
                label: 'item_name',
                visible: true,
            },
            {
                id: 'categories',
                numeric: true,
                disablePadding: false,
                label: 'categories',
                visible: true,
            },
            {
                id: 'count',
                numeric: true,
                disablePadding: false,
                label: 'count',
                visible: true,
            },
            {
                id: 'lastPrice',
                numeric: true,
                disablePadding: false,
                label: 'price',
                visible: true,
            },
            {
                id: 'totalWeight',
                numeric: true,
                disablePadding: false,
                label: 'weight',
                visible: true,
            },
        ];

        return headCellsDefault;
    }

    function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }

    function getComparator(order: Order, orderBy: Key): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
        return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
    }

    function stableSort<T>(array: any[], comparator: (a: T, b: T) => number) {
        const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) {
                return order;
            }
            return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
    }

    return {
        toolbarWrapper,
        inputStyle,
        labelStyle,
        titleStyle,
        tableRowStyle,
        tableCellStyle,
        wrapperStyle,
        titleUpdateForm,
        titleDeleteForm,
        labelDeleteForm,
        titleNameForm,
        titleCategoryForm,
        inputStylePadding,
        formWrapper,
        updateFormInput,
        categoryFormStyle,
        buttonWrapper,
        funcButtonWrapper,
        wrapperItems,
        boxItemsLoading,
        buttonItemsStyle,
        isTablet,
        rowsPerPageOptions,
        rowsPP,
        debounceTimeValue,
        formStyle,
        tableWrapper,
        tablePaperWrapper,
        tableWidthStyle,
        tableRowWrapper,
        tableModalsWrapper,
        tableBoxWrapper,
        tableBoxForms,
        tablePaginationStyle,
        progressLoader,
        opacityHalf,
        opacityFull,
        useGetHeaders,
        getComparator,
        stableSort,
    };
}
