import { Box, TableCell } from '@mui/material';
import { FIELD_NAMES } from '../../../common/dictionary';
import { IRecipePart } from '../../../models/Recipe/IRecipePart';
import RecipePartItem from './RecipePartItem';
import getTitle from '../../../common/translations';
import { useLanguage } from '../../../context/LanguageContext';

interface RecipePartProps {
    recipePart: IRecipePart;
    index: number;
    openedRow: boolean;
    isLoading: boolean;
    size: number;
    isLinear?: boolean;
}

const RecipePart = ({ recipePart, index, openedRow, isLoading, size, isLinear }: RecipePartProps) => {
    const { language } = useLanguage();

    return (
        <TableCell
            sx={{
                pt: openedRow && !isLoading ? '0px' : '10px',
                pb: openedRow && !isLoading ? '0px' : '10px',
            }}
        >
            <Box
                sx={{
                    height: openedRow && !isLoading ? '55px' : '',
                    alignItems: 'center',
                    display: 'flex',
                    pt: 0,
                    pb: 0,
                }}
            >
                {getTitle(language, 'part')} #{index + 1}&nbsp;{recipePart.partName}
            </Box>
            {recipePart.items?.map((item, inx) => (
                <Box
                    key={item.id}
                    sx={{
                        ...(recipePart.items && recipePart.items.length > inx + 1 && { borderBottom: '0.1px solid rgba(0,0,0,0.1)' }),
                        height: openedRow && !isLoading ? '55px' : '',
                        alignItems: 'center',
                        display: 'flex',
                        pt: 0,
                        pb: 0,
                    }}
                >
                    <RecipePartItem item={item} size={size} isLinear={isLinear} />
                </Box>
            ))}
        </TableCell>
    );
};
export default RecipePart;
