import { useState } from 'react';
import { Box, IconButton, InputAdornment, Menu, TextField, Toolbar, Tooltip, Typography } from '@mui/material';
import SearchOffIcon from '@mui/icons-material/SearchOff';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import getTitle from '../../../common/translations';
import { useLanguage } from '../../../context/LanguageContext';
import { getStyles } from '../tableCommon';

interface EnhancedTableToolbarProps {
    numSelected: number;
    search: string;
    handleChangeSearch: (event: any) => void;
    // columns menu
    filterMenu: (JSX.Element | undefined)[];
    handleMenuOpen: (event: any) => void;
    anchorEl: null;
    handleMenuClose: () => void;
}

export function EnhancedTableToolbar({ numSelected, search, handleChangeSearch, filterMenu, handleMenuOpen, anchorEl, handleMenuClose }: EnhancedTableToolbarProps) {
    const { language } = useLanguage();
    const { toolbarWrapper, inputStyle, titleStyle, isTablet } = getStyles(numSelected);

    const [isFocus, setIsFocus] = useState(false);

    return (
        <Toolbar sx={toolbarWrapper}>
            <TextField
                autoFocus={search.length > 0 && isFocus}
                id={'searchInput'}
                label={getTitle(language, 'search')}
                variant="standard"
                value={search}
                onChange={handleChangeSearch}
                onBlur={() => setIsFocus(false)}
                onFocus={() => setIsFocus(true)}
                sx={{ ...inputStyle, '.MuiInputBase-input': { pr: '0px' } }}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            {search && (
                                <IconButton aria-label="clear search" onClick={(e) => handleChangeSearch(e)} edge="end" sx={{ mr: '0px', ml: '0px' }}>
                                    <SearchOffIcon />
                                </IconButton>
                            )}
                        </InputAdornment>
                    ),
                }}
            />

            {!isTablet ? (
                numSelected > 0 ? (
                    <Typography color="inherit" variant="subtitle1" component="div" sx={titleStyle}>
                        {numSelected} {getTitle(language, 'numSelected')}
                    </Typography>
                ) : null
            ) : null}

            {numSelected > 0 ? (
                <Tooltip title="Delete">
                    <IconButton>
                        <DeleteIcon />
                    </IconButton>
                </Tooltip>
            ) : (
                <Box>
                    <Tooltip title="Filter list">
                        <IconButton onClick={handleMenuOpen}>
                            <FilterListIcon />
                        </IconButton>
                    </Tooltip>
                    <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleMenuClose}
                        MenuListProps={{
                            'aria-labelledby': 'column-selector',
                        }}
                    >
                        {filterMenu}
                    </Menu>
                </Box>
            )}
        </Toolbar>
    );
}
