import { useEffect, useRef, useState } from 'react';
import { Autocomplete, Box, Button, FormControl, InputAdornment, InputLabel, MenuItem, Select, Stack, TextField } from '@mui/material';

import { RECIPE_SIZE } from '../../../common/dictionary';
import { getCommonStyles } from '../../../common/commonStyles';
import { IRecipe } from '../../../models/Recipe/IRecipe';
import { ISubOrder } from '../../../models/Order/ISubOrder';
import { useLanguage } from '../../../context/LanguageContext';
import getTitle from '../../../common/translations';
import { getRecipeSize } from '../../../common/helpers';

interface ICreateSubOrderProps {
    subOrder: ISubOrder;
    recipes: IRecipe[] | undefined;
    handleUpdateSubOrders: (subOrder: ISubOrder) => void;
    handleCorrectSubOrder: (correct: boolean, id: string | number) => void;
    deleteSubOrder: (id: string | number) => void;
}

const CreateSubOrder = ({ subOrder, recipes, handleUpdateSubOrders, handleCorrectSubOrder, deleteSubOrder }: ICreateSubOrderProps) => {
    const [newSubOrder, setNewSubOrder] = useState<ISubOrder>({ ...subOrder });
    const { language } = useLanguage();
    const { simpleBtnStyle } = getCommonStyles();
    const [newPrice, setNewPrice] = useState<number>(subOrder.price);
    const [orderCount, setOrderCount] = useState<number>(1);
    const [recipeCost, setRecipeCost] = useState(0);
    const [isValueSelected, setIsValueSelected] = useState(false);
    const [recipeSize, setRecipeSize] = useState<string[]>([]);
    const [selectedRecipe, setSelectedRecipe] = useState<IRecipe>();
    const inputCountRef = useRef<HTMLInputElement>(null);
    const inputPriceRef = useRef<HTMLInputElement>(null);

    const buttonStyle = { lineHeight: '1.25rem' /* fontSize: '0.75rem' */ };
    const autoCompleteLabelStyle = { '& .MuiInputLabel-root': { pointerEvents: 'none' } };
    let recipesNameList: string[] = [];
    recipes?.map((r) => recipesNameList.push(r.recipeName || ''));

    const handleRecipeName = (e: any, name: string | null) => {
        setIsValueSelected(!!name);
        if (name) {
            recipes?.forEach((r) => {
                const recipe = r.recipeName === name;

                if (recipe) {
                    setNewSubOrder((prevState) => {
                        return {
                            ...prevState,
                            recipeName: name,
                            recipeId: r.id,
                        };
                    });

                    setSelectedRecipe(r);
                    if (r.isLinear) {
                        setRecipeSize(getRecipeSize(r));
                    } else {
                        const sizes = r.proportionInfos.map((pi) => pi.proportion);
                        setRecipeSize(sizes);
                    }

                    return;
                }

                handleUpdateSubOrders({ ...newSubOrder, recipeName: name, recipeId: r.id });
            });
        }
    };

    const handleRecipeSize = (e: any, counter: any) => {
        if (counter) {
            let tempCost = selectedRecipe?.isLinear ? Number(selectedRecipe?.proportionInfos[0].cost) : 0;

            if (!selectedRecipe?.isLinear) {
                selectedRecipe?.proportionInfos.forEach((pi) => {
                    if (pi.proportion === counter.props.value) tempCost = Number(pi.cost);
                });
            }
            const cost = tempCost * Number(counter.props.value) * newSubOrder.count;
            setRecipeCost(tempCost);

            setNewSubOrder((prevState) => ({
                ...prevState,
                recipeCounter: counter.props.value,
                productCost: cost,
            }));

            handleUpdateSubOrders({ ...newSubOrder, recipeCounter: counter.props.value, productCost: cost });
        }
    };

    const handleOrderCount = (e: any) => {
        const qty = Number(e.target.value);

        if (qty >= 0) {
            const cost = recipeCost * newSubOrder.recipeCounter * qty;
            setOrderCount(qty);
            setNewSubOrder((prevState) => ({
                ...prevState,
                count: qty,
                productCost: cost,
            }));
            handleUpdateSubOrders({ ...newSubOrder, count: qty, productCost: cost });
        }
    };

    const handlePrice = (e: any) => {
        const price = Number(e.target.value);

        if (price >= 0) {
            setNewPrice(price);
            setNewSubOrder((prevState) => ({
                ...prevState,
                price,
            }));
            handleUpdateSubOrders({ ...newSubOrder, price });
        }
    };

    const size =
        newSubOrder.recipeCounter.toString().length === 1 && newSubOrder.recipeCounter !== 0
            ? `${newSubOrder.recipeCounter}.00`
            : newSubOrder.recipeCounter.toString().length === 3
            ? `${newSubOrder.recipeCounter}0`
            : newSubOrder.recipeCounter === 0
            ? ''
            : newSubOrder.recipeCounter.toString() || '1.00';

    const hasError = newSubOrder.recipeCounter === 0;

    useEffect(() => {
        const hasRecipeId = newSubOrder.recipeId !== 0;
        const hasCount = newSubOrder.count !== 0;
        const hasPrice = newSubOrder.price !== 0;
        const hasRecipeCounter = newSubOrder.recipeCounter !== 0;
        const correct = hasRecipeId && hasCount && hasPrice && hasRecipeCounter;
        handleCorrectSubOrder(correct, newSubOrder.id);
    }, [newSubOrder, handleCorrectSubOrder]);

    return (
        <Box sx={{ padding: '20px', backgroundColor: 'rgba(211, 211, 211, 0.5)', borderTop: '1px solid #999', borderBottom: '1px solid #999', mt: '10px', mb: '10px' }}>
            <Stack spacing={2} sx={{ minWidth: 100, maxWidth: 390 }}>
                <Autocomplete
                    disablePortal
                    id="recipes"
                    placeholder={getTitle(language, 'select_recipe')}
                    options={recipesNameList}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            required
                            label={getTitle(language, 'select_recipe')}
                            color={newSubOrder.recipeName === '' ? 'error' : 'success'}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderWidth: '2px',
                                        borderColor: isValueSelected ? 'green' : 'rgb(211, 47, 47)',
                                    },
                                    '&:hover fieldset': {
                                        borderWidth: '2px',
                                        borderColor: isValueSelected ? 'green' : 'rgb(211, 47, 47)',
                                    },
                                    '&.Mui-focused fieldset': {
                                        borderWidth: '2px',
                                        borderColor: isValueSelected ? 'green' : 'rgb(211, 47, 47)',
                                    },
                                },
                            }}
                        />
                    )}
                    onChange={handleRecipeName}
                    sx={{
                        ...autoCompleteLabelStyle,
                        '&.MuiAutocomplete-root .MuiInputLabel-root': {
                            color: isValueSelected ? 'green' : 'rgb(211, 47, 47)',
                        },
                    }}
                />

                <FormControl
                    variant="outlined"
                    size="medium"
                    required
                    error={hasError}
                    sx={{
                        '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                            borderWidth: '2px',
                            borderColor: hasError ? 'red' : 'green',
                        },
                    }}
                >
                    <InputLabel id="recipe_size_label">{getTitle(language, 'recipe_size_kg')}</InputLabel>
                    <Select
                        labelId="recipe_size_label"
                        id="create_recipe_select"
                        value={size}
                        onChange={handleRecipeSize}
                        label={getTitle(language, 'recipe_size_kg')}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderWidth: '2px',
                                    borderColor: isValueSelected ? 'green' : 'rgb(211, 47, 47)',
                                },
                                '&:hover fieldset': {
                                    borderWidth: '2px',
                                    borderColor: isValueSelected ? 'green' : 'rgb(211, 47, 47)',
                                },
                                '&.Mui-focused fieldset': {
                                    borderWidth: '2px',
                                    borderColor: isValueSelected ? 'green' : 'rgb(211, 47, 47)',
                                },
                            },
                        }}
                    >
                        {recipeSize.map((option) => (
                            <MenuItem key={option} value={option}>
                                {option}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <TextField
                    inputRef={inputCountRef}
                    required
                    id={'count'}
                    label={getTitle(language, 'count_orders')}
                    placeholder={getTitle(language, 'count_orders')}
                    value={orderCount}
                    focused
                    type="number"
                    onChange={handleOrderCount}
                    onFocus={() => inputCountRef.current && inputCountRef.current.select()}
                    color={orderCount === 0 ? 'error' : 'success'}
                    style={{ marginBottom: 10 }}
                    sx={autoCompleteLabelStyle}
                />

                <TextField
                    inputRef={inputPriceRef}
                    required
                    id={'price'}
                    label={getTitle(language, 'price_orders')}
                    placeholder={getTitle(language, 'price_orders')}
                    value={newPrice}
                    focused
                    type="number"
                    onChange={handlePrice}
                    onFocus={() => inputPriceRef.current && inputPriceRef.current.select()}
                    color={newSubOrder.price === 0 ? 'error' : 'success'}
                    style={{ marginBottom: 10 }}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">zł</InputAdornment>,
                    }}
                    sx={autoCompleteLabelStyle}
                />

                <Box>
                    {getTitle(language, 'order_price')} {Number(newPrice * newSubOrder.count).toFixed(2)} zł
                </Box>
                <Box>
                    {getTitle(language, 'order_cost')} {Number(newSubOrder.productCost).toFixed(2)} zł
                </Box>
                <Box>
                    {getTitle(language, 'order_profit')} {Number(newPrice * newSubOrder.count - newSubOrder.productCost).toFixed(2)} zł
                </Box>

                <Button variant="outlined" onClick={() => deleteSubOrder(subOrder.id)} sx={{ ...buttonStyle, ...simpleBtnStyle }}>
                    {getTitle(language, 'delete_suborder')}
                </Button>
            </Stack>
        </Box>
    );
};

export default CreateSubOrder;
