export const useStyles = () => {
    const loginWrapper = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: 'radial-gradient(closest-side, #fff, #dfffce, #f0ffd4, transparent)',
        borderRadius: '10px',
        overflow: 'hidden',
        margin: 'auto 0',
        height: '92vh',
    };

    const title = {
        mt: '20px',
        mb: '0px',
        height: '45px',
    };

    const header = {
        fontSize: '1.5em',
        color: '#477e43ff',
        textShadow: '0px 2px 2px rgb(255 255 255 / 80%)',
        fontWeight: '700',
        display: 'flex',
        justifyContent: 'center',
    };

    const errorWrapper = { width: '233px', color: '#990000', fontWeight: '500', position: 'absolute', top: '295px', left: '10px' };

    const errorStyle = { display: 'flex', justifyContent: 'center', position: 'relative' };

    return {
        title,
        header,
        errorWrapper,
        errorStyle,
        loginWrapper,
    };
};
