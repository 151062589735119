import { Box } from '@mui/material';
import { IRequiredItems } from '../../../models/Items/IRequiredItems';

interface Props {
    item: IRequiredItems;
}

const Items = ({ item }: Props) => {
    const backgroundColor: string = item.inStockCount < item.requiredCount ? '#ffa50050' : '#bababa15';
    return (
        <Box style={{ paddingTop: '5px', paddingBottom: '2px', paddingLeft: '10px', backgroundColor: backgroundColor, borderBottom: '1px solid rgba(222, 222, 222, 05)' }}>
            {item.name} - {item.requiredCount} {item.unitOfWeight} / {item.inStockCount} {item.unitOfWeight}
        </Box>
    );
};

export default Items;
