import { useEffect, useState } from 'react';
import {
    AlertColor,
    Box,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormControl,
    FormControlLabel,
    FormGroup,
    IconButton,
    InputAdornment,
    Radio,
    RadioGroup,
    Stack,
    TextField,
} from '@mui/material';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import SettingsIcon from '@mui/icons-material/Settings';
import SearchOffIcon from '@mui/icons-material/SearchOff';

import { Calendar, DateObject } from 'react-multi-date-picker';
import { getCalendarDate } from '../../common/helpers';
import { getCommonStyles } from '../../common/commonStyles';
import { getMedia } from '../../common/mediaQuery';
import { IGroupedOrdersByDay } from '../../models/Order/IGroupedOrdersByDay';
import { IOrderSearch } from '../../models/Order/IOrderSearch';
import { orderAPI } from '../../services/OrderServices';
import { useLanguage } from '../../context/LanguageContext';
import CreateOrder from './createOrder/CreateOrder';
import getTitle from '../../common/translations';
import MySnackBar from '../common/snackBars/MySnackBar';
import Orders from './common/Orders';
import useDebounce from '../../hooks/useDebounce';
import collapseTracker from '../../common/collapseTracker';

const OrdersHOC = () => {
    const { language } = useLanguage();
    const { isFullHD, isMobile, isTablet } = getMedia();
    const { mainBtnStyle, simpleBtnStyle } = getCommonStyles();

    const [createOrder, setCreateOrder] = useState<boolean>(false);
    const [editOrderMode, setEditOrderMode] = useState<boolean>(false);
    const [searchResult, setSearchResult] = useState<IGroupedOrdersByDay[] | undefined>();
    const [searchBar, setSearchBar] = useState<string>('');
    const [searchOptions, setSearchOptions] = useState<boolean>(false);
    const [isMultipleDate, setIsMultipleDate] = useState<boolean>(false);
    const [isDateRange, setIsDateRange] = useState<boolean>(true);
    const [closedOrders, setClosedOrders] = useState<boolean>(false);
    const [calendarDialogOpen, setCalendarDialogOpen] = useState<boolean>(false);
    const [dateRange, setDateRange] = useState<DateObject[]>([]);
    const [multipleDates, setMultipleDates] = useState<DateObject[]>([]);
    const [fetchedOrderSnackBar, setFetchedOrderSnackBar] = useState<boolean>(false);
    const [fetchedOrderErrorSnackBar, setFetchedOrderErrorSnackBar] = useState<boolean>(false);
    const [searchOrderSnackBar, setSearchOrderSnackBar] = useState<boolean>(false);
    const [searchOrderErrorSnackBar, setSearchOrderErrorSnackBar] = useState<boolean>(false);

    const buttonStyle = isTablet ? { lineHeight: '1.25rem' /* fontSize: '0.75rem' */ } : {};
    const funcButtonWrapper = { mt: '10px', mb: '0px' };

    const successColor: AlertColor = 'success';
    const errorColor: AlertColor = 'error';
    const { data: orders, isLoading: ordersIsLoading, isError: ordersIsError, isSuccess: ordersIsSuccess } = orderAPI.useGetAllOrdersQuery();
    const [orderSearch, { isError: orderSearchIsError, isLoading: orderSearchIsLoading, isSuccess: orderSearchIsSuccess }] = orderAPI.useOrderSearchMutation();
    const debouncedValue = useDebounce(searchBar, 1000);

    collapseTracker(orders);

    const search = () => {
        const searchDTO: IOrderSearch = { query: searchBar, closedOrders: closedOrders, isDateRange: isDateRange, isMultipleDate: isMultipleDate };
        orderSearch(searchDTO).then((res: any) => {
            if ('data' in res) {
                if (res.data) {
                    setSearchResult(res.data);
                } else {
                    setSearchResult([]);
                }
            }
        });
    };

    const setDialogOpen = () => {
        setCalendarDialogOpen(!calendarDialogOpen);
    };

    const searchRange = () => {
        if (dateRange.length === 2) {
            setSearchBar(`${getCalendarDate(dateRange[0].format())} ~ ${getCalendarDate(dateRange[1].format())}`);
            setCalendarDialogOpen(false);
        }
    };

    const searchMultipleDates = () => {
        const dates: string[] = [];
        multipleDates.forEach((el) => dates.push(`${getCalendarDate(el.format())}`));
        setSearchBar(dates.toString());
        setCalendarDialogOpen(false);
    };

    const setRange = (dateObjects: DateObject[]) => {
        if (dateObjects.length === 2) {
            setDateRange(dateObjects);
        }
    };
    const setMultiple = (dateObjects: DateObject[]) => {
        setMultipleDates([...dateObjects]);
    };

    const handleChangeSearch = (event: any) => {
        if (event.type === 'click') {
            setSearchBar('');
            setClosedOrders(false);
            setIsDateRange(false);
            setIsMultipleDate(false);
            setDateRange([]);
            setMultipleDates([]);
            setSearchOptions(false);
        } else {
            setSearchBar(event.target.value);
        }
    };

    const dateRangeCheckBox = (value: boolean) => {
        setIsDateRange(value);
        setIsMultipleDate(false);
        setMultipleDates([]);
        setSearchBar('');
    };

    const multipleDatesCheckBox = (value: boolean) => {
        setIsDateRange(false);
        setIsMultipleDate(value);
        setDateRange([]);
        setSearchBar('');
    };

    const handleEditMode = () => {
        setEditOrderMode(!editOrderMode);
    };

    const handleCreateOrder = () => {
        setCreateOrder(!createOrder);
    };

    const handleExistOrderId = (ids: string[]) => {
        setCreateOrder(false);
        setSearchBar(ids.toString());
    };

    useEffect(() => {
        if (searchBar) {
            search();
        } else {
            setSearchBar('');
            setSearchResult(undefined);
        }
    }, [debouncedValue, closedOrders]);

    useEffect(() => {
        if (!ordersIsLoading && !ordersIsError && ordersIsSuccess) setFetchedOrderSnackBar(true);
        if (ordersIsError) setFetchedOrderErrorSnackBar(true);
        if (orderSearchIsLoading && !orderSearchIsError) setSearchOrderSnackBar(true);
        if (orderSearchIsError) setSearchOrderErrorSnackBar(true);
    }, [orders, ordersIsLoading, ordersIsError, ordersIsSuccess, orderSearchIsError, orderSearchIsLoading]);

    return (
        <Box sx={{ padding: '10px', ...(isFullHD ? { minHeight: '900px' } : {}) }}>
            {!createOrder && !editOrderMode && (
                <Box sx={{ paddingLeft: '16px', paddingRight: '16px', paddingBottom: '10px' }}>
                    <Box sx={funcButtonWrapper}>
                        <Stack direction="row" divider={<Divider flexItem />} alignItems="center" justifyContent={'space-evenly'} gap={'5px'}>
                            <Button variant="outlined" onClick={handleCreateOrder} sx={{ ...buttonStyle, ...mainBtnStyle }}>
                                {`${getTitle(language, 'create_order')}`}
                            </Button>
                        </Stack>
                    </Box>

                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <TextField
                            id={'searchInput'}
                            label={getTitle(language, 'search')}
                            variant="standard"
                            value={searchBar}
                            fullWidth
                            onChange={handleChangeSearch}
                            sx={{ '.MuiInputBase-input': { pr: '0px' }, maxWidth: '80%', mr: '5px' }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {searchBar && (
                                            <IconButton aria-label="clear search" onClick={(e) => handleChangeSearch(e)} edge="end" sx={{ mr: '0px', ml: '0px' }}>
                                                <SearchOffIcon />
                                            </IconButton>
                                        )}
                                    </InputAdornment>
                                ),
                            }}
                            InputLabelProps={{
                                sx: {
                                    pl: '5px',
                                    pt: '5px',
                                },
                            }}
                        />

                        <Button
                            variant="outlined"
                            onClick={() => {
                                setSearchOptions(!searchOptions);
                            }}
                            sx={{
                                ...buttonStyle,
                                boxShadow: '0px 1px 3px #999',
                                fontWeight: '600',
                                backgroundColor: 'rgba(25, 118, 210, 0.08)',
                                pl: '10px',
                                pr: '10px',
                                minWidth: '20px',
                                ...(isMobile ? {} : { width: '210px' }),
                            }}
                        >
                            {isTablet ? '' : getTitle(language, 'search_options')}
                            &nbsp;
                            <Box component={'span'} sx={{ display: 'flex' }}>
                                <SettingsIcon />
                            </Box>
                        </Button>
                    </Box>
                    {searchOptions && (
                        <Box sx={{ marginTop: '5px', marginBottom: '5px' }}>
                            <FormGroup>
                                <Stack direction={isTablet ? 'column' : 'row'} spacing={1} alignSelf="center">
                                    <FormControlLabel
                                        control={<Checkbox checked={closedOrders} value={closedOrders} onClick={() => setClosedOrders(!closedOrders)} />}
                                        label={getTitle(language, 'closed_orders')}
                                    />
                                    <FormControl sx={{ paddingLeft: '11px' }}>
                                        <Stack direction="row" spacing={1} alignSelf="center">
                                            <RadioGroup row>
                                                <FormControlLabel
                                                    onChange={() => dateRangeCheckBox(!isDateRange)}
                                                    control={<Radio checked={isDateRange} value={isDateRange} />}
                                                    label={getTitle(language, 'range_search')}
                                                />
                                                <FormControlLabel
                                                    onChange={() => multipleDatesCheckBox(!isMultipleDate)}
                                                    control={<Radio checked={isMultipleDate} value={isMultipleDate} />}
                                                    label={getTitle(language, 'multiple_dates_search')}
                                                />
                                            </RadioGroup>
                                        </Stack>
                                    </FormControl>

                                    <Button
                                        size="small"
                                        variant="text"
                                        sx={{ fontWeight: 600, backgroundColor: '#33333310' }}
                                        onClick={setDialogOpen}
                                        endIcon={<CalendarMonthIcon />}
                                    >
                                        {getTitle(language, 'open_calendar')}
                                    </Button>
                                </Stack>
                            </FormGroup>
                        </Box>
                    )}
                </Box>
            )}
            {!createOrder && orders && !searchResult && <Orders orders={orders} handleEditModeInHOC={handleEditMode} />}
            {!createOrder && searchResult && <Orders orders={searchResult} handleEditModeInHOC={handleEditMode} />}
            {createOrder && !editOrderMode && <CreateOrder handleExistOrderId={handleExistOrderId} handleCreateOrderMode={handleCreateOrder} />}

            <Dialog open={calendarDialogOpen} onClose={setDialogOpen}>
                <DialogTitle alignSelf="center">
                    <Box component={'span'} sx={isTablet ? { fontSize: '1rem' } : {}}>
                        {isDateRange ? getTitle(language, 'select_date_range_for_the_search') : getTitle(language, 'select_dates_for_the_search')}
                    </Box>
                </DialogTitle>
                <DialogContent sx={{ alignSelf: 'center' }}>
                    {isDateRange ? (
                        <Calendar
                            value={dateRange}
                            onChange={(dateObjects) => {
                                setRange(dateObjects as DateObject[]);
                            }}
                            range
                            rangeHover
                        />
                    ) : (
                        <Calendar
                            value={multipleDates}
                            onChange={(dateObjects) => {
                                setMultiple(dateObjects as DateObject[]);
                            }}
                            multiple
                        />
                    )}
                </DialogContent>
                <DialogActions sx={{ alignSelf: 'center', mb: '10px' }}>
                    <Button variant="outlined" onClick={isDateRange ? searchRange : searchMultipleDates} sx={{ ...buttonStyle, ...simpleBtnStyle }}>
                        {getTitle(language, 'search')}
                    </Button>
                </DialogActions>
            </Dialog>
            <MySnackBar
                duration={5000}
                openSnackBar={fetchedOrderSnackBar}
                handleClose={() => setFetchedOrderSnackBar(false)}
                severity={successColor}
                alertText="All orders fetched!"
            />
            <MySnackBar
                duration={5000}
                openSnackBar={fetchedOrderErrorSnackBar}
                handleClose={() => setFetchedOrderErrorSnackBar(false)}
                severity={errorColor}
                alertText="Fetch orders error!"
            />
            <MySnackBar duration={5000} openSnackBar={searchOrderSnackBar} handleClose={() => setSearchOrderSnackBar(false)} severity={successColor} alertText="Search success!" />
            <MySnackBar
                duration={5000}
                openSnackBar={searchOrderErrorSnackBar}
                handleClose={() => setSearchOrderErrorSnackBar(false)}
                severity={errorColor}
                alertText="Search error!"
            />
        </Box>
    );
};

export default OrdersHOC;
