import { ADD_STOCK } from '../common/endpoints';
import { IStock } from '../models/IStock';
import { baseAPI } from './BaseAPI';

export const stockAPI = baseAPI.injectEndpoints({
    endpoints: (build) => ({
        addStock: build.mutation<IStock, IStock | undefined>({
            query: (stock: IStock) => ({
                url: ADD_STOCK,
                method: 'POST',
                body: stock,
            }),
            invalidatesTags: ['Item'],
        }),
    }),
});
