import { CSSProperties } from 'react';

interface vapourStyle extends CSSProperties {
    '--i'?: string;
}

export const useCustomSpan = () => {
    const vapourStyle1: vapourStyle = { '--i': '1' };
    const vapourStyle2: vapourStyle = { '--i': '2' };
    const vapourStyle3: vapourStyle = { '--i': '3' };
    const vapourStyle4: vapourStyle = { '--i': '4' };
    const vapourStyle5: vapourStyle = { '--i': '5' };
    const vapourStyle6: vapourStyle = { '--i': '6' };
    const vapourStyle7: vapourStyle = { '--i': '7' };
    const vapourStyle8: vapourStyle = { '--i': '8' };
    const vapourStyle9: vapourStyle = { '--i': '8' };
    const vapourStyle10: vapourStyle = { '--i': '10' };
    const vapourStyle11: vapourStyle = { '--i': '11' };
    const vapourStyle12: vapourStyle = { '--i': '12' };
    const vapourStyle13: vapourStyle = { '--i': '13' };
    const vapourStyle14: vapourStyle = { '--i': '14' };
    const vapourStyle15: vapourStyle = { '--i': '15' };
    const vapourStyle16: vapourStyle = { '--i': '16' };
    const vapourStyle17: vapourStyle = { '--i': '17' };
    const vapourStyle18: vapourStyle = { '--i': '18' };
    const vapourStyle19: vapourStyle = { '--i': '19' };
    const vapourStyle20: vapourStyle = { '--i': '20' };
    const vapourStyle21: vapourStyle = { '--i': '21' };

    return {
        vapourStyle1,
        vapourStyle2,
        vapourStyle3,
        vapourStyle4,
        vapourStyle5,
        vapourStyle6,
        vapourStyle7,
        vapourStyle8,
        vapourStyle9,
        vapourStyle10,
        vapourStyle11,
        vapourStyle12,
        vapourStyle13,
        vapourStyle14,
        vapourStyle15,
        vapourStyle16,
        vapourStyle17,
        vapourStyle18,
        vapourStyle19,
        vapourStyle20,
        vapourStyle21,
    };
};
