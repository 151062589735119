export const useStyles = ({ windowHeight, headerSizeInRem, fs }: { windowHeight: number; headerSizeInRem: number; fs: number }) => {
    const appWrapper = {
        margin: 0,
        padding: 0,
        backgroundColor: '#fcfdf5',
        minHeight: '100vh',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
    };

    const appWrapperContent = {
        backgroundColor: 'rgba(255, 255, 255, 1)',
        boxShadow: '10px 0 20px 1px rgba(0, 0, 0, 0.25)',
        minHeight: `${windowHeight - 129 - headerSizeInRem * fs}px`,
        marginBottom: '20px',
    };

    const navBarWrapper = {
        marginBottom: '16px',
    };

    const contentWrapper = {
        marginTop: `${headerSizeInRem}rem`,
    };

    return {
        appWrapper,
        appWrapperContent,
        navBarWrapper,
        contentWrapper,
    };
};
