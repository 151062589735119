import { useRef, useState } from 'react';
import { Box, TextField } from '@mui/material';
import { FuncButtonWIcon } from '../../../common/buttons/FunctionalButton';
import getTitle from '../../../../common/translations';
import { useLanguage } from '../../../../context/LanguageContext';
import { IStock } from '../../../../models/IStock';
import { DTO_STATE, IS_DELETED } from '../../../../common/dictionary';
import { addStock, getStyles } from '../../tableCommon';
import { IItem } from '../../../../models/Items/IItem';

export const UpdateForm = ({ item, addStock }: { item: IItem; addStock: addStock }) => {
    const { language } = useLanguage();
    const { titleUpdateForm, inputStylePadding, formWrapper, updateFormInput, buttonWrapper } = getStyles();

    const [newStock, setStock] = useState<IStock>({
        productionCompany: '',
        itemId: item.id || -1,
        count: 0,
        price: 0,
        weightOfPiece: item.weightOfPiece || 0,
        createdAt: item.createdAt,
        isDeleted: IS_DELETED.ACTIVE,
        state: DTO_STATE.DTO_NEW,
    });

    const inputCountRef = useRef<HTMLInputElement>(null);
    const inputPriceRef = useRef<HTMLInputElement>(null);
    const inputWOPRef = useRef<HTMLInputElement>(null);

    // methods
    const handleAddStock = () => {
        addStock(newStock);
    };

    const handleChange = (e: any) => {
        if (e.target.value >= 0) {
            setStock((prevState) => {
                return {
                    ...prevState,
                    [e.target.id]: Number(e.target.value),
                };
            });
        }
    };

    return (
        <Box sx={formWrapper}>
            <TextField
                inputRef={inputCountRef}
                margin="dense"
                id="count"
                type="number"
                label={getTitle(language, 'count')}
                placeholder={getTitle(language, 'count')}
                fullWidth
                variant="standard"
                value={newStock.count}
                onChange={handleChange}
                onFocus={() => inputCountRef.current && inputCountRef.current.select()}
                sx={updateFormInput}
            />
            <TextField
                inputRef={inputPriceRef}
                margin="dense"
                id="price"
                type="number"
                label={getTitle(language, 'price')}
                placeholder={getTitle(language, 'price')}
                fullWidth
                variant="standard"
                value={newStock.price}
                onChange={handleChange}
                onFocus={() => inputPriceRef.current && inputPriceRef.current.select()}
                sx={updateFormInput}
            />
            <TextField
                inputRef={inputWOPRef}
                margin="dense"
                id="weightOfPiece"
                label={getTitle(language, 'weight_of_piece')}
                placeholder={getTitle(language, 'weight_of_piece')}
                type="number"
                fullWidth
                variant="standard"
                value={newStock.weightOfPiece}
                onChange={handleChange}
                onFocus={() => inputWOPRef.current && inputWOPRef.current.select()}
                sx={inputStylePadding}
            />
            <Box sx={buttonWrapper}>
                <FuncButtonWIcon field={titleUpdateForm} handleClick={handleAddStock} iconName={'sendIcon'} />
            </Box>
        </Box>
    );
};
