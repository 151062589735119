import { useState } from 'react';
import { Button, Grid, Paper, Table, TableBody, TableCell, TableRow, TextField } from '@mui/material';
import AddToPhotosIcon from '@mui/icons-material/AddToPhotos';
import { IRecipe } from '../../../../models/Recipe/IRecipe';
import { DTO_STATE, IS_DELETED } from '../../../../common/dictionary';
import { useLanguage } from '../../../../context/LanguageContext';
import getTitle from '../../../../common/translations';
import { EditRecipePart } from './EditRecipePart';
import { IRecipePart } from '../../../../models/Recipe/IRecipePart';
import { getStyles } from '../../recipeCommon';
import { getAllItems, saveRecipePost } from '../../dataManager';
import { getMedia } from '../../../../common/mediaQuery';

interface Props {
    recipeData: IRecipe;
    handleClickEdit: (id: string | number) => Promise<void>;
    size: string;
}

export const EditRecipe = ({ recipeData, handleClickEdit, size }: Props) => {
    const { partsInputStyle } = getStyles();
    const { isTablet } = getMedia();
    const { language } = useLanguage();
    const { innerWidth: width } = window;

    // api
    const { items, getAllItemsError, getAllItemsIsLoading, getAllItemsIsSuccess } = getAllItems();
    const { saveRecipe, saveRecipeError, isSaveRecipeSuccess } = saveRecipePost();

    // data
    const [editedRecipe, setEditedRecipe] = useState({ ...recipeData });
    const isHasChanged = !compareObjects(recipeData, editedRecipe);
    const [bgcN, setBgcN] = useState('transparent');
    const [bgcD, setBgcD] = useState('transparent');
    const [isLoadingOnSave, setIsLoadingOnSave] = useState(false);

    // methods
    const changeRecipeName = (e: any) => {
        const newRecipeName = e.target.value;
        setEditedRecipe((prevState) => ({
            ...prevState,
            recipeName: newRecipeName,
            state: DTO_STATE.DTO_MODIFIED,
        }));

        if (recipeData?.recipeName !== newRecipeName) {
            setBgcN('rgba(233, 255, 77, 0.5)');
        } else {
            setBgcN('transparent');
        }
    };

    const changeRecipeDescription = (e: any) => {
        const newDescription = e.target.value;

        setEditedRecipe((prevState) => ({
            ...prevState,
            recipeDescription: newDescription,
            state: DTO_STATE.DTO_MODIFIED,
        }));

        if (recipeData?.recipeDescription !== newDescription) {
            setBgcD('rgba(233, 255, 77, 0.5)');
        } else {
            setBgcD('transparent');
        }
    };

    const handleEditRecipePart = (editedPart: IRecipePart | undefined) => {
        if (editedPart) {
            setEditedRecipe((prevState) => ({
                ...prevState,
                parts: prevState.parts ? prevState.parts.map((part) => (part.id === editedPart.id ? editedPart : part)) : [editedPart],
                state: DTO_STATE.DTO_MODIFIED,
            }));
        }
    };

    function compareObjects(obj1: any, obj2: any) {
        for (const key in obj1) {
            if (key === 'state') continue;
            if (typeof obj1[key] === 'object' && obj1[key] !== null) {
                if (!compareObjects(obj1[key], obj2[key])) {
                    return false;
                }
            } else if (obj1[key] !== obj2[key]) {
                return false;
            }
        }

        return true;
    }

    const addNewPart = () => {
        const newPart: IRecipePart = {
            id: crypto.randomUUID(),
            partName: '',
            partDescription: '',
            items: [],
            recipeId: editedRecipe.id || 0,
            createdAt: null,
            isDeleted: IS_DELETED.ACTIVE,
            state: DTO_STATE.DTO_NEW,
        };

        setEditedRecipe((prevState) => ({
            ...prevState,
            parts: [...(prevState.parts || []), newPart],
            state: DTO_STATE.DTO_MODIFIED,
        }));
    };

    const handleSaveRecipe = () => {
        editedRecipe.state = DTO_STATE.DTO_MODIFIED;
        setIsLoadingOnSave(true);

        // don't save new item which was deleted
        editedRecipe &&
            editedRecipe.parts &&
            editedRecipe.parts.forEach((part) => {
                part?.items?.filter((item) => !(item.state === DTO_STATE.DTO_DELETED && typeof item.id === 'string'));
            });

        saveRecipe(editedRecipe as IRecipe).then(() => {
            handleClickEdit(recipeData.id);
            setIsLoadingOnSave(false);
        });
    };

    function hasStateNew(objects: any) {
        for (let key in objects) {
            if (key === 'state' && (objects[key] === DTO_STATE.DTO_NEW || objects[key] === DTO_STATE.DTO_DELETED)) {
                return true;
            }
            if (typeof objects[key] === 'object' && objects[key] !== null) {
                if (hasStateNew(objects[key])) {
                    return true;
                }
            }
        }
        return false;
    }

    return (
        <>
            <Button
                sx={{ position: 'absolute', top: '-36px', ...(isTablet ? { left: `${width / 2.8}px`, fontSize: '0.8rem' } : { right: 0 }) }}
                onClick={handleSaveRecipe}
                disabled={hasStateNew(editedRecipe.parts) ? false : !isHasChanged}
            >
                {getTitle(language, 'save_recipe')}
            </Button>

            <Grid item xs={12} sx={{ mt: '5px', mb: '20px', position: 'relative' }}>
                <Paper sx={{ boxShadow: '0px 0px 10px -2px rgba(0, 0, 0, 0.2)' }}>
                    <Table sx={{ backgroundColor: '#fafaf8' }}>
                        <TableBody>
                            <TableRow
                                sx={{
                                    height: '55px',
                                }}
                            >
                                <TableCell
                                    sx={{
                                        padding: '0px',
                                        pl: '10px',
                                    }}
                                >
                                    <TextField
                                        size={'small'}
                                        label={getTitle(language, 'recipe_name')}
                                        required
                                        value={editedRecipe.recipeName}
                                        onChange={changeRecipeName}
                                        variant={'filled'}
                                        sx={{
                                            ...partsInputStyle,
                                            backgroundColor: bgcN,
                                            ...(isTablet
                                                ? {
                                                      '& .MuiFilledInput-input': { fontSize: '0.85rem !important' },
                                                      width: '170px',
                                                  }
                                                : {}),
                                        }}
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow
                                sx={{
                                    height: '55px',
                                }}
                            >
                                <TableCell
                                    sx={{
                                        padding: '0px',
                                        pl: '10px',
                                    }}
                                >
                                    <TextField
                                        size={'small'}
                                        label={getTitle(language, 'description')}
                                        value={editedRecipe.recipeDescription}
                                        onChange={changeRecipeDescription}
                                        variant={'filled'}
                                        sx={{
                                            ...partsInputStyle,
                                            backgroundColor: bgcD,
                                            ...(isTablet
                                                ? {
                                                      '& .MuiFilledInput-input': { fontSize: '0.85rem !important' },
                                                      width: '170px',
                                                  }
                                                : {}),
                                        }}
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow
                                sx={{
                                    position: 'absolute',
                                    backgroundColor: '#fafaf8',
                                    pl: '15px',
                                    pr: '15px',
                                    top: '37px',
                                    ...(isTablet ? { right: '5px' } : { right: '27px' }),
                                }}
                            >
                                <TableCell
                                    sx={{
                                        padding: '0px',
                                    }}
                                >
                                    <Button
                                        variant="outlined"
                                        onClick={addNewPart}
                                        sx={{
                                            bgcolor: '#fff',
                                            ...(isTablet
                                                ? {
                                                      fontSize: '0.8rem !important',
                                                  }
                                                : {}),
                                        }}
                                    >
                                        {isTablet ? <AddToPhotosIcon /> : getTitle(language, 'add_part')}
                                    </Button>
                                </TableCell>
                            </TableRow>
                            {editedRecipe.parts?.map((part) => {
                                return (
                                    <TableRow
                                        key={part.id}
                                        sx={{
                                            height: '55px',
                                            backgroundColor: part.state === DTO_STATE.DTO_NEW ? 'rgba(233, 255, 77, 0.3)' : 'transparent',
                                        }}
                                    >
                                        <TableCell
                                            sx={{
                                                padding: '0px',
                                                pl: '10px',
                                                pb: '0.5px',
                                            }}
                                        >
                                            <EditRecipePart key={part.id} recipePart={part} saveChanges={handleEditRecipePart} items={items} size={size} />
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </Paper>
            </Grid>
        </>
    );
};
