import { Box, CircularProgress, Grid, Paper, Table, TableBody, TableCell, TableRow, Tooltip } from '@mui/material';
import TimelineIcon from '@mui/icons-material/Timeline';
import LinearScaleIcon from '@mui/icons-material/LinearScale';
import { IRecipe } from '../../../models/Recipe/IRecipe';
import RecipePart from './RecipePart';
import { useLanguage } from '../../../context/LanguageContext';
import getTitle from '../../../common/translations';
import { getMedia } from '../../../common/mediaQuery';
import { getStyles } from '../recipeCommon';

interface RecipeProps {
    recipe: IRecipe;
    openedRow: boolean;
    isLoading: boolean;
    size: number;
}

const Recipe = ({ recipe, openedRow, isLoading, size }: RecipeProps) => {
    const { language } = useLanguage();
    const { opacityHalf, opacityFull } = getStyles();
    const { isTablet } = getMedia();
    const tooltip = recipe.isLinear ? getTitle(language, 'linear_recipe') : getTitle(language, 'nonlinear_recipe');

    return (
        <Box sx={{ flexBasis: isTablet ? '100%' : '39%', position: 'relative' }}>
            <Box sx={isLoading ? opacityHalf : opacityFull}>
                <Grid item xs={12} sx={{ mt: '5px', mb: '20px' }}>
                    <Paper sx={{ boxShadow: '0px 0px 10px -2px rgba(0,0,0,0.2)' }}>
                        <Table sx={{ backgroundColor: '#fafaf8' }}>
                            <TableBody>
                                <TableRow
                                    sx={{
                                        height: '55px',
                                    }}
                                >
                                    <TableCell
                                        sx={{
                                            pt: '10px',
                                            pb: '10px',
                                        }}
                                    >
                                        {getTitle(language, 'recipe')}: &nbsp;
                                        {recipe.recipeName}
                                    </TableCell>

                                    <Tooltip title={tooltip} placement="right">
                                        <TableCell
                                            sx={{
                                                padding: 0,
                                                pr: '10px',
                                                width: '25px',
                                            }}
                                        >
                                            {recipe.isLinear ? <LinearScaleIcon /> : <TimelineIcon />}
                                        </TableCell>
                                    </Tooltip>
                                </TableRow>
                                <TableRow
                                    sx={{
                                        height: '55px',
                                    }}
                                >
                                    <TableCell
                                        sx={{
                                            pt: '10px',
                                            pb: '10px',
                                        }}
                                    >
                                        {getTitle(language, 'description')}: &nbsp;
                                        {recipe.recipeDescription}
                                    </TableCell>
                                </TableRow>
                                {recipe.parts?.map((part, index: number) => (
                                    <TableRow
                                        key={part.id}
                                        sx={{
                                            height: '55px',
                                        }}
                                    >
                                        <RecipePart recipePart={part} index={index} openedRow={openedRow} isLoading={isLoading} size={size} isLinear={recipe.isLinear} />
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Paper>
                </Grid>
            </Box>
        </Box>
    );
};
export default Recipe;
