import { SetStateAction } from 'react';
import { Box, Checkbox, TableCell, TableHead, TableRow, TableSortLabel } from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import { useLanguage } from '../../../context/LanguageContext';
import getTitle from '../../../common/translations';
import { HeadCell, Order, getStyles } from '../recipeCommon';

interface EnhancedTableProps {
    numSelected: number;
    onRequestSort: (event: React.MouseEvent<unknown>, property: SetStateAction<string>) => void;
    onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
    order: Order;
    orderBy: string;
    rowCount: number;
    visibleColumns: HeadCell[];
}

export function EnhancedTableHead({ onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, visibleColumns }: EnhancedTableProps) {
    const { language } = useLanguage();
    const { tableRowStyle, tableCellStyle } = getStyles();

    const createSortHandler = (property: SetStateAction<string>) => (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead sx={tableRowStyle}>
            <TableRow>
                <TableCell padding="checkbox">
                    <Checkbox
                        color="primary"
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{
                            'aria-label': 'select all desserts',
                        }}
                    />
                </TableCell>
                {visibleColumns.map((headCell, index) => {
                    return (
                        <TableCell
                            key={headCell.id}
                            align={index === 0 ? 'left' : 'center'}
                            padding={headCell.disablePadding ? 'none' : 'normal'}
                            sortDirection={orderBy === headCell.id ? order : false}
                            sx={tableCellStyle}
                        >
                            {headCell.label === 'price' ? (
                                getTitle(language, headCell.label)
                            ) : (
                                <TableSortLabel active={orderBy === headCell.id} direction={orderBy === headCell.id ? order : 'asc'} onClick={createSortHandler(headCell.id)}>
                                    {getTitle(language, headCell.label)}
                                    {orderBy === headCell.id ? (
                                        <Box component="span" sx={visuallyHidden}>
                                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                        </Box>
                                    ) : null}
                                </TableSortLabel>
                            )}
                        </TableCell>
                    );
                })}
            </TableRow>
        </TableHead>
    );
}
