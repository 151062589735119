import { useEffect, useState } from 'react';
import { Box, Button, CircularProgress, TextField } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import { ICustomer } from '../../models/ICustomer';
import { DTO_STATE } from '../../common/dictionary';
import { customerAPI } from '../../services/CustomerServices';
import { getCommonStyles } from '../../common/commonStyles';
import getTitle from '../../common/translations';
import { useLanguage } from '../../context/LanguageContext';
import { getStyles } from './customerCommon';

interface CreateCustomerProps {
    handleCreateCustomerDialog: () => void;
}

const CreateCustomerNew = ({ handleCreateCustomerDialog }: CreateCustomerProps) => {
    const [isLoading, setIsLoading] = useState(false);

    const { progressLoader, opacityHalf } = getStyles();
    const { simpleBtnStyle } = getCommonStyles();
    const { language } = useLanguage();

    const [saveCustomer, { error: saveCustomerError, isSuccess: saveCustomerIsSuccess }] = customerAPI.useSaveCustomerMutation();
    const [newCustomer, setNewCustomer] = useState<Partial<ICustomer>>({
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        instagram: '',
        faceBook: '',
        state: DTO_STATE.DTO_NEW,
    });

    const handleCustomerFields = (e: any) => {
        setNewCustomer((prevState) => {
            return {
                ...prevState,
                [e.target.id]: e.target.value,
            };
        });
    };
    const handleCreateCustomer = () => {
        setIsLoading(true);
        saveCustomer(newCustomer);
    };

    useEffect(() => {
        if (saveCustomerIsSuccess) {
            handleCreateCustomerDialog();
            setIsLoading(false);
        }
    }, [saveCustomerIsSuccess]);

    return (
        <Box sx={{ backgroundColor: '#99999915', padding: '10px', mt: '20px', position: 'relative' }}>
            {isLoading ? (
                <Box sx={progressLoader}>
                    <CircularProgress color="success" />
                </Box>
            ) : (
                <></>
            )}

            <Box sx={isLoading ? opacityHalf : {}}>
                <TextField
                    autoFocus
                    margin="dense"
                    id="firstName"
                    label="First name"
                    type="text"
                    fullWidth
                    variant="standard"
                    value={newCustomer.firstName}
                    onChange={handleCustomerFields}
                />
                <TextField margin="dense" id="lastName" label="Last name" type="text" fullWidth variant="standard" value={newCustomer.lastName} onChange={handleCustomerFields} />
                <TextField margin="dense" id="email" label="Email" type="text" fullWidth variant="standard" value={newCustomer.email} onChange={handleCustomerFields} />
                <TextField
                    margin="dense"
                    id="phoneNumber"
                    label="Phone number"
                    type="text"
                    fullWidth
                    variant="standard"
                    value={newCustomer.phoneNumber}
                    onChange={handleCustomerFields}
                />
                <TextField margin="dense" id="instagram" label="Instagram" type="text" fullWidth variant="standard" value={newCustomer.instagram} onChange={handleCustomerFields} />
                <TextField margin="dense" id="faceBook" label="Facebook" type="text" fullWidth variant="standard" value={newCustomer.faceBook} onChange={handleCustomerFields} />

                <Box sx={{ mt: '20px', mb: '10px' }}>
                    <Button size="small" variant="outlined" onClick={handleCreateCustomerDialog} sx={{ ...simpleBtnStyle, mr: '10px' }}>
                        {getTitle(language, 'cancel')}
                    </Button>
                    <Button size="small" variant="outlined" onClick={handleCreateCustomer} sx={{ ...simpleBtnStyle, mr: '10px' }} endIcon={<SendIcon />}>
                        {getTitle(language, 'create')}
                    </Button>
                </Box>
            </Box>
        </Box>
    );
};

export default CreateCustomerNew;
