import { useState } from 'react';
import { Box, Button, Collapse } from '@mui/material';

import { getCommonStyles } from '../../../common/commonStyles';
import { IRecipe } from '../../../models/Recipe/IRecipe';
import { ISubOrder } from '../../../models/Order/ISubOrder';
import { useLanguage } from '../../../context/LanguageContext';
import EditSubOrder from '../editOrder/EditSubOrder';
import getTitle from '../../../common/translations';
import RecipeInSubOrder from './RecipeInSubOrder';

interface SubOrderProps {
    subOrder: ISubOrder;
    editMode?: boolean;
    recipes?: IRecipe[] | undefined;
    handleOrderPrice?: () => void;
    setNewValueInSubOrder?: (fieldName: string, subOrderId: number | string, value: number) => void;
    deleteSubOrder?: (subOrderId: number | string) => void;
}

const SubOrder = ({ subOrder, editMode, recipes, handleOrderPrice, setNewValueInSubOrder, deleteSubOrder }: SubOrderProps) => {
    const isNewSubOrder = subOrder.id.toString().includes('-');
    const { language } = useLanguage();
    const buttonStyle = { lineHeight: '1.25rem' /* fontSize: '0.75rem' */ };
    const { simpleBtnStyle } = getCommonStyles();
    const [editPartMode, setEditPartMode] = useState(isNewSubOrder);
    const [editedPart, setEditedPart] = useState<ISubOrder | undefined>(isNewSubOrder ? { ...subOrder } : undefined);
    const [showRecipe, setShowRecipe] = useState(false);
    const [color, setColor] = useState('white');

    const handleEditPartMode = () => {
        if (!editPartMode) {
            setEditedPart({ ...subOrder });
        } else {
            setEditedPart(undefined);
        }

        setEditPartMode(!editPartMode);
    };

    const handleShowRecipe = () => {
        setShowRecipe(!showRecipe);
    };

    const handleDelete = () => {
        deleteSubOrder && deleteSubOrder(editedPart!.id);
        setColor('rgba(255, 182, 193, 0.50)');
    };

    return (
        <Box sx={{ padding: '5px', backgroundColor: color, boxShadow: '0px 1px 3px #999' }}>
            <Box>
                {getTitle(language, 'recipe_title')}
                {subOrder.recipeName}
            </Box>
            <Box>
                {getTitle(language, 'description_orders')}
                {subOrder.description}
            </Box>
            <Box>
                {getTitle(language, 'price_orders')}
                {subOrder.price}zł
            </Box>
            <Box>
                {getTitle(language, 'order_cost')}
                {subOrder.productCost}zł
            </Box>
            <Box>
                {getTitle(language, 'count_orders')}:&nbsp;
                {subOrder.count}
            </Box>
            <Box>
                {getTitle(language, 'recipe_size_kg')}:&nbsp;{subOrder.recipeCounter}
            </Box>

            {!editMode && (
                <Button
                    variant="outlined"
                    onClick={handleShowRecipe}
                    sx={{
                        ...buttonStyle,
                        boxShadow: '0px 1px 3px #999',
                        fontWeight: '600',
                        backgroundColor: 'rgba(25, 118, 210, 0.08)',
                    }}
                >
                    {getTitle(language, 'recipe')}
                </Button>
            )}

            <Collapse in={showRecipe} timeout="auto" unmountOnExit>
                <RecipeInSubOrder key={subOrder.recipeId} recipeId={subOrder.recipeId as number} size={subOrder.recipeCounter} />
            </Collapse>

            {editMode ? (
                <Button variant="outlined" onClick={handleEditPartMode} sx={{ ...buttonStyle, ...simpleBtnStyle }}>
                    {getTitle(language, !editPartMode ? 'edit' : 'close')}
                </Button>
            ) : (
                <></>
            )}

            {editedPart && (
                <EditSubOrder
                    key={editedPart.id}
                    subOrder={editedPart}
                    recipes={recipes}
                    handleOrderPrice={handleOrderPrice}
                    setNewValueInSubOrder={setNewValueInSubOrder}
                    deleteSubOrder={handleDelete}
                />
            )}
        </Box>
    );
};

export default SubOrder;
