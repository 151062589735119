import { useState } from 'react';
import { Box, Button, ButtonGroup, TextField } from '@mui/material';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import UndoIcon from '@mui/icons-material/Undo';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { IRecipePart } from '../../../../models/Recipe/IRecipePart';
import { EditRecipePartItem } from './EditRecipePartItem';
import { DTO_STATE, IS_DELETED } from '../../../../common/dictionary';
import { IItem } from '../../../../models/Items/IItem';
import { IRecipePartItem } from '../../../../models/Recipe/IRecipePartItem';
import { getStyles } from '../../recipeCommon';
import getTitle from '../../../../common/translations';
import { useLanguage } from '../../../../context/LanguageContext';
import { getMedia } from '../../../../common/mediaQuery';

interface Props {
    recipePart: IRecipePart;
    saveChanges: (editedPart: IRecipePart | undefined) => void;
    items: IItem[] | undefined;
    size: string;
}

export const EditRecipePart = ({ recipePart, saveChanges, items, size }: Props) => {
    const { partsInputStyle } = getStyles();
    const { isTablet } = getMedia();

    const { language } = useLanguage();
    const backgroundColor = recipePart.state === DTO_STATE.DTO_DELETED ? '#FF7F7F' : '';
    const [editedPart] = useState<IRecipePart>({ ...recipePart });
    const [newName, setNewName] = useState(recipePart.partName);
    const [lastState, setLastState] = useState<string>(editedPart.state);
    const [bgc, setBgc] = useState('transparent');

    const changeRecipePart = (e: any) => {
        const localData = e.target.value;

        if (recipePart.partName !== localData) {
            setBgc('rgba(233, 255, 77,0.5)');
        }

        setNewName(localData);
        editedPart.partName = localData;

        if (editedPart.state !== DTO_STATE.DTO_NEW) {
            editedPart.state = DTO_STATE.DTO_MODIFIED;
        }
        saveChanges(editedPart);
    };

    const handleEditRecipePartItem = (editedItem: IRecipePartItem | undefined) => {
        if (!!editedItem) {
            if (!!editedPart.items) {
                let newItems: IRecipePartItem[] | undefined = [];
                editedPart.items.forEach((item) => {
                    if (item.id !== editedItem.id) {
                        newItems?.push(item);
                    } else {
                        if (typeof editedItem.id === 'string' && editedItem.state === DTO_STATE.DTO_DELETED) {
                            return;
                        }
                        newItems?.push(editedItem);
                    }
                });
                editedPart.items = newItems;
            }
        }
        if (editedPart.state !== DTO_STATE.DTO_NEW) {
            editedPart.state = DTO_STATE.DTO_MODIFIED;
        }

        saveChanges(editedPart);
    };

    const addNewItem = () => {
        const tempId = crypto.randomUUID();
        const newItem: IRecipePartItem = {
            id: tempId,
            itemId: -1,
            recipePartId: editedPart.id,
            itemName: '',
            unitOfWeight: '',
            isDeleted: IS_DELETED.ACTIVE,
            state: DTO_STATE.DTO_NEW,
            proportions: [
                {
                    id: crypto.randomUUID(),
                    proportion: Number(size),
                    quantity: 0,
                    recipePartItemId: tempId,
                    state: DTO_STATE.DTO_NEW,
                },
            ],
        };

        const newItems: IRecipePartItem[] | undefined = editedPart.items?.map((item) => item);
        newItems?.push(newItem);
        editedPart.items = newItems;

        saveChanges(editedPart);
    };

    const handleDeletePart = () => {
        if (editedPart.state === DTO_STATE.DTO_DELETED) {
            editedPart.state = lastState;
        } else {
            setLastState(editedPart.state);
            editedPart.state = DTO_STATE.DTO_DELETED;
        }
        console.log(editedPart);
        saveChanges(editedPart);
    };

    function findIndicesOfDuplicates(items: IRecipePartItem[] | undefined) {
        const nameMap: { [name: string]: number[] } = {};
        const duplicateIndices: number[] = [];

        // don't save new item which was deleted
        const index = (items && items.findIndex((item: IRecipePartItem) => item.state === DTO_STATE.DTO_DELETED && typeof item.id === 'string')) || -1;

        if (index !== -1) {
            items && items.splice(index, 1);
        }

        // Create a map of names to their indices
        items &&
            items.forEach((object: IRecipePartItem, index: number) => {
                const { itemName } = object;
                if (itemName in nameMap) {
                    nameMap[itemName].push(index);
                } else {
                    nameMap[itemName] = [index];
                }
            });

        // Find indices of names with more than one occurrence
        for (const name in nameMap) {
            if (nameMap[name].length > 1) {
                duplicateIndices.push(...nameMap[name]);
            }
        }

        return duplicateIndices;
    }

    return (
        <Box sx={{ backgroundColor: backgroundColor }}>
            <Box
                sx={{
                    display: 'flex',
                    height: '55px',
                }}
            >
                <TextField
                    size="small"
                    label={getTitle(language, 'part_name')}
                    value={newName}
                    onChange={changeRecipePart}
                    disabled={recipePart.state === DTO_STATE.DTO_DELETED}
                    variant={'filled'}
                    sx={{
                        ...partsInputStyle,
                        backgroundColor: bgc,
                        ...(isTablet
                            ? {
                                  '& .MuiFilledInput-input': { fontSize: '0.85rem !important' },
                                  width: '170px',
                              }
                            : {}),
                    }}
                />
                <ButtonGroup sx={{ ...(!isTablet && { width: '43%' }), height: '50%', justifyContent: 'space-between', alignSelf: 'center' }}>
                    <Button
                        sx={{
                            marginLeft: isTablet ? '10px' : '40px',
                            color: editedPart.state === DTO_STATE.DTO_DELETED ? 'rgba(30, 75, 30, 1)' : 'rgba(105, 22, 22, 1)',
                            border: '1px solid rgba(105, 22, 22, 0.3) !important',
                            backgroundColor: editedPart.state === DTO_STATE.DTO_DELETED ? 'rgba(0, 255, 0, 0.5)' : 'rgba(255, 0, 0, 0.5)',
                            borderRadius: '5px !important',
                            height: '35px',
                            ':hover': {
                                backgroundColor: '#fff',
                                border: '1px solid rgba(105, 22, 22, 1) !important',
                            },

                            ...(isTablet
                                ? {
                                      fontSize: '0.8rem !important',
                                      padding: '0px',
                                      mr: '10px',
                                  }
                                : {}),
                        }}
                        onClick={handleDeletePart}
                    >
                        {editedPart.state === DTO_STATE.DTO_DELETED ? (
                            isTablet ? (
                                <UndoIcon />
                            ) : (
                                getTitle(language, 'restore_item_part')
                            )
                        ) : isTablet ? (
                            <RemoveCircleOutlineIcon />
                        ) : (
                            getTitle(language, 'delete_item_part')
                        )}
                    </Button>

                    <Button
                        color="success"
                        onClick={addNewItem}
                        disabled={recipePart.state === DTO_STATE.DTO_DELETED}
                        sx={{
                            backgroundColor: 'rgba(46, 125, 50, 0.3)',
                            border: '1px solid rgba(30, 75, 30, 0.5) !important',
                            borderRadius: '5px !important',
                            height: '35px',
                            ':hover': {
                                backgroundColor: '#fff',
                                border: '1px solid rgba(30, 75, 30, 1) !important',
                            },

                            ...(isTablet
                                ? {
                                      fontSize: '0.8rem !important',
                                      padding: '0px',
                                  }
                                : {}),
                        }}
                    >
                        {isTablet ? <AddCircleOutlineIcon /> : getTitle(language, 'add_item_part')}
                    </Button>
                </ButtonGroup>
            </Box>
            <Box>
                {editedPart.items?.map((item, index) => {
                    const isDuplicateName = findIndicesOfDuplicates(recipePart.items).includes(index);

                    return (
                        <EditRecipePartItem
                            key={item.id}
                            item={item}
                            items={items}
                            isLast={recipePart?.items?.length !== index + 1}
                            saveChanges={handleEditRecipePartItem}
                            isPartDeleted={recipePart.state === DTO_STATE.DTO_DELETED}
                            isDuplicateName={isDuplicateName}
                        />
                    );
                })}
            </Box>
        </Box>
    );
};
