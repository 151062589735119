export const BASE_API_URL = process.env.REACT_APP_BASE_API_URL;

// Items API endpoints
export const GET_ALL_ITEMS = 'Items/GetAllItems';
export const GET_ITEMS = 'Items/GetItems';
export const GET_ITEMS_BY_CATEGORIES = 'Items/GetItemsByCategories';
export const GET_ITEM_BY_ID = 'Items/GetItem/';
export const CREATE_ITEM = 'Items/CreateItem';
export const DELETE_ITEM = 'Items/DeleteItem/';
export const ADD_ITEM_CATEGORY = 'Items/AddItemCategory';
export const RENAME_ITEM = 'Items/RenameItem';

// Stock API endpoints
export const ADD_STOCK = 'Stocks/AddStock';

// Category API endpoints
export const CREATE_CATEGORY = 'Categories/CreateCategory';
export const DELETE_CATEGORY = 'Categories/DeleteCategory/';
export const GET_ALL_CATEGORIES = 'Categories/GetAllCategories';
export const GET_CATEGORY_BY_ID = 'Categories/GetCategoryById/';
export const RENAME_CATEGORY = 'Categories/RenameCategory';

// Recipe API endpoints
export const GET_ALL_RECIPES = 'Recipes/GetAllRecipes';
export const CREATE_RECIPE = 'Recipes/CreateRecipe';
export const SAVE_RECIPE = 'Recipes/SaveRecipe';
export const GET_RECIPE = 'Recipes/GetRecipeById';
export const GET_RECIPES = 'Recipes/GetRecipes';
export const DELETE_RECIPES = 'Recipes/DeleteRecipes';
export const GET_RECIPE_WITH_SELECTED_PROPORTION = 'Recipes/GetRecipeWithSelectedProportion';

// Order API endpoints
export const SAVE_ORDER = 'Orders/SaveOrder';
export const GET_ORDER_BY_ID = 'Orders/GetOrderById';
export const GET_ORDER_BY_NAME = 'Orders/GetOrderByName/';
export const GET_ORDERS_BY_CUSTOMER = 'Orders/GetOrdersByCustomer';
export const GET_ALL_ORDERS = 'Orders/GetAllOrders';
export const GET_ORDERS_SEVERAL_DAY_AHEAD = 'Orders/GetOrdersSeveralDaysAhead';
export const CHANGE_ORDER_STAGE = 'Orders/ChangeOrderStage/';
export const CLOSE_ORDER = 'Orders/CloseOrder';
export const GET_REQUIRED_ITEMS_BY_ORDER_IDS = 'Orders/GetRequiredItemsByOrderIds';
export const DELETE_ORDER = 'Orders/DeleteOrder';
export const ORDER_SEARCH = 'Orders/OrderSearch';

// Auth API endpoints
export const LOG_IN = 'Auth/SignIn';
export const LOG_OUT = 'Auth/SignOut';

// User API endpoints
export const GET_USER_DATA = 'Users/GetUserData';
export const SAVE_USER = 'Users/SaveUser';

// Customer API endpoints
export const GET_ALL_CUSTOMERS = 'Customers/GetAllCustomers';
export const GET_CUSTOMER_BY_ID = 'Customers/CustomerById';
export const GET_CUSTOMER_BY_NAME = 'Customers/CustomerByName';
export const SEARCH_CUSTOMERS = 'Customers/SearchCustomers';
export const SAVE_CUSTOMER = 'Customers/SaveCustomer';
export const GET_CUSTOMERS = 'Customers/GetCustomers';
export const UPDATE_CUSTOMER = 'Customers/UpdateCustomer';
export const DELETE_CUSTOMER = 'Customers/DeleteCustomer';
