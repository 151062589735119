import { useId, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MenuIcon from '@mui/icons-material/Menu';
import { useLanguage } from '../../context/LanguageContext';
import getTitle from '../../common/translations';

type Props = {
    logout: () => void;
};

export default function LongMenu({ logout }: Props) {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const open = Boolean(anchorEl);
    const { language } = useLanguage();

    const history = useNavigate();
    const handleLocationChange = (newValue: string) => {
        history(newValue);
    };

    // menu options
    const options = [
        { id: useId(), label: '/', value: <Box>{getTitle(language, 'home')}</Box> },
        { id: useId(), label: '/orders', value: <Box>{getTitle(language, 'orders_navbar')}</Box> },
        { id: useId(), label: '/items', value: <Box>{getTitle(language, 'items')}</Box> },
        { id: useId(), label: '/recipes', value: <Box>{getTitle(language, 'recipes')}</Box> },
        { id: useId(), label: '/customers', value: <Box>{getTitle(language, 'customers_navbar')}</Box> },
        { id: useId(), label: '/finances', value: <Box>{getTitle(language, 'finances')}</Box> },

        { id: useId(), label: '/logout', value: <Box>{getTitle(language, 'logout')}</Box> },
    ];

    // methods
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleMenuItem = (path: string) => {
        switch (path) {
            case '/':
                handleLocationChange('/');
                break;
            case '/orders':
                handleLocationChange('/orders');
                break;
            case '/items':
                handleLocationChange('/items');
                break;
            case '/recipes':
                handleLocationChange('/recipes');
                break;
            case '/customers':
                handleLocationChange('/customers');
                break;
            case '/finances':
                handleLocationChange('/finances');
                break;
            case '/logout':
                logout();
                break;

            default:
                break;
        }

        handleClose();
    };

    // styles
    const menuWrapper = { display: 'flex', marginLeft: '10px', marginTop: '5px' };
    const iconStyle = { padding: '0px 5px' };
    const menuListProps = {
        'aria-labelledby': 'long-button',
    };
    const PaperPropsStyle = {
        style: {
            width: '20ch',
            borderRadius: '12px',
            boxShadow: '20px 20px 50px rgba(0, 0, 0, 0.5)',
            backdropFilter: 'blur(10px)',
            background: 'rgba(255, 255, 255, 0.2)',
        },
    };

    return (
        <Box style={menuWrapper}>
            <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
                style={iconStyle}
            >
                <MenuIcon />
            </IconButton>
            <Menu
                id="long-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                MenuListProps={menuListProps}
                PaperProps={PaperPropsStyle}
            >
                {options.map((option) => (
                    <MenuItem
                        key={option.id}
                        onClick={() => handleMenuItem(option.label)}
                        sx={{
                            '&:hover': {
                                backgroundColor: 'rgba(25, 118, 210, 0.5)',
                            },
                        }}
                    >
                        {option.value}
                    </MenuItem>
                ))}
            </Menu>
        </Box>
    );
}
