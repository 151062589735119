import { useState } from 'react';
import { Box, Button, TextField } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import { ICustomer } from '../../models/ICustomer';
import { DTO_STATE } from '../../common/dictionary';
import { customerAPI } from '../../services/CustomerServices';
import { getCommonStyles } from '../../common/commonStyles';
import getTitle from '../../common/translations';
import { useLanguage } from '../../context/LanguageContext';

interface CreateCustomerProps {
    handleCreateCustomerDialog: () => void;
    search: (e: any) => void;
}

const CreateCustomer = ({ handleCreateCustomerDialog, search }: CreateCustomerProps) => {
    const { simpleBtnStyle } = getCommonStyles();
    const { language } = useLanguage();

    const [saveCustomer, { error: saveCustomerError, isSuccess: saveCustomerIsSuccess }] = customerAPI.useSaveCustomerMutation();
    const [newCustomer, setNewCustomer] = useState<Partial<ICustomer>>({
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        instagram: '',
        faceBook: '',
        state: DTO_STATE.DTO_NEW,
    });

    const handleCustomerFields = (e: any) => {
        setNewCustomer((prevState) => {
            return {
                ...prevState,
                [e.target.id]: e.target.value,
            };
        });
    };
    if (saveCustomerIsSuccess) {
        handleCreateCustomerDialog();
        search(null);
    }
    const handleCreateCustomer = () => {
        saveCustomer(newCustomer);
    };

    return (
        <Box sx={{ backgroundColor: '#99999925', padding: '10px' }}>
            <TextField
                autoFocus
                margin="dense"
                id="firstName"
                label="First name"
                type="text"
                fullWidth
                variant="standard"
                value={newCustomer.firstName}
                onChange={handleCustomerFields}
            />
            <TextField
                autoFocus
                margin="dense"
                id="lastName"
                label="Last name"
                type="text"
                fullWidth
                variant="standard"
                value={newCustomer.lastName}
                onChange={handleCustomerFields}
            />
            <TextField autoFocus margin="dense" id="email" label="Email" type="text" fullWidth variant="standard" value={newCustomer.email} onChange={handleCustomerFields} />
            <TextField
                autoFocus
                margin="dense"
                id="phoneNumber"
                label="Phone number"
                type="text"
                fullWidth
                variant="standard"
                value={newCustomer.phoneNumber}
                onChange={handleCustomerFields}
            />
            <TextField
                autoFocus
                margin="dense"
                id="instagram"
                label="Instagram"
                type="text"
                fullWidth
                variant="standard"
                value={newCustomer.instagram}
                onChange={handleCustomerFields}
            />
            <TextField
                autoFocus
                margin="dense"
                id="faceBook"
                label="Facebook"
                type="text"
                fullWidth
                variant="standard"
                value={newCustomer.faceBook}
                onChange={handleCustomerFields}
            />
            <Box sx={{ mt: '20px', mb: '10px' }}>
                <Button size="small" variant="outlined" onClick={handleCreateCustomerDialog} sx={{ ...simpleBtnStyle, mr: '10px' }}>
                    {getTitle(language, 'cancel')}
                </Button>
                <Button size="small" variant="outlined" onClick={handleCreateCustomer} sx={{ ...simpleBtnStyle, mr: '10px' }} endIcon={<SendIcon />}>
                    {getTitle(language, 'create')}
                </Button>
            </Box>
        </Box>
    );
};

export default CreateCustomer;
