import { IUser } from '../../models/IUser';

export const useStyles = (user: IUser | undefined) => {
    const menuWrapper = !!user ? {} : { pointerEvents: 'none', opacity: 0.7 };

    const iconStyle = {
        padding: 0,
        pl: '3px',
        pr: '3px',
        fontSize: '1.3rem',
        mt: '4px',
        width: '34px',
    };

    const bar = {
        backdropFilter: 'blur(5px)',
        backgroundColor: 'rgba(255, 255, 255, 0.5)',
    };

    const headerWrapper = {
        display: 'flex',
        justifyContent: 'space-between',
        height: '3rem',
        alignItems: 'center',
    };

    const leftSideWrapper = {
        paddingLeft: '5px',
        color: 'rgba(0, 0, 0, 0.54)',
        cursor: 'pointer',
    };

    const rightSideWrapper = {
        display: 'flex',
        color: 'rgba(0, 0, 0, 0.54)',
        alignItems: 'center',
    };

    const nameStyle = {
        fontWeight: 'bold',
    };

    return {
        menuWrapper,
        iconStyle,
        bar,
        headerWrapper,
        leftSideWrapper,
        rightSideWrapper,
        nameStyle,
    };
};
