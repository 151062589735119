import { SetStateAction, useEffect, useState } from 'react';
import { Box, Button, TextField } from '@mui/material';
import { IRecipePart } from '../../../../models/Recipe/IRecipePart';
import CreateRecipeItems from './CreateRecipeItems';
import { IItem } from '../../../../models/Items/IItem';
import { IRecipePartItem } from '../../../../models/Recipe/IRecipePartItem';
import { DTO_STATE, FIELD_NAMES, IS_DELETED, UNIT_OF_WEIGHT } from '../../../../common/dictionary';
import { useLanguage } from '../../../../context/LanguageContext';
import getTitle from '../../../../common/translations';
import { getStyles } from '../../recipeCommon';
import { getCommonStyles } from '../../../../common/commonStyles';
import { getMedia } from '../../../../common/mediaQuery';

interface CreateRecipePartProps {
    part: IRecipePart;
    reRender: () => void;
    items: IItem[] | undefined;
    handleDeletePart: (partId: number | string) => void;
    setIsPartNameEmpty: (data: boolean) => void;
    setIsNewItemNameEmpty: (data: boolean) => void;
    setIsNewItemCountEmpty: (data: boolean) => void;
    proportions: any; // TODO: any
    partsNames: any; // TODO: any
    setPartsNames: any; // TODO: any
}

const CreateRecipePart = ({
    part,
    reRender,
    items,
    handleDeletePart,
    setIsPartNameEmpty,
    setIsNewItemNameEmpty,
    setIsNewItemCountEmpty,
    proportions,
    partsNames,
    setPartsNames,
}: CreateRecipePartProps) => {
    const { language } = useLanguage();
    const { autoCompleteLabelStyle } = getStyles();
    const { isMobile } = getMedia();
    const { simpleBtnStyle } = getCommonStyles();

    const [newItems, setRecipePartItems] = useState<IRecipePartItem[]>([
        {
            id: crypto.randomUUID(),
            itemId: -1,
            recipePartId: part.id,
            itemName: '',
            unitOfWeight: UNIT_OF_WEIGHT.G,
            isDeleted: IS_DELETED.ACTIVE,
            state: DTO_STATE.DTO_NEW,
            proportions: [],
        },
    ]);
    const [partName, setPartName] = useState<any>('');

    part.items = [...newItems];

    const addItem = () => {
        setRecipePartItems((prevState) => {
            return [
                ...prevState,
                {
                    id: crypto.randomUUID(),
                    itemId: -1,
                    recipePartId: part.id,
                    itemName: '',
                    count: 0,
                    unitOfWeight: UNIT_OF_WEIGHT.G,
                    isDeleted: IS_DELETED.ACTIVE,
                    state: DTO_STATE.DTO_NEW,
                    proportions: [],
                },
            ];
        });
    };

    const setNewItemValues = (recipeItem: IRecipePartItem) => {
        const itemIndex = newItems.findIndex((item) => item.id === recipeItem.id);
        newItems[itemIndex] = recipeItem;
    };

    const insertItem = (arr: any, newItem: any) => {
        const index = arr.findIndex((item: any) => item.id === newItem.id);

        if (index !== -1) {
            arr[index] = newItem;
        } else {
            arr.push(newItem);
        }

        return arr;
    };

    const handlePartNameAndDescription = (e: { target: { value: SetStateAction<string> } }) => {
        setPartName(e.target.value);

        setPartsNames((prev: any) => {
            const updatedParts = insertItem([...prev], { id: part.id, name: e.target.value });
            return updatedParts;
        });
    };

    const handleDeleteItem = (itemId: number | string) => {
        const index = newItems.findIndex((item) => item.id === itemId);
        newItems.splice(index, 1);
        reRender();
    };

    useEffect(() => {
        setIsPartNameEmpty(partName === '');
    }, [partName]);

    return (
        <Box
            sx={{
                margin: '10px',
                padding: '10px',
                backgroundColor: '#fcfdf5',
                borderTop: '2px solid rgba(0, 0, 0, 0.1)',
                borderBottom: '2px solid rgba(0, 0, 0, 0.1)',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    ...(isMobile ? { width: '280px' } : {}),
                }}
            >
                <TextField
                    focused
                    required
                    id={FIELD_NAMES.NAME}
                    label={getTitle(language, 'part_name')}
                    placeholder={getTitle(language, 'part_name')}
                    value={partName}
                    onChange={handlePartNameAndDescription}
                    color={partName === '' ? 'error' : 'success'}
                    sx={{ ...autoCompleteLabelStyle, width: '280px', mr: '30px' }}
                />

                <Button
                    variant="outlined"
                    onClick={() => handleDeletePart(part.id)}
                    sx={{
                        ...simpleBtnStyle,
                        backgroundColor: 'rgba(255, 219, 187, 0.25)',
                        fontWeight: '400',
                        margin: 0,
                        alignSelf: 'center',
                        ...(isMobile
                            ? {
                                  pt: '0px',
                                  pb: '0px',
                                  pr: '5px',
                                  pl: '5px',
                              }
                            : {}),
                    }}
                >
                    {getTitle(language, 'delete_part')}
                </Button>
            </Box>

            {/* items for the part */}
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                {part.items?.map((item) => (
                    <CreateRecipeItems
                        key={item.id}
                        itemId={item.id}
                        items={items}
                        setNewItemValues={setNewItemValues}
                        partId={part.id}
                        handleDeleteItem={handleDeleteItem}
                        setIsNewItemNameEmpty={setIsNewItemNameEmpty}
                        setIsNewItemCountEmpty={setIsNewItemCountEmpty}
                        proportions={proportions}
                    />
                ))}
            </Box>

            <Box sx={{ pt: '10px' }}>
                <Button
                    variant="outlined"
                    onClick={addItem}
                    sx={{
                        ...simpleBtnStyle,
                        backgroundColor: '',
                        fontWeight: '400',
                        margin: 0,
                        padding: '5px 0',
                        width: '253px',
                    }}
                >
                    {getTitle(language, 'add_new_item')}
                </Button>
            </Box>
        </Box>
    );
};

export default CreateRecipePart;
