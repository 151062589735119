import { LOG_IN, LOG_OUT } from '../common/endpoints';
import { IUser } from '../models/IUser';
import { baseAPI } from './BaseAPI';

export const authAPI = baseAPI.injectEndpoints({
    endpoints: (build) => ({
        logIn: build.mutation<IUser, string[]>({
            query: (data: string[]) => ({
                url: LOG_IN,
                method: 'POST',
                body: {
                    email: data[0],
                    password: data[1],
                },
            }),
        }),

        logOut: build.mutation<void, void>({
            query: () => ({
                url: LOG_OUT,
                method: 'POST',
            }),

            invalidatesTags: ['User'],
        }),
    }),
});
