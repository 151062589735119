import { useState, useEffect } from 'react';
import { IGroupedOrdersByDay } from '../../../models/Order/IGroupedOrdersByDay';
import { orderAPI } from '../../../services/OrderServices';
import { ORDER_STAGES } from '../../../common/dictionary';
import { IRequiredItems } from '../../../models/Items/IRequiredItems';
import Items from './Items';
import getTitle from '../../../common/translations';
import { useLanguage } from '../../../context/LanguageContext';
import { Box, Collapse, IconButton, TableCell, TableRow } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/ExpandMore';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { getStyles } from '../../recipes/recipeCommon';
import MySnackBar from '../../common/snackBars/MySnackBar';

interface Props {
    orders: IGroupedOrdersByDay[] | undefined;
    setFetchedRequiredItemsSnackBar: React.Dispatch<React.SetStateAction<boolean>>;
}

const RequiredItems = ({ orders, setFetchedRequiredItemsSnackBar }: Props) => {
    const { language } = useLanguage();
    const [expand, setExpand] = useState<boolean>(false);
    const { tableRowWrapper, tableRowStyle } = getStyles();
    let orderIds: number[] = [];
    let items: IRequiredItems[] | undefined = undefined;
    let lowCount: boolean = false;

    if (orders) {
        orders.forEach((element) => {
            element.orders?.forEach((order) => {
                if (order.stage === ORDER_STAGES.DONE) return;
                orderIds.push(order.id as number);
            });
        });

        const { data: requiredItems, isLoading, isError, isSuccess } = orderAPI.useGetRequiredItemsByOrderIdsQuery(orderIds);
        if (requiredItems) {
            items = requiredItems;
            requiredItems.forEach((item) => {
                lowCount = item.inStockCount < item.requiredCount;
            });
        }
    }

    useEffect(() => {
        if (items) setFetchedRequiredItemsSnackBar(true);
    }, [items]);

    return (
        <>
            <TableRow sx={{ ...tableRowWrapper, ...tableRowStyle, backgroundColor: 'rgb(235, 255, 150)' }} onClick={() => setExpand(!expand)}>
                <TableCell>
                    <IconButton aria-label="expand row" size="small">
                        {expand ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>

                    {getTitle(language, 'required_ingredients')}
                    {lowCount && (
                        <Box component={'span'} style={{ color: 'red', letterSpacing: 0 }}>
                            ***
                        </Box>
                    )}
                </TableCell>
            </TableRow>
            <TableRow>
                <Collapse in={expand} timeout="auto" unmountOnExit>
                    {items && items.map((item) => <Items key={item.itemId} item={item} />)}
                </Collapse>
            </TableRow>
        </>
    );
};

export default RequiredItems;
