import { useState } from 'react';
import { Box, TextField } from '@mui/material';
import { FuncButtonWIcon } from '../../../common/buttons/FunctionalButton';
import getTitle from '../../../../common/translations';
import { useLanguage } from '../../../../context/LanguageContext';
import { getStyles } from '../../tableCommon';

export const NameForm = ({ item, renameItem }: { item: any; renameItem: (data: [itemId: number, newName: string]) => void }) => {
    const { language } = useLanguage();
    const { titleNameForm, formWrapper, buttonWrapper, inputStylePadding } = getStyles();

    const [itemNewName, setRenameItem] = useState({
        item: item,
        newName: item.name,
    });

    // methods
    const handleNewName = (e: React.ChangeEvent<HTMLInputElement>) => {
        setRenameItem((prevState) => {
            return {
                ...prevState,
                newName: e.target.value,
            };
        });
    };

    const handleUpdate = () => {
        const data: [number, string] = [itemNewName.item.id, itemNewName.newName];
        renameItem(data);
    };

    return (
        <Box sx={formWrapper}>
            <TextField
                label={getTitle(language, 'rename_item_dialog_cont')}
                margin="dense"
                fullWidth
                variant="standard"
                value={itemNewName.newName}
                onChange={handleNewName}
                sx={inputStylePadding}
            />
            <Box sx={buttonWrapper}>
                <FuncButtonWIcon field={titleNameForm} handleClick={handleUpdate} iconName={'sendIcon'} />
            </Box>
        </Box>
    );
};
