import { Fragment, SetStateAction, useEffect, useId, useState } from 'react';

import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { CircularProgress, Collapse, MenuItem } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import { EnhancedTableToolbar } from './EnhancedTableToolbar';
import { EnhancedTableHead } from './EnhancedTableHead';
import { ICategory } from '../../../models/ICategory';
import { NameForm } from './CollapseContent/NameForm';
import { DeleteForm } from './CollapseContent/DeleteForm';
import { UpdateForm } from './CollapseContent/UpdateForm';
import { CategoryForm } from './CollapseContent/CategoryForm';
import { useLanguage } from '../../../context/LanguageContext';
import getTitle from '../../../common/translations';
import { getStyles, HeadCell, Order, addItemCategory, addStock, renameItem } from '../tableCommon';
import priceFormatter from '../../../common/priceFormatter';
import { CLEAR_BUTTON } from '../../../common/dictionary';
import { IItem } from '../../../models/Items/IItem';

export function EnhancedCollapsibleTable({
    items,
    categories,
    categoriesAvailable,
    renameItem,
    addStock,
    addItemCategory,
    remove,
    itemsQtyTotal,
    itemsPerPage,
    currentPageNumber,
    setItemsPerPage,
    setCurrentPageNumber,
    isNewDataFetching,
    setIsNewDataFetching,
    setSearchPhrase,
    setSearchInput,
    searchInput,
    orderBy,
    setOrderBy,
    order,
    setOrder,
    setCatList,
    catList,
}: {
    items: IItem[];
    categories: ICategory[] | undefined;
    categoriesAvailable: boolean;
    renameItem: renameItem;
    addStock: addStock;
    addItemCategory: addItemCategory;
    remove: (id: number | undefined) => Promise<void>;
    itemsQtyTotal: number;
    itemsPerPage: number;
    currentPageNumber: number;
    setItemsPerPage: (data: number) => void;
    setCurrentPageNumber: (data: number) => void;
    isNewDataFetching: boolean;
    setIsNewDataFetching: (data: boolean) => void;
    setSearchPhrase: (data: string) => void;
    setSearchInput: (data: string) => void;
    searchInput: string;
    orderBy: string;
    setOrderBy: (data: SetStateAction<string>) => void;
    order: Order;
    setOrder: (data: SetStateAction<Order>) => void;

    setCatList: any;
    catList: any;
}) {
    const {
        useGetHeaders,
        rowsPerPageOptions,
        debounceTimeValue,
        formStyle,
        tableBoxForms,
        tableWrapper,
        tablePaperWrapper,
        tableWidthStyle,
        tableRowWrapper,
        tableRowStyle,
        tableModalsWrapper,
        tableBoxWrapper,
        tablePaginationStyle,
        progressLoader,
        opacityHalf,
    } = getStyles();
    const uniqueId = useId();
    const { language } = useLanguage();

    const [selected, setSelected] = useState<number[]>([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const [visibleColumns, setVisibleColumns] = useState<HeadCell[]>([]);
    const [rowOpen, setRowOpen] = useState<Record<number, boolean>>({});
    const [headCells, setHeadCells] = useState(useGetHeaders());
    const [debouncedValue, setDebouncedValue] = useState(searchInput);

    const emptyRows = currentPageNumber > 0 ? Math.max(0, (1 + currentPageNumber) * itemsPerPage - items.length) : 0;

    // columns menu
    const menu = headCells.map((column: HeadCell) => {
        if (column.id !== 'name' && column.id !== 'count') {
            return (
                <MenuItem
                    key={column.id}
                    onClick={(event) => {
                        event.stopPropagation();
                        handleColumnItemClick(column);
                    }}
                >
                    <FormControlLabel control={<Switch checked={column.visible} />} label={getTitle(language, column.label)} />
                </MenuItem>
            );
        }
    });

    // methods
    const handleClickCollapse = (event: React.MouseEvent<unknown>, id: string | number) => {
        const isOpen = !!rowOpen[Number(id)];
        setRowOpen((prevRowOpen) => ({
            ...prevRowOpen,
            [id]: !isOpen,
        }));
    };

    const handleRequestSort = (event: React.MouseEvent<unknown>, property: SetStateAction<string>) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            const newSelected = items.map((n: any) => n.id);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event: React.MouseEvent<unknown>, id: number) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected: number[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }
        setSelected(newSelected);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        if (newPage >= 0 && newPage < Math.ceil(itemsQtyTotal / itemsPerPage) && newPage > currentPageNumber) {
            setCurrentPageNumber(newPage);
        } else {
            setCurrentPageNumber(newPage);
        }
        setIsNewDataFetching(true);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setItemsPerPage(parseInt(event.target.value, 10));
        setCurrentPageNumber(0);
    };

    const handleChangeSearch = (value: any) => {
        if (value === CLEAR_BUTTON) {
            setSearchInput('');
            setSearchPhrase('');
        } else {
            setSearchInput(value.target.value);
        }
    };

    const isSelected = (id: number | string) => selected.indexOf(Number(id)) !== -1;

    // columns menu
    const handleMenuOpen = (event: { currentTarget: SetStateAction<null> }) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleColumnToggle = (clickedColumn: { id: string }) => {
        const updatedColumns = headCells.map((column) => {
            return column.id === clickedColumn.id ? { ...column, visible: !column.visible } : column;
        });
        setHeadCells(updatedColumns);
    };

    const handleColumnItemClick = (column: HeadCell) => {
        handleColumnToggle(column);
    };

    // effects
    useEffect(() => {
        setVisibleColumns(headCells.filter((column) => column.visible));
    }, [headCells]);

    useEffect(() => {
        const t = setTimeout(() => {
            setDebouncedValue(searchInput);
        }, debounceTimeValue);

        return () => {
            clearTimeout(t);
        };
    }, [searchInput]);

    useEffect(() => {
        setSearchPhrase(debouncedValue);
    }, [debouncedValue]);

    return (
        <Box sx={tableWrapper}>
            {isNewDataFetching ? (
                <Box sx={progressLoader}>
                    <CircularProgress color="success" />
                </Box>
            ) : (
                <></>
            )}

            <Paper
                sx={{
                    ...tablePaperWrapper,
                    ...tablePaginationStyle,
                    ...(isNewDataFetching ? { opacityHalf } : {}),
                }}
            >
                <EnhancedTableToolbar
                    numSelected={selected.length}
                    search={searchInput}
                    handleChangeSearch={handleChangeSearch}
                    // columns menu
                    anchorEl={anchorEl}
                    handleMenuOpen={handleMenuOpen}
                    handleMenuClose={handleMenuClose}
                    filterMenu={menu}
                />

                <TableContainer>
                    <Table sx={tableWidthStyle} aria-labelledby="tableTitle">
                        <EnhancedTableHead
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={items.length}
                            visibleColumns={visibleColumns}
                            categories={categories}
                            categoriesAvailable={categoriesAvailable}
                            setCatList={setCatList}
                            catList={catList}
                        />
                        <TableBody>
                            {items.length === 0 && (
                                <TableRow>
                                    <TableCell colSpan={6}>{getTitle(language, 'no_records')}</TableCell>
                                </TableRow>
                            )}
                            {items.map((row: any, index: number) => {
                                const isItemSelected = isSelected(row.id);
                                const labelId = `enhanced-table-checkbox-${index}`;
                                const cats = row?.categories?.map((el: number) => categories && categories.find((c: ICategory) => c.id === el)?.name).join(' ');

                                return (
                                    <Fragment key={uniqueId + index}>
                                        <TableRow
                                            hover
                                            onClick={(event) => {
                                                event.stopPropagation();
                                                handleClickCollapse(event, row.id);
                                            }}
                                            role="checkbox"
                                            aria-checked={isItemSelected}
                                            tabIndex={-1}
                                            key={row.id}
                                            selected={isItemSelected}
                                            sx={{ ...tableRowWrapper, ...(index % 2 && tableRowStyle) }}
                                        >
                                            <TableCell
                                                padding="checkbox"
                                                onClick={(event) => {
                                                    event.stopPropagation();
                                                    handleClick(event, Number(row.id));
                                                }}
                                            >
                                                <Checkbox
                                                    color="primary"
                                                    checked={isItemSelected}
                                                    inputProps={{
                                                        'aria-labelledby': labelId,
                                                    }}
                                                />
                                            </TableCell>
                                            <TableCell id={labelId} padding="none">
                                                <IconButton aria-label="expand row" size="small">
                                                    {rowOpen[Number(row.id)] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                                </IconButton>
                                                {row.name}
                                            </TableCell>

                                            {(row.categories || row.categories === 0 || row.categories === '0') && <TableCell align="center">{cats || '-'}</TableCell>}
                                            {(row.count || row.count === 0 || row.count === '0') && <TableCell align="center">{row.count.toString()}</TableCell>}
                                            {(row.lastPrice || row.lastPrice === 0 || row.lastPrice === '0') && (
                                                <TableCell align="center">{priceFormatter(row.lastPrice)} zł</TableCell>
                                            )}
                                            {(row.totalWeight || row.totalWeight === 0 || row.totalWeight === '0') && (
                                                <TableCell align="center">{priceFormatter(row.totalWeight)}</TableCell>
                                            )}
                                        </TableRow>

                                        {/* item manager in collapse section under the row */}
                                        <TableRow>
                                            <TableCell style={tableModalsWrapper} colSpan={6}>
                                                <Collapse in={!!rowOpen[Number(row.id)]} timeout="auto" unmountOnExit>
                                                    <Box>
                                                        <Box sx={tableBoxWrapper}>
                                                            <Box sx={tableBoxForms}>
                                                                <Box sx={formStyle}>
                                                                    <NameForm item={row} renameItem={renameItem} />
                                                                </Box>

                                                                <Box sx={formStyle}>
                                                                    <DeleteForm item={row} remove={remove} />
                                                                </Box>

                                                                <Box sx={formStyle}>
                                                                    <UpdateForm item={items.find((x: any) => x.id === row.id) || row} addStock={addStock} />
                                                                </Box>

                                                                <Box sx={formStyle}>
                                                                    <CategoryForm item={row} categories={categories} addItemCategory={addItemCategory} />
                                                                </Box>
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                </Collapse>
                                            </TableCell>
                                        </TableRow>
                                    </Fragment>
                                );
                            })}
                            {(emptyRows > 0 && items?.length === 0) ||
                                (items?.length === 0 && (
                                    <TableRow>
                                        <TableCell colSpan={6} />
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>

                <TablePagination
                    rowsPerPageOptions={rowsPerPageOptions}
                    component="div"
                    count={itemsQtyTotal}
                    rowsPerPage={itemsPerPage}
                    page={currentPageNumber}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        </Box>
    );
}
