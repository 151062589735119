import { Autocomplete, Box, Button, DialogContentText, DialogTitle, Divider, Stack, TextField } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import CheckboxList from '../../common/lists/CheckboxList';
import getTitle from '../../../common/translations';
import { useLanguage } from '../../../context/LanguageContext';
import { UNIT_OF_WEIGHT } from '../../../common/dictionary';
import { ICategory } from '../../../models/ICategory';
import { getStyles } from '../tableCommon';
import { getCommonStyles } from '../../../common/commonStyles';

type Props = {
    handelCreateItemDialog: () => void;
    newItemName: string | undefined;
    handleNewItem: (e: any) => void;
    unitsOfWeight: UNIT_OF_WEIGHT[];
    handleUnitOfWeight: (e: React.SyntheticEvent<Element, Event>, unit: UNIT_OF_WEIGHT | null) => void;
    categories: ICategory[] | undefined;
    checked: number[];
    setChecked: React.Dispatch<React.SetStateAction<number[]>>;
    handleCreateItem: () => void;
};

export function CreateItemDialog({
    handelCreateItemDialog,
    newItemName,
    handleNewItem,
    unitsOfWeight,
    handleUnitOfWeight,
    categories,
    checked,
    setChecked,
    handleCreateItem,
}: Props) {
    const { language } = useLanguage();
    const { funcButtonWrapper, buttonItemsStyle } = getStyles();
    const { simpleBtnStyle } = getCommonStyles();

    return (
        <Box>
            <Box sx={funcButtonWrapper}>
                <Stack direction="row" divider={<Divider flexItem />} alignItems="center" justifyContent={'space-evenly'} gap={'5px'}>
                    <Button variant="outlined" onClick={handelCreateItemDialog} sx={{ ...buttonItemsStyle, ...simpleBtnStyle }}>
                        {getTitle(language, 'back_to_Items')}
                    </Button>
                </Stack>
            </Box>

            <Box
                sx={{
                    padding: '1rem 1rem',
                    pt: '0rem',
                }}
            >
                <DialogTitle
                    sx={{
                        padding: '1rem 0.3rem',
                    }}
                >
                    {getTitle(language, 'create_new_item')}
                </DialogTitle>

                <DialogContentText>{getTitle(language, 'create_new_item_dialog_cont')}</DialogContentText>

                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label={getTitle(language, 'item_name')}
                    type="text"
                    required
                    fullWidth
                    variant="standard"
                    value={newItemName}
                    onChange={handleNewItem}
                />

                <Autocomplete
                    size="medium"
                    disablePortal
                    id="unitOfWeight"
                    placeholder=" "
                    options={unitsOfWeight}
                    renderInput={(params) => <TextField {...params} required label={getTitle(language, 'unit_of_weight')} variant="standard" />}
                    onChange={handleUnitOfWeight}
                />

                <CheckboxList categories={categories} checked={checked} setChecked={setChecked} />

                <Box mt="10px">
                    <Button variant="outlined" onClick={handleCreateItem} endIcon={<SendIcon />} sx={{ ...buttonItemsStyle, ...simpleBtnStyle }}>
                        {getTitle(language, 'create')}
                    </Button>
                </Box>
            </Box>
        </Box>
    );
}
