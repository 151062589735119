import { useEffect, useState } from 'react';
import { Box, LinearProgress, Stack, Divider, Button } from '@mui/material';

import CreateRecipe from './recipe/createRecipe/CreateRecipe';
import getTitle from '../../common/translations';
import { useLanguage } from '../../context/LanguageContext';
import { EnhancedCollapsibleTable } from './table/EnhancedCollapsibleTable';
import { getStyles } from './recipeCommon';
import { IRecipe } from '../../models/Recipe/IRecipe';
import { fetchRecipes } from './dataManager';
import { getMedia } from '../../common/mediaQuery';
import CreateNonLinearRecipe from './recipe/createRecipe/nonLinearRecipe/CreateNonLinearRecipe';

function Recipes() {
    const { language } = useLanguage();
    const { isFullHD } = getMedia();
    const { funcButtonWrapper, wrapperItems, boxItemsLoading, buttonItemsStyle, rowsPP } = getStyles();

    const [createLinearRecipe, setCreateLinearRecipe] = useState(false);
    const [createCustomRecipe, setCreateCustomRecipe] = useState(false);
    const [isNewDataFetching, setIsNewDataFetching] = useState(false);
    const [isAllRecipesIsLoading, setIsAllRecipesIsLoading] = useState(false);

    // api data state
    const [recipesData, setRecipesData] = useState<IRecipe[]>([]);
    const [currentPageNumber, setCurrentPageNumber] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(rowsPP);
    const [itemsQtyTotal, setItemsQtyTotal] = useState(1);

    // search state
    const [searchPhrase, setSearchPhrase] = useState('');
    const [searchInput, setSearchInput] = useState('');

    const [orderByName, setOrderByName] = useState(false);
    const [descending, setDescending] = useState(false);

    const handleOrderByName = () => {
        if (!orderByName) {
            setOrderByName(true);
        } else {
            setDescending(!descending);
        }
    };

    const CATEGORIES = '';
    const ORDER_BY_COST = false;

    const { recipes, getAllItemsIsLoading, getAllItemsError, getAllItemsIsSuccess } = fetchRecipes({
        currentPageNumber,
        itemsPerPage,
        searchPhrase,
        categories: CATEGORIES,
        descending,
        orderByName,
        orderByCost: ORDER_BY_COST,
    });

    useEffect(() => {
        if (recipes) {
            setRecipesData(recipes.data);
            setCurrentPageNumber(recipes.page);
            setItemsPerPage(recipes.pageSize);
            setItemsQtyTotal(recipes.totalCount);
            setIsNewDataFetching(false);

            setIsAllRecipesIsLoading(!getAllItemsIsSuccess);
        }
    }, [recipes]);

    return (
        <Box sx={{ ...wrapperItems, ...(isFullHD ? { minHeight: '900px' } : {}) }}>
            <Box sx={funcButtonWrapper}>
                <Stack direction="row" divider={<Divider flexItem />} alignItems="center" justifyContent={'center'} gap={'5px'}>
                    {!createCustomRecipe && (
                        <Button
                            variant="outlined"
                            onClick={() => setCreateLinearRecipe(!createLinearRecipe)}
                            sx={{
                                ...buttonItemsStyle,
                                margin: '5px',
                                boxShadow: '0px 1px 3px #999',
                                fontWeight: '600',
                                backgroundColor: 'rgba(25, 118, 210, 0.08)',
                            }}
                        >
                            {createLinearRecipe ? `${getTitle(language, 'back_to_recipes')}` : `${getTitle(language, 'create_recipe')}`}
                        </Button>
                    )}

                    {/* 
                    {!createLinearRecipe && (
                        <Button
                            variant="outlined"
                            onClick={() => setCreateCustomRecipe(!createCustomRecipe)}
                            sx={{
                                ...buttonItemsStyle,
                                margin: '5px',
                                boxShadow: '0px 1px 3px #999',
                                fontWeight: '600',
                                backgroundColor: 'rgba(25, 200, 50, 0.08)',
                                color: 'rgba(1, 50, 32, 0.7)',
                            }}
                        >
                            {createCustomRecipe ? `${getTitle(language, 'back_to_recipes')}` : `${getTitle(language, 'create_non_linear_recipe')}`}
                        </Button>
                    )}
                     */}
                </Stack>
            </Box>

            {(getAllItemsIsLoading || isAllRecipesIsLoading) && (
                <Box sx={boxItemsLoading}>
                    <LinearProgress />
                </Box>
            )}

            {createLinearRecipe && <CreateRecipe setCreateRecipe={setCreateLinearRecipe} setIsAllRecipesIsLoading={setIsAllRecipesIsLoading} />}

            {/* {createCustomRecipe && <CreateNonLinearRecipe setCreateRecipe={setCreateCustomRecipe} />} */}

            {!createLinearRecipe && !createCustomRecipe && getAllItemsIsSuccess && !getAllItemsIsLoading && !getAllItemsError && (
                <EnhancedCollapsibleTable
                    items={recipesData}
                    itemsQtyTotal={itemsQtyTotal}
                    itemsPerPage={itemsPerPage}
                    currentPageNumber={currentPageNumber}
                    setItemsPerPage={setItemsPerPage}
                    setCurrentPageNumber={setCurrentPageNumber}
                    isNewDataFetching={isNewDataFetching}
                    setIsNewDataFetching={setIsNewDataFetching}
                    setSearchPhrase={setSearchPhrase}
                    setSearchInput={setSearchInput}
                    searchInput={searchInput}
                    setOrderByName={handleOrderByName}
                    orderByName={orderByName}
                    setIsAllRecipesIsLoading={setIsAllRecipesIsLoading}
                />
            )}
        </Box>
    );
}

export default Recipes;
