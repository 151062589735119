import { useEffect } from 'react';
import { Box, CircularProgress } from '@mui/material';
import { IRecipe } from '../../../../models/Recipe/IRecipe';
import { getStyles } from '../../recipeCommon';
import { getRecipe } from '../../dataManager';
import { getMedia } from '../../../../common/mediaQuery';
import { EditRecipe } from './EditRecipe';

interface Props {
    recipe: IRecipe;
    setIsLoading: (arg0: boolean) => void;
    handleClickEdit: (id: string | number) => Promise<void>;
    isLoading: boolean;
}

export const EditRecipeHOC = ({ recipe, setIsLoading, handleClickEdit, isLoading }: Props) => {
    const { opacityHalf, opacityFull } = getStyles();
    const { isTablet } = getMedia();

    // api
    const { recipeData, isLoadingRecipe, getRecipeError, isSuccessRecipe } = getRecipe(recipe.id, recipe.isLinear, '1.00');
    const key_id = `${recipeData?.recipeName || recipeData?.createdAt}-${recipeData?.id}`;

    // effects
    useEffect(() => {
        if (isSuccessRecipe) {
            setIsLoading(false);
        }
    }, [isSuccessRecipe]);

    return (
        <Box
            sx={{
                flexBasis: isTablet ? '100%' : '59%',
                position: 'relative',
                ...(isLoadingRecipe ? opacityHalf : isSuccessRecipe ? opacityFull : opacityHalf),
            }}
        >
            {isLoadingRecipe ? (
                <Box sx={{ position: 'absolute', top: '40%', left: '-5%', zIndex: 99 }}>
                    <CircularProgress />
                </Box>
            ) : (
                <></>
            )}
            {recipeData && !isLoadingRecipe && <EditRecipe key={key_id} recipeData={recipeData} size={'1.00'} handleClickEdit={handleClickEdit} />}
        </Box>
    );
};
