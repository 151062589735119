import React, { createContext, useState, useContext, ReactNode } from 'react';

interface LanguageContextValue {
    language: string;
    changeLanguage: (newLanguage: string) => void;
}

const LanguageContext = createContext<LanguageContextValue | undefined>(undefined);

export const useLanguage = () => {
    const context = useContext(LanguageContext);
    if (!context) {
        throw new Error('useLanguage must be used within a LanguageProvider');
    }
    return context;
};

export const LanguageProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [language, setLanguage] = useState('ru');

    const changeLanguage = (newLanguage: string) => {
        setLanguage(newLanguage);
    };

    const value: LanguageContextValue = {
        language,
        changeLanguage,
    };

    return <LanguageContext.Provider value={value}>{children}</LanguageContext.Provider>;
};
