import { CREATE_CATEGORY, GET_ALL_CATEGORIES } from '../common/endpoints';
import { ICategory } from '../models/ICategory';
import { baseAPI } from './BaseAPI';

export const categoryAPI = baseAPI.injectEndpoints({
    endpoints: (build) => ({
        getAllCategories: build.query<ICategory[], void>({
            query: () => ({
                url: GET_ALL_CATEGORIES,
                method: 'GET',
            }),

            providesTags: (result) => ['Category'],
        }),

        createCategory: build.mutation<ICategory, string>({
            query: (categoryName: string) => ({
                url: CREATE_CATEGORY,
                method: 'POST',
                params: {
                    categoryName: categoryName,
                },
            }),

            invalidatesTags: ['Category'],
        }),
    }),
});
