import { Fragment, SetStateAction, useEffect, useId, useState } from 'react';

import { Button, CircularProgress, Collapse, InputLabel, Select, MenuItem, FormControl } from '@mui/material';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import { EnhancedTableToolbar } from './EnhancedTableToolbar';
import { EnhancedTableHead } from './EnhancedTableHead';
import { IRecipe, ServiceSize } from '../../../models/Recipe/IRecipe';
import { getStyles, Order } from '../recipeCommon';
import Recipe from '../recipe/Recipe';
import { EditRecipe } from '../recipe/EditRecipe/EditRecipe';
import { CLEAR_BUTTON, RECIPE_SIZE } from '../../../common/dictionary';
import getTitle from '../../../common/translations';
import { useLanguage } from '../../../context/LanguageContext';
import priceFormatter from '../../../common/priceFormatter';
import { getMedia } from '../../../common/mediaQuery';
import { EditRecipeWithProportions } from '../recipe/EditRecipe/nonLinear/EditRecipeWithProportions';
import { getRecipeSize } from '../../../common/helpers';
import { EditRecipeHOC } from '../recipe/EditRecipe/EditRecipeHOC';

type Props = {
    items: IRecipe[];
    itemsQtyTotal: number;
    itemsPerPage: number;
    currentPageNumber: number;
    setItemsPerPage: (qty: number) => void;
    setCurrentPageNumber: (page: number) => void;
    isNewDataFetching: boolean;
    setIsNewDataFetching: (arg: boolean) => void;
    setSearchPhrase: (arg: string) => void;
    setSearchInput: (arg: string) => void;
    searchInput: string;
    setOrderByName: () => void;
    orderByName: boolean;
    setIsAllRecipesIsLoading: (arg: boolean) => void;
};

export function EnhancedCollapsibleTable({
    items,
    currentPageNumber,
    itemsQtyTotal,
    itemsPerPage,
    setItemsPerPage,
    setCurrentPageNumber,
    isNewDataFetching,
    setIsNewDataFetching,
    setSearchPhrase,
    setSearchInput,
    searchInput,
    setOrderByName,
    orderByName,
    setIsAllRecipesIsLoading,
}: Props) {
    const {
        useGetHeaders,
        rowsPerPageOptions,
        debounceTimeValue,
        tableWrapper,
        tablePaperWrapper,
        tableWidthStyle,
        tableRowWrapper,
        tableRowStyle,
        tableModalsWrapper,
        tablePaginationStyle,
        opacityHalf,
        progressLoader,
        tableBodyBox,
        partsWeightAutocomplete,
        inputTableBodyLabel,
        inputTableBody,
        tableBodySecondBox,
        fontSize,
        controlsWrapper,
        controlsBoxWrapper,
        controlsButtons,
        infoEditBox,
        recipeBoxWrapper,
        tablePaginationSelectProps,
        tablePagination,
    } = getStyles();
    const uniqueId = useId();
    const headCells = useGetHeaders();
    const { language } = useLanguage();
    const { isTablet } = getMedia();

    const [order, setOrder] = useState<Order>('desc');
    const [orderBy, setOrderBy] = useState<string>(orderByName ? 'name' : '');
    const [selected, setSelected] = useState<number[]>([]);
    const [rowOpen, setRowOpen] = useState<Record<number, boolean>>({});
    const [editOpen, setEditOpen] = useState<Record<number, boolean>>({});
    const [serviceSize, setServiceSize] = useState<ServiceSize>({ '-1': { partOfWeight: '1.00' } });
    const [isLoading, setIsLoading] = useState(false);
    const [debouncedValue, setDebouncedValue] = useState(searchInput);

    const emptyRows = currentPageNumber > 0 ? Math.max(0, (1 + currentPageNumber) * itemsPerPage - items.length) : 0;

    // methods
    const handleClickCollapse = (id: string | number) => {
        const isOpen = !!rowOpen[Number(id)];
        const isEdit = !!editOpen[Number(id)];

        setRowOpen((prevRowOpen) => ({
            ...prevRowOpen,
            [id]: !isOpen,
        }));

        if (isEdit) {
            setEditOpen((prevRowOpen) => ({
                ...prevRowOpen,
                [id]: !isEdit,
            }));
        }

        setServiceSize((prevServiceSize) => {
            if (!isOpen) {
                delete prevServiceSize[Number(id)];
            }

            return prevServiceSize;
        });
    };

    const handleClickEdit = async (id: string | number) => {
        const isEdit = !!editOpen[Number(id)];

        if (!isEdit) {
            setIsLoading(true);
        } else {
            setIsLoading(false);
        }

        setEditOpen((prevRowOpen) => ({
            ...prevRowOpen,
            [id]: !isEdit,
        }));
    };

    const handleRequestSort = (event: React.MouseEvent<unknown>, property: SetStateAction<string>) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);

        setOrderByName();
    };

    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            const newSelected = items.map((n) => Number(n.id));
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const handleRowClick = (id: number) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected: number[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }
        setSelected(newSelected);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        if (newPage >= 0 && newPage < Math.ceil(itemsQtyTotal / itemsPerPage) && newPage > currentPageNumber) {
            setCurrentPageNumber(newPage);
        } else {
            setCurrentPageNumber(newPage);
        }
        setIsNewDataFetching(true);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setItemsPerPage(parseInt(event.target.value, 10));
        setCurrentPageNumber(0);
    };

    const handleChangeSearch = (value: string) => {
        if (value === CLEAR_BUTTON) {
            setSearchInput('');
            setSearchPhrase('');
        } else {
            setSearchInput(value);
        }
    };

    const isSelected = (id: number | string) => selected.indexOf(Number(id)) !== -1;

    const handleUnitOfWeight = (partValue: string, id: string | number) => {
        setServiceSize((prevServiceSize) => {
            const updatedServiceSize = {
                ...prevServiceSize,
                [id]: {
                    partOfWeight: partValue,
                },
            };
            return updatedServiceSize;
        });
    };

    useEffect(() => {
        const t = setTimeout(() => {
            setDebouncedValue(searchInput);
        }, debounceTimeValue);

        return () => {
            clearTimeout(t);
        };
    }, [searchInput]);

    useEffect(() => {
        setSearchPhrase(debouncedValue);
    }, [debouncedValue]);

    return (
        <Box sx={tableWrapper}>
            {isNewDataFetching ? (
                <Box sx={progressLoader}>
                    <CircularProgress color="success" />
                </Box>
            ) : (
                <></>
            )}
            <Paper
                sx={{
                    ...tablePaperWrapper,
                    ...tablePaginationStyle,
                    ...(isNewDataFetching ? { opacityHalf } : {}),
                }}
            >
                <EnhancedTableToolbar
                    numSelected={selected.length}
                    searchInput={searchInput}
                    handleChangeSearch={handleChangeSearch}
                    selected={selected}
                    setSelected={setSelected}
                    setIsAllRecipesIsLoading={setIsAllRecipesIsLoading}
                    items={items}
                />

                <TableContainer>
                    <Table sx={tableWidthStyle} aria-labelledby="tableTitle">
                        <EnhancedTableHead
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={items?.length || 0}
                            visibleColumns={headCells}
                        />
                        <TableBody>
                            {items.length === 0 && (
                                <TableRow>
                                    <TableCell colSpan={6}>{getTitle(language, 'no_records')}</TableCell>
                                </TableRow>
                            )}
                            {items.map((row: IRecipe, index: number) => {
                                const proportions: string[] = row.proportionInfos.map((pi) => pi.proportion);
                                const isItemSelected = isSelected(row.id);
                                const labelId = `enhanced-table-checkbox-${index}`;
                                const size = serviceSize[Number(row.id)]?.partOfWeight || (row.isLinear ? '1.00' : (proportions && Number(proportions[0]).toFixed(2)) || '1.00');
                                //const size_options = row.isLinear ? RECIPE_SIZE : proportions || [];
                                const size_options = getRecipeSize(row);
                                // const price = row.isLinear
                                //     ? priceFormatter((Number(row.proportionInfos[0].cost || '0.00') * Number(size)).toFixed(2))
                                //     : priceFormatter(row.proportionInfos.find((pi) => pi.proportion === size)?.cost || '0.00');
                                const price = row.proportionInfos.length > 0 ? priceFormatter((Number(row.proportionInfos[0].cost || '0.00') * Number(size)).toFixed(2)) : '0.00';

                                const collapseControls = (
                                    <Box sx={{ display: 'flex', flexDirection: isTablet ? 'column' : 'row' }}>
                                        <Box sx={{ ...tableBodyBox }}>
                                            <InputLabel htmlFor="partsWeight" sx={isTablet ? inputTableBodyLabel : {}} onClick={(e) => e.preventDefault()}>
                                                {getTitle(language, 'service_size')}
                                            </InputLabel>

                                            <FormControl variant="standard">
                                                <Select
                                                    labelId="partsWeight-label"
                                                    id="partsWeight"
                                                    value={size}
                                                    onChange={(e) => handleUnitOfWeight(e.target.value, row.id)}
                                                    disableUnderline
                                                    disabled={editOpen[Number(row.id)]}
                                                    sx={{
                                                        '& .MuiSelect-select': {
                                                            pr: '25px !important',
                                                            pl: '5px !important',
                                                            ml: '10px',
                                                            ':hover': {
                                                                backgroundColor: 'rgba(0, 0, 0, 0.025)',
                                                            },
                                                            ':focus': {
                                                                backgroundColor: 'rgba(0, 0, 0, 0.025)',
                                                            },
                                                        },
                                                        '& .MuiInputBase-input': {
                                                            ...(isTablet ? { ...fontSize } : {}),
                                                            padding: '0px',
                                                        },
                                                    }}
                                                >
                                                    {size_options.map((option: any) => (
                                                        <MenuItem key={option} value={option}>
                                                            {option}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Box>

                                        <Box sx={{ ...tableBodySecondBox, ...(isTablet ? { ml: '0px', mb: '10px', mt: '5px' } : {}) }}>
                                            <InputLabel htmlFor="partsWeight" sx={isTablet ? { fontSize } : {}}>
                                                {getTitle(language, 'service_price')}:&nbsp;
                                            </InputLabel>
                                            <Box
                                                sx={{
                                                    ...(isTablet ? { fontSize } : {}),
                                                }}
                                            >
                                                {price} &nbsp;zł
                                            </Box>
                                        </Box>
                                    </Box>
                                );

                                return (
                                    <Fragment key={uniqueId + index}>
                                        <TableRow
                                            hover
                                            onClick={(event) => {
                                                event.stopPropagation();
                                                handleClickCollapse(row.id);
                                            }}
                                            role="checkbox"
                                            aria-checked={isItemSelected}
                                            tabIndex={-1}
                                            key={row.id}
                                            selected={isItemSelected}
                                            sx={{ ...tableRowWrapper, ...(index % 2 && tableRowStyle) }}
                                        >
                                            <TableCell
                                                padding="checkbox"
                                                onClick={(event) => {
                                                    event.stopPropagation();
                                                    handleRowClick(Number(row.id));
                                                }}
                                            >
                                                <Checkbox
                                                    color="primary"
                                                    checked={isItemSelected}
                                                    inputProps={{
                                                        'aria-labelledby': labelId,
                                                    }}
                                                />
                                            </TableCell>
                                            <TableCell id={labelId} padding="none">
                                                <IconButton aria-label="expand row" size="small">
                                                    {rowOpen[Number(row.id)] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                                </IconButton>
                                                {row.recipeName}
                                            </TableCell>

                                            <TableCell align="center">{price} &nbsp;zł</TableCell>
                                        </TableRow>

                                        {/* item manager in collapse section under the row */}
                                        <TableRow>
                                            <TableCell style={tableModalsWrapper} colSpan={6}>
                                                <Collapse in={!!rowOpen[Number(row.id)]} timeout="auto" unmountOnExit>
                                                    {/* controls */}
                                                    <Box sx={controlsWrapper}>
                                                        <Box sx={controlsBoxWrapper}>
                                                            <Button
                                                                onClick={(event) => {
                                                                    event.stopPropagation();
                                                                    handleClickEdit(row.id);
                                                                }}
                                                                sx={{ ...controlsButtons, ...(isTablet ? { width: 'auto', pl: '5px', pr: '5px' } : {}) }}
                                                            >
                                                                {!!editOpen[Number(row.id)] ? getTitle(language, 'cancel_editing') : getTitle(language, 'edit_recipe')}
                                                            </Button>

                                                            {/* info */}
                                                            {isTablet ? <></> : <>{collapseControls}</>}
                                                        </Box>
                                                    </Box>

                                                    {/* info */}
                                                    {isTablet && !!!editOpen[Number(row.id)] ? <Box sx={infoEditBox}>{collapseControls}</Box> : <></>}

                                                    {/* recipe data */}
                                                    <Box>
                                                        <Box sx={recipeBoxWrapper}>
                                                            {/* for tablet and mobile */}
                                                            {!editOpen[Number(row.id)] && isTablet && (
                                                                <Recipe
                                                                    key={Number(row.id) + Math.random()}
                                                                    recipe={row}
                                                                    openedRow={!!editOpen[Number(row.id)]}
                                                                    isLoading={isLoading}
                                                                    size={Number(size)}
                                                                />
                                                            )}

                                                            {/* for desktop */}
                                                            {!isTablet && (
                                                                <Recipe
                                                                    key={Number(row.id) + Math.random()}
                                                                    recipe={row}
                                                                    openedRow={!!editOpen[Number(row.id)]}
                                                                    isLoading={isLoading}
                                                                    size={Number(size)}
                                                                />
                                                            )}

                                                            {/* for desktop, tablet and mobile for linear recipe */}
                                                            {editOpen[Number(row.id)] && (
                                                                <EditRecipeHOC
                                                                    key={`${row.id}-${row.recipeName || row.createdAt}`}
                                                                    recipe={row}
                                                                    setIsLoading={setIsLoading}
                                                                    isLoading={isLoading}
                                                                    handleClickEdit={handleClickEdit}
                                                                />
                                                            )}
                                                        </Box>
                                                    </Box>
                                                </Collapse>
                                            </TableCell>
                                        </TableRow>
                                    </Fragment>
                                );
                            })}

                            {(emptyRows > 0 && items?.length === 0) ||
                                (items?.length === 0 && (
                                    <TableRow>
                                        <TableCell colSpan={6} />
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>

                <TablePagination
                    rowsPerPageOptions={rowsPerPageOptions}
                    component="div"
                    count={itemsQtyTotal}
                    rowsPerPage={itemsPerPage}
                    page={currentPageNumber}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    SelectProps={tablePaginationSelectProps}
                    sx={tablePagination}
                />
            </Paper>
        </Box>
    );
}

/* 
    TODO: when search check pagination behavior
    - now if total 5 pages and user stays on 4th but after search got only 3 pages error comes in console
    - Failed prop type: MUI: The page prop of a TablePagination is out of range (0 to 1, but page is 2).
 */
