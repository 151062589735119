import { Key } from 'react';
import { AlertColor } from '@mui/material';
import { alpha } from '@mui/material';
import { MutationTrigger } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import { BaseQueryFn, FetchArgs, FetchBaseQueryError, FetchBaseQueryMeta, MutationDefinition } from '@reduxjs/toolkit/dist/query';
import { IItem } from '../../models/Items/IItem';
import { UNIT_OF_WEIGHT } from '../../common/dictionary';
import { IRecipe } from '../../models/Recipe/IRecipe';
import { getMedia } from '../../common/mediaQuery';
import getTitle from '../../common/translations';
import { useLanguage } from '../../context/LanguageContext';

export const successColor: AlertColor = 'success';
export const errorColor: AlertColor = 'error';
export const unitsOfWeight = [UNIT_OF_WEIGHT.G, UNIT_OF_WEIGHT.PCS];

export type Order = 'asc' | 'desc';

export interface HeadCell {
    id: string;
    disablePadding: boolean;
    label: string;
    numeric: boolean;
    visible: boolean;
}

export type addItemCategory = MutationTrigger<
    MutationDefinition<
        IItem,
        BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, {}, FetchBaseQueryMeta>,
        'Order' | 'Item' | 'Auth' | 'Category' | 'Customer' | 'Recipe' | 'User',
        IItem,
        'baseAPI'
    >
>;

//  FIXME: fix the type
export type updateItem = MutationTrigger<
    MutationDefinition<
        [number, string],
        BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, {}, FetchBaseQueryMeta>,
        'Order' | 'Item' | 'Auth' | 'Category' | 'Customer' | 'Recipe' | 'User',
        IItem,
        'baseAPI'
    >
>;

export function getStyles(numSelected?: number) {
    const { isMobile, isTablet, rowsPerPageOptions, rowsPP, debounceTimeValue, isMobileHeight700 } = getMedia();
    const { language } = useLanguage();

    // titles
    const titleDeleteForm = isMobile ? '' : getTitle(language, 'delete');
    const labelDeleteForm = isMobile ? getTitle(language, 'delete_item_info_start_mobile') : getTitle(language, 'delete_item_info_start');
    const titleNameForm = isMobile ? '' : getTitle(language, 'save');
    const titleUpdateForm = isMobile ? '' : getTitle(language, 'save');

    // styles
    const formStyle = isMobile ? { flex: '1 0 50%' } : { flex: '1 0 calc(50% - 16px)' };
    const plr = { pl: '5px', pr: '5px' };
    const toolbarWrapper = {
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected &&
            numSelected > 0 && {
                bgcolor: (theme: { palette: { primary: { main: string }; action: { activatedOpacity: number } } }) =>
                    alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
            }),
    };
    const inputStylePadding = { '& label': plr, '& input': plr };
    const inputStyle = {
        flex: '1 1 50%',
        mr: '0px',
        padding: 0,
        maxHeight: '30px',
        minHeight: '30px',
        height: '30px',
        ...inputStylePadding,

        '& .MuiInputBase-root': { mt: 0 },
        '& .MuiFormLabel-root': { top: '-12px' },
    };
    const tableBoxForms = {
        display: 'flex',
        maxWidth: isTablet ? '720px' : '1100px',
        flexWrap: 'wrap',
        gap: '16px',
        overflowX: 'auto',
        width: '100%',
    };
    const updateFormInput = {
        mr: '10px',
        '& label': plr,
        '& input': plr,
    };
    const tableBoxWrapper = { flexGrow: 1, mt: '10px', mb: '10px' };
    const labelStyle = { flex: '1 1 0%' };
    const titleStyle = { maxWidth: '215px' };
    const tableRowStyle = { backgroundColor: '#fcfdf5', height: '60px' };
    const tableCellStyle = isTablet ? { fontWeight: '600' } : { fontWeight: '600', fontSize: '1rem' };
    const funcButtonWrapper = { mt: '10px', mb: '0px' };
    const wrapperItems = { padding: '10px' };
    const boxItemsLoading = { width: '100%', paddingTop: '25px' };
    const buttonItemsStyle = isTablet ? { lineHeight: '2.5rem' /* fontSize: '0.75rem' */ } : {};
    const tableWrapper = { width: '100%' };
    const tablePaperWrapper = { width: '100%', mb: '0px', boxShadow: '0px 0px 0px 0px rgba(0,0,0,0), 0px 0px 0px 0px rgba(0,0,0,0), 0px 0px 0px 0px rgba(0,0,0,0)' };
    const tableWidthStyle = isTablet ? {} : { minWidth: 750 };
    const tableRowWrapper = { cursor: 'pointer', height: '60px' };
    const tableModalsWrapper = { paddingBottom: 0, paddingTop: 0 };
    const partsInputStyle = {
        width: '320px',
        '& .MuiFilledInput-input': { background: 'transparent !important' },
        '& .MuiInputBase-root': { background: 'transparent !important' },
        '& .MuiFilledInput-root': { background: 'transparent !important' },
    };
    const tablePaginationStyle = isTablet
        ? {
              '& .MuiTablePagination-select': { pl: '5px !important', pr: '15px !important' },
              '& .MuiTablePagination-input': { pr: '5px !important', pl: '5px !important', ml: '5px !important', mr: '5px !important' },
              '& .MuiTablePagination-actions': { pt: '2px', pr: '0px !important', pl: '0px !important', ml: '0px !important', mr: '0px !important' },
              '& .MuiTablePagination-displayedRows': { pt: '2px' },
          }
        : {};
    const recipePartItemStyle = isTablet ? { pl: '10px' } : { pl: '10px', pt: '10px', pb: '10px' };
    const opacityHalf = { opacity: '0.5', pointerEvents: 'none' };
    const opacityFull = { opacity: 1 };
    const progressLoader = { position: 'absolute', top: '50%', left: '47%' };
    const fontSize = { fontSize: '0.8rem !important' };
    const tableBodyBox = { display: 'flex', flexDirection: 'row', alignItems: 'center', pt: '3px' };
    const tableBodySecondBox = { display: 'flex', flexDirection: 'row', alignItems: 'center', ml: '20px', pt: '3px' };
    const partsWeightAutocomplete = {
        '& .MuiAutocomplete-inputRoot': { pr: '20px !important', pl: '10px !important', pt: '3px !important' },
        '& .MuiAutocomplete-input': { minWidth: '33px !important', ...(isTablet ? { ...fontSize } : {}) },
    };
    const inputTableBodyLabel = { ...fontSize };
    const inputTableBody = { ...fontSize, minHeight: '25px !important' };
    const controlsWrapper = { display: 'flex' };
    const controlsBoxWrapper = { display: 'flex', justifyContent: 'space-between', minWidth: '28%' };
    const controlsButtons = { mt: '5px', mr: '20px', ...(isTablet ? { fontSize } : {}) };
    const infoEditBox = { display: 'flex', flexDirection: 'row', alignItems: 'center', pl: '7px' };
    const recipeBoxWrapper = { ...formStyle, display: 'flex', justifyContent: 'space-between' };
    const tablePaginationSelectProps = { MenuProps: { sx: { '& .MuiTablePagination-menuItem': { fontSize: '0.85rem', minHeight: '25px !important' } } } };
    const tablePagination = { '& .MuiSelect-select': { pb: '1px' }, '& .MuiSelect-icon': { top: '1px' } };
    const autoCompleteLabelStyle = { '& .MuiInputLabel-root': { pointerEvents: 'none' } };

    const wrapperStyle = isMobile
        ? {
              maxWidth: '280px',
          }
        : {};
    const formWrapper = {
        display: 'flex',
        justifyContent: 'space-between',
        ...wrapperStyle,
    };

    const updateFormStyle = isMobile
        ? {
              maxWidth: '100%',
          }
        : {
              maxWidth: '48%',
          };
    const updateFormWrapper = {
        display: 'flex',
        justifyContent: 'space-between',
        ...updateFormStyle,
    };

    const btnWrapperStyle = isTablet
        ? {
              mb: '12px',
          }
        : {};
    const buttonWrapper = {
        mb: '4px',
        ml: '20px',
        display: 'flex',
        alignItems: 'flex-end',
        ...btnWrapperStyle,
    };

    // methods
    function useGetHeaders() {
        const headCellsDefault: HeadCell[] = [
            {
                id: 'name',
                label: 'customer_name',
                disablePadding: false,
                numeric: false,
                visible: true,
            },
            {
                id: 'qty',
                label: 'customer_qty',
                disablePadding: false,
                numeric: false,
                visible: true,
            },
        ];

        return headCellsDefault;
    }

    function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }

    function getComparator(order: Order, orderBy: Key): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
        return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
    }

    function stableSort(
        array: IRecipe[],
        comparator: (
            a: {
                [x: string]: string | number;
                [x: number]: string | number;
            },
            b: {
                [x: string]: string | number;
                [x: number]: string | number;
            }
        ) => number
    ) {
        const stabilizedThis = array.map((el: IRecipe, index: number) => [el, index]);
        stabilizedThis.sort((a: any, b: any) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) {
                return order;
            }
            return a[1] - b[1];
        });
        return stabilizedThis.map((el: any) => el[0]);
    }

    return {
        inputStyle,
        labelStyle,
        titleStyle,
        toolbarWrapper,
        buttonItemsStyle,
        boxItemsLoading,
        wrapperItems,
        funcButtonWrapper,
        tableModalsWrapper,
        tableCellStyle,
        tableRowStyle,
        tableRowWrapper,
        tableWidthStyle,
        tablePaperWrapper,
        tableWrapper,
        formStyle,
        tableBoxWrapper,
        tableBoxForms,
        recipePartItemStyle,
        rowsPerPageOptions,
        rowsPP,
        debounceTimeValue,
        partsInputStyle,
        tablePaginationStyle,
        opacityHalf,
        progressLoader,
        tableBodyBox,
        partsWeightAutocomplete,
        inputTableBodyLabel,
        inputTableBody,
        tableBodySecondBox,
        fontSize,
        controlsWrapper,
        controlsBoxWrapper,
        controlsButtons,
        infoEditBox,
        recipeBoxWrapper,
        tablePaginationSelectProps,
        tablePagination,
        opacityFull,
        autoCompleteLabelStyle,
        titleDeleteForm,
        labelDeleteForm,
        formWrapper,
        wrapperStyle,
        inputStylePadding,
        buttonWrapper,
        titleNameForm,
        titleUpdateForm,
        updateFormInput,
        updateFormStyle,
        stableSort,
        getComparator,
        useGetHeaders,
    };
}

// FIXME: check used styles and delete unused TODO:
