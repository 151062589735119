import { Box, CircularProgress } from '@mui/material';

import { getMedia } from '../../../common/mediaQuery';
import { IRecipePart } from '../../../models/Recipe/IRecipePart';
import { IRecipePartItem } from '../../../models/Recipe/IRecipePartItem';
import { recipeAPI } from '../../../services/RecipeServices';
import { useLanguage } from '../../../context/LanguageContext';
import getTitle from '../../../common/translations';

interface Props {
    recipeId: number;
    size: number;
}

const RecipeInSubOrder = ({ recipeId, size }: Props) => {
    const { language } = useLanguage();
    const { isMobile } = getMedia();

    const {
        data: recipeData,
        isLoading: isLoadingRecipe,
        error: getRecipeError,
        isSuccess: isSuccessRecipe,
    } = recipeAPI.useGetRecipeWithSelectedProportionQuery([`${recipeId}`, `${size}`]);

    return (
        <Box sx={{ margin: '5px', boxShadow: '0px 1px 3px #999', ...(!isMobile && { ml: '10px' }) }}>
            {isLoadingRecipe ? <CircularProgress /> : <></>}
            {recipeData && (
                <Box sx={{ padding: '5px' }}>
                    <Box>
                        {getTitle(language, 'recipe_name')}: {recipeData.recipeName}
                    </Box>
                    <Box>
                        {recipeData.recipeDescription && (
                            <>
                                {getTitle(language, 'description_orders')}
                                {recipeData.recipeDescription}
                            </>
                        )}
                    </Box>
                    <Box sx={{ margin: '5px', ...(!isMobile && { ml: '10px' }) }}>
                        {recipeData.parts && recipeData?.parts.map((part) => <RecipePart key={part.id} part={part} />)}
                    </Box>
                </Box>
            )}
        </Box>
    );
};

export default RecipeInSubOrder;

interface RecipePartProps {
    part: IRecipePart;
}

const RecipePart = ({ part }: RecipePartProps) => {
    const { language } = useLanguage();

    return (
        <Box sx={{ borderBottom: '1px solid #999' }}>
            {getTitle(language, 'part_name')}: {part.partName}
            {part.items && part?.items.map((item) => <RecipePartItem key={item.id} item={item} />)}
        </Box>
    );
};

interface RecipePartItemProps {
    item: IRecipePartItem;
}

const RecipePartItem = ({ item }: RecipePartItemProps) => {
    const qty = item.proportions[0].quantity;
    return (
        <Box>
            {item.itemName} {qty} {item.unitOfWeight}
        </Box>
    );
};
