import { useRef, useState } from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, TextField } from '@mui/material';

import { DTO_STATE, IS_DELETED, ORDER_STAGES } from '../../../common/dictionary';
import { getCommonStyles } from '../../../common/commonStyles';
import { getDateTime } from '../../../common/helpers';
import { ICustomer } from '../../../models/ICustomer';
import { IOrder } from '../../../models/Order/IOrder';
import { ISubOrder } from '../../../models/Order/ISubOrder';
import { orderAPI } from '../../../services/OrderServices';
import { recipeAPI } from '../../../services/RecipeServices';
import { useLanguage } from '../../../context/LanguageContext';
import getTitle from '../../../common/translations';
import SubOrder from '../order/SubOrder';

interface EditOrderProps {
    order: IOrder;
    handleEditMode: () => void;
}

const EditOrder = ({ order, handleEditMode }: EditOrderProps) => {
    const { language } = useLanguage();
    const [, updateState] = useState({});
    const { data: recipes, error: getRecipesError, isLoading: getRecipesIsLoading, isSuccess: getRecipesIsSuccess } = recipeAPI.useGetAllRecipesQuery();
    const [saveOrder, { error: saveOrderError, isSuccess: saveOrderIsSuccess }] = orderAPI.useSaveOrderMutation();
    const buttonStyle = { lineHeight: '1.25rem' /* fontSize: '0.75rem' */ };
    const { simpleBtnStyle } = getCommonStyles();

    const customerData = order.customer!.instagram || order.customer!.faceBook || order.customer!.name;
    const [dialogOpen, setDialogOpen] = useState<boolean>(false);
    const [newDescription, setNewDescription] = useState<string>(order.description);
    const [newPrice, setNewPrice] = useState<number>(order.price);
    const [prepaymentDate, setPrepaymentDate] = useState<string>();
    const [prepaymentBalance, setPrepaymentBalance] = useState<number>(0);
    const inputBalanceRef = useRef<HTMLInputElement>(null);

    const forPayment = order.price - order.prepayment!.prepayment - prepaymentBalance;
    const handelDialogOpen = () => {
        setDialogOpen(!dialogOpen);
    };

    const datePickUp = getDateTime(order.pickUpAt);
    const [changePickUpDate, setChangePickUpDate] = useState<boolean>(false);

    const [newPickUpDate, setNewPickUpDate] = useState<string>('');

    const handlePrepaymentBalance = (e: any) => {
        setPrepaymentBalance(Number(e.target.value));
    };
    const handlePickUpDateTime = (e: any) => {
        processDate(e);
    };
    const handlePrepaymentDate = (e: any) => {
        const exDateTime = new RegExp('(?<year>\\d{4})-(?<month>\\d{1,2})-(?<day>\\d{1,2})');
        if (exDateTime.test(e.target.value)) setPrepaymentDate(e.target.value);
    };

    const processDate = (e: any) => {
        const exDateTime = new RegExp('(?<year>\\d{4})-(?<month>\\d{1,2})-(?<day>\\d{1,2})');
        if (exDateTime.test(e.target.value)) setNewPickUpDate(e.target.value);
    };

    let init: ISubOrder[] = [];
    order.subOrders?.forEach((subOrder) => {
        init.push({ ...subOrder });
    });

    /* FIXME: what is the purpose? TODO: delete? */
    const [editedSubOrders, setEditedSubOrders] = useState<ISubOrder[]>(init);

    const handleSaveOrder = () => {
        const prepaymentState =
            prepaymentBalance === order.prepayment?.prepayment && (prepaymentDate || order.prepayment.prepaymentAt) === order.prepayment.prepaymentAt
                ? DTO_STATE.DTO_UNCHANGED
                : DTO_STATE.DTO_MODIFIED;
        const editedOrder: IOrder = {
            id: order.id,
            cost: order.cost,
            customerId: order.customerId,
            isDeleted: order.isDeleted,
            price: newPrice,
            stage: order.stage,
            state: DTO_STATE.DTO_MODIFIED,
            orderNumber: order.orderNumber,
            description: newDescription,
            pickUpAt: newPickUpDate || order.pickUpAt,
            createdAt: order.createdAt,
            prepayment: { ...order.prepayment!, prepaymentAt: prepaymentDate, prepayment: prepaymentBalance, state: prepaymentState },
            subOrders: [...editedSubOrders],
        };
        saveOrder(editedOrder);
    };

    const setOrderDescription = (e: any) => {
        setNewDescription(e.target.value);
    };

    const setNewValueInSubOrder = (fieldName: string, subOrderId: number | string, value: number) => {
        const i = editedSubOrders.findIndex((x) => x.id === subOrderId);
        let modified = false;
        if (fieldName === 'recipeId') {
            editedSubOrders[i].recipeId = value;
            modified = true;
        }
        if (fieldName === 'counter') {
            editedSubOrders[i].recipeCounter = value;
            modified = true;
        }
        if (fieldName === 'price') {
            editedSubOrders[i].price = value;
            modified = true;
        }
        if (fieldName === 'count') {
            editedSubOrders[i].count = value;
            modified = true;
        }

        if (modified && !subOrderId.toString().includes('-')) editedSubOrders[i].state = DTO_STATE.DTO_MODIFIED;

        handleOrderPrice();
    };

    const handleOrderPrice = () => {
        let price = 0;
        editedSubOrders.forEach((element) => {
            price += element.price;
        });

        setNewPrice(price);
    };

    const deleteSubOrder = (subOrderId: number | string) => {
        const i = editedSubOrders.findIndex((x) => x.id === subOrderId);
        if (subOrderId.toString().includes('-')) {
            const newSubOrders = editedSubOrders.filter((e) => !e.id.toString().includes('-'));
            setEditedSubOrders([...newSubOrders]);
        } else {
            editedSubOrders[i].state = DTO_STATE.DTO_DELETED;
        }
    };

    const addNewSubOrder = () => {
        const newSubOrder: ISubOrder = {
            id: crypto.randomUUID(),
            recipeName: '',
            recipeId: 0,
            description: '',
            price: 0,
            productCost: 0,
            count: 1,
            recipeCounter: 0,
            orderId: order.id,
            isDeleted: IS_DELETED.ACTIVE,
            state: DTO_STATE.DTO_NEW,
        };
        setEditedSubOrders([...editedSubOrders, newSubOrder]);
    };

    return (
        <Box
            style={{
                margin: 10,
            }}
        >
            {saveOrderIsSuccess && <>{handleEditMode()}</>}
            <Stack spacing={2} sx={{ minWidth: 100, maxWidth: 400 }}>
                <Button variant="outlined" onClick={handleEditMode} sx={{ ...buttonStyle, ...simpleBtnStyle }}>
                    {getTitle(language, 'back_to_orders')}
                </Button>
                <Box>
                    {getTitle(language, 'order_number')}
                    {order.orderNumber}
                </Box>
                <TextField
                    style={{ marginBottom: 10 }}
                    required
                    multiline
                    id={'description'}
                    label={getTitle(language, 'description_orders')}
                    placeholder={getTitle(language, 'description_orders')}
                    value={newDescription}
                    onChange={setOrderDescription}
                />
                <Box>
                    {getTitle(language, 'total_order_cost')}
                    {order.cost} zł
                </Box>
                <Box>
                    {getTitle(language, 'prepayment')}
                    {order.stage === ORDER_STAGES.PREPAID
                        ? `${getTitle(language, 'received')} ${order.prepayment!.prepayment} zł ${order.prepayment?.prepaymentAt}`
                        : `${getTitle(language, 'waiting')}`}
                    <Box style={{ paddingTop: 10 }}>
                        <TextField
                            inputRef={inputBalanceRef}
                            required
                            id={'prepaymentBalance'}
                            label={getTitle(language, 'prepayment_balance')}
                            type="number"
                            placeholder={getTitle(language, 'prepayment_balance')}
                            value={prepaymentBalance}
                            focused
                            onChange={handlePrepaymentBalance}
                            onFocus={() => inputBalanceRef.current && inputBalanceRef.current.select()}
                            style={{ marginBottom: 10, paddingRight: 20 }}
                        />
                        <TextField
                            style={{ marginBottom: 10 }}
                            required
                            id={'prepaymentAt'}
                            label={getTitle(language, 'prepayment_at')}
                            type="date"
                            placeholder={getTitle(language, 'prepayment_at')}
                            onChange={handlePrepaymentDate}
                            onClick={handlePrepaymentDate}
                            focused
                        />
                    </Box>
                </Box>
                {getTitle(language, 'customer')}
                {customerData}
                <Button variant="outlined" onClick={handelDialogOpen} sx={{ ...buttonStyle, ...simpleBtnStyle }}>
                    {getTitle(language, 'customer_info')}
                </Button>
                <Box style={{ margin: 10 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        {getTitle(language, 'sub_orders')}
                        &nbsp;
                        <Button variant="outlined" onClick={addNewSubOrder} sx={{ ...buttonStyle, ...simpleBtnStyle }}>
                            {getTitle(language, 'add_suborder')}
                        </Button>
                    </Box>
                    {editedSubOrders?.map((subOrder) => (
                        <SubOrder
                            key={subOrder.id}
                            subOrder={subOrder}
                            editMode={true}
                            recipes={recipes}
                            handleOrderPrice={handleOrderPrice}
                            setNewValueInSubOrder={setNewValueInSubOrder}
                            deleteSubOrder={deleteSubOrder}
                        />
                    ))}
                </Box>
                <Box>
                    {getTitle(language, 'total_order_price')}
                    {newPrice} zł
                </Box>
                <Box>
                    {getTitle(language, 'pick_up_date')}
                    {datePickUp?.date} | {getTitle(language, 'pick_up_time')}
                    {datePickUp?.time}
                </Box>
                <Button variant="outlined" onClick={() => setChangePickUpDate(!changePickUpDate)} sx={{ ...buttonStyle, ...simpleBtnStyle }}>
                    {getTitle(language, 'change_pickup_date')}
                </Button>
                {changePickUpDate && (
                    <Box style={{ paddingTop: 10 }}>
                        <TextField
                            style={{ marginBottom: 10 }}
                            required
                            id={'pickUpDate'}
                            label={getTitle(language, 'pick_up_date')}
                            type="datetime-local"
                            placeholder={getTitle(language, 'pick_up_date')}
                            onChange={handlePickUpDateTime}
                            onClick={handlePickUpDateTime}
                            color={!newPickUpDate ? 'error' : 'success'}
                            focused
                        />
                    </Box>
                )}
                <Box>
                    {getTitle(language, 'rest_for_payment')}
                    {forPayment} zł
                </Box>
                <Button variant="outlined" onClick={handleSaveOrder} sx={{ ...buttonStyle, ...simpleBtnStyle }}>
                    {getTitle(language, 'save_order')}
                </Button>
            </Stack>
            <Dialog fullWidth open={dialogOpen} onClose={handelDialogOpen}>
                <DialogTitle>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Box>{getTitle(language, 'customer_info')}</Box>
                        <Box>
                            <Button variant="outlined" onClick={handelDialogOpen} sx={{ ...buttonStyle, ...simpleBtnStyle }}>
                                {getTitle(language, 'close')}
                            </Button>
                        </Box>
                    </Box>
                </DialogTitle>
                <DialogContent>{order.customer && <CustomerInDialog key={order.customer.id} customer={order.customer} />}</DialogContent>
                <DialogActions></DialogActions>
            </Dialog>
        </Box>
    );
};

export default EditOrder;

interface CustomerInDialogProps {
    customer: ICustomer;
}

const CustomerInDialog = ({ customer }: CustomerInDialogProps) => {
    const { language } = useLanguage();

    return (
        <Box style={{ backgroundColor: 'rgba(245, 245, 220, 0.7)', padding: '10px' }}>
            <Box sx={{ lineHeight: '2rem' }}>
                {getTitle(language, 'full_name')}
                {customer.name}
            </Box>
            <Box sx={{ lineHeight: '2rem' }}>
                {getTitle(language, 'email')}
                {customer.email}
            </Box>
            <Box sx={{ lineHeight: '2rem' }}>
                {getTitle(language, 'phone_number')}
                {customer.phoneNumber}
            </Box>
            <Box sx={{ lineHeight: '2rem' }}>
                {getTitle(language, 'instagram')}
                {customer.instagram}
            </Box>
            <Box sx={{ lineHeight: '2rem' }}>
                {getTitle(language, 'facebook')}
                {customer.faceBook}
            </Box>
        </Box>
    );
};
